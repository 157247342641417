import { IconType } from 'react-icons';

const TableIcon: IconType = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
    >
      <path d="M3 3a1 1 0 00-1 1v16a1 1 0 001 1h18a1 1 0 001-1V4a1 1 0 00-1-1H3zm8 2v3H4V5h7zm-7 9v-4h7v4H4zm0 2h7v3H4v-3zm9 0h7v3h-7v-3zm7-2h-7v-4h7v4zm0-9v3h-7V5h7z"></path>
    </svg>
  );
};

export default TableIcon;
