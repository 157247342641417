import { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';

import { FrameLoadingStatus } from '../../../core/weather-data/WeatherDataLoaderTypes';
import { useIsPlaying } from '../../../hooks/useIsPlaying';
import { MapLoadingState } from '../../../store/slices/map-loading.slice';
import { RootState } from '../../../store/store';

export const MapLoader = () => {
  const { loadingState, mapIsLoading } = useSelector<RootState, MapLoadingState>(
    (state) => state.mapLoading,
  );
  const [isMapLoading, setIsMapLoading] = useState(false);
  const isPlaying = useIsPlaying();
  useEffect(() => {
    if (!mapIsLoading) {
      const timer = setTimeout(() => {
        setIsMapLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
    if (mapIsLoading) {
      setIsMapLoading(true);
    }
  }, [mapIsLoading]);

  const totalLength =
    loadingState[FrameLoadingStatus.Loading] +
    loadingState[FrameLoadingStatus.Error] +
    loadingState[FrameLoadingStatus.Success];
  const currentlyLoading = loadingState[FrameLoadingStatus.Loading];

  const allLoaded = totalLength > 0 && currentlyLoading === 0;

  if (isMapLoading && !isPlaying) {
    let percentage = Math.trunc(((totalLength - currentlyLoading) * 100) / totalLength);
    if (isNaN(percentage)) {
      percentage = 0;
    }
    return (
      <div
        data-html2canvas-ignore="true"
        className={`absolute w-full h-full z-[9999] flex items-center justify-center top-0`}
        style={{
          backgroundColor: 'rgba(0,0,0,0.7)',
        }}
      >
        <CircularProgressbarWithChildren
          value={totalLength - currentlyLoading}
          maxValue={totalLength}
          strokeWidth={3}
          styles={buildStyles({
            pathColor: `${allLoaded ? '#7ac142' : '#2176ff'}`,
            trailColor: '#ffffff8f',
          })}
        >
          {allLoaded ? (
            <div className="wrapper-check">
              <svg
                className="checkmark-check"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle className="checkmark__circle-check" cx="26" cy="26" r="25" fill="none" />{' '}
                <path
                  className="checkmark__check-check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          ) : (
            <div
              style={{
                fontSize: ' 35px',
                marginTop: '-5px',
                color: '#ffffff8f',
                background: 'white',
                borderRadius: '100%',
                padding: 20,
              }}
            >
              <strong className="text-[#081B37]">{`${percentage}%`}</strong>
            </div>
          )}
        </CircularProgressbarWithChildren>
      </div>
    );
  }
  return <></>;
};
