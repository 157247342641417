const siteTooltip = (
  properties: Record<string, string>,
  time: string,
) => `<div style="font-size: 14px; line-height: 1.4;">
                <strong>Description:</strong> ${properties.desc || 'N/A'}
                <br>
                <strong>Weather:</strong> ${properties[`${time}_alert_code`] || 'N/A'}
                <br>
                <strong>Precipitation:</strong> ${properties[`precip_${time}_alert_code`] || 'N/A'}
                <br>
                <strong>Visibility:</strong> ${properties[`visibility_${time}_alert_code`] || 'N/A'}
                <br>
                <strong>Blowover:</strong> ${properties[`blow_over_${time}_alert_code`] || 'N/A'}
                <br>
              </div>`;

const roadTooltip = (properties: Record<string, string>, time: string) => `
            <div style="font-size: 14px; line-height: 1.4;">
              <strong>Description:</strong> ${properties.desc || 'N/A'}
              <br>
              <strong>Weather:</strong> ${properties[`${time}_alert_code`] || 'N/A'}
              <br>
              <strong>Precipitation:</strong> ${properties[`precip_${time}_alert_code`] || 'N/A'}
              <br>
              <strong>Visibility:</strong> ${properties[`visibility_${time}_alert_code`] || 'N/A'}
              <br>
              <strong>Blowover:</strong> ${properties[`blow_over_${time}_alert_code`] || 'N/A'}
              <br>
            </div>
          `;

const cameraTooltip = (properties: Record<string, string>) => {
  return `
                <div style="font-size: 14px; line-height: 1.4;">
                  <img src="data:image/jpeg;base64, ${properties?.large_image}"  alt=${properties?.file_date}/>
                </div>`;
};
export { cameraTooltip, roadTooltip, siteTooltip };
