import { v4 } from 'uuid';

import { HistoricalTypeEnum } from '../enums/HistoricalTypeEnum';
import { SceneDef } from './SceneDef';

export class C9Object {
  id: string = v4(); // uuid
  versionId = v4();
  name: string;
  description: string;
  currentVersion = false;
  published = false;
  creationTime = Date.now();
  updateTime: number; // Unix timestamp ms
  createdBy: string; //string;
  updatedBy: string;
  createdDomainModelVersion = 1;
  UpdatedDomainModelVersion: number;
  CompatibleWithCurrentRequester: boolean;
  properties: Record<string, string> = {};
  elementType: keyof SceneDef;
  templateId: string; // uuid
  templateVersionId: string; // uuid
  externalId: string;
  historicalType: HistoricalTypeEnum;
  enterpriseAccountId: string;
}
