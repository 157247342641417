import { ErrorBoundary } from 'react-error-boundary';
import { RouteObject } from 'react-router-dom';

import Fallback from '../pages/error-boundary/Fallback';
import Landing from '../pages/landing/Landing';
import PikAlert from '../pages/pikalert/Pikalert';
import Studio from '../pages/playground/Studio';
import PrivateRoute from './auth/PrivateRoute';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={Fallback}>
        <PrivateRoute />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/',
        index: true,
        element: <Landing />,
      },
      {
        path: '/studio',
        element: <Studio />,
      },
      {
        path: '/pikalert',
        element: <PikAlert />,
      },
    ],
  },
];
