import { Map } from 'ol';
import { useEffect } from 'react';

import { ModeEnum } from '../../../../core/ui/enums/ModeEnum';
import {
  WeatherDataGLLayer,
  WeatherDataGLSource,
} from '../../../../core/weather-data/ol-extensions/WeatherDataGLLayer';
import { WeatherDataLoader } from '../../../../core/weather-data/WeatherDataLoader';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../../../model/definitions/RadarMapLayer';
import { WDLayerTypeEnum } from '../../../../model/enums/WDLayerTypeEnum';
import { PlayContext } from '../../../playground/playerContext/PlayerContext';

/**
 * This hook makes sure all defined weather data layers are displayed on the OpenLayers map, and re-renders them when needed
 * @param mapPanel Map element panel
 * @param map OpenLayers map
 * @param playContext The player context used for timing
 */
export const useWeatherDataLayers = (project: C9ProjectDef, map: Map, playContext: PlayContext) => {
  // #1 When map layers change, make sure they are already in the OpenLayers map
  const mapPanel = project.sceneDefs[0].mapPanels[0];
  const radarLayers = mapPanel.wdSpace[0].radarMapLayers;
  const activeFramerate = 30;
  const mode = ModeEnum.SEQUENCE;
  const projectToPlay = project;
  // const { activeFramerate, mode, projectToPlay } = useSelector<RootState>(
  //   (state) => state.active,
  // ) as ActiveDef;

  const scene = projectToPlay.sceneDefs.find((sc) =>
    sc.mapPanels.some((p) => p.id === mapPanel.id),
  );
  const sceneStartMs = scene?.timeControl.startMS || 0;
  const sceneEndMs = scene?.timeControl.endMS || 0;

  useEffect(() => {
    if (!map || radarLayers.length === 0) return;

    const allWDLayers = map.getAllLayers().filter((l) => l.get('weatherDataLayerId') != undefined);

    const layersToRemove = allWDLayers.map((l) => l.get('weatherDataLayerId'));
    radarLayers.forEach((l) => {
      if (!l.enabled) return;
      const idx = layersToRemove.findIndex((x) => x === l.id);
      if (idx > -1) layersToRemove.splice(idx, 1);
      ensureLayerExistsOnMap(
        projectToPlay.id,
        map,
        mapPanel,
        l,
        WDLayerTypeEnum.radar,
        activeFramerate,
        mode,
        sceneStartMs,
        sceneEndMs,
      );
    });

    removeLayersFromMap(map, layersToRemove);
  }, [radarLayers, map, mapPanel, mode, activeFramerate, sceneStartMs, projectToPlay, sceneEndMs]);

  // #2 When time changes, trigger a rerender for all custom layers
  useEffect(() => {
    if (!map) return;
    rerenderWeatherDataLayers(map);
  }, [playContext, map]);
};

const removeLayersFromMap = (map: Map, layers: string[]) => {
  if (!layers || !layers.length) return;
  map.getAllLayers().forEach((mapLayer) => {
    if (
      mapLayer.get('weatherDataLayerId') != undefined &&
      layers.find((x) => x == mapLayer.get('weatherDataLayerId'))
    ) {
      map.removeLayer(mapLayer);
      mapLayer.dispose();
    }
  });
};

const ensureLayerExistsOnMap = (
  projectId: string,
  map: Map,
  mapPanel: MapPanelDef,
  wdLayer: RadarMapLayer,
  wdLayerType: WDLayerTypeEnum,
  activeFramerate: number,
  mode: ModeEnum,
  sceneStartMs: number,
  sceneEndMs: number,
) => {
  const mapLayer = map.getAllLayers().find((l) => l.get('weatherDataLayerId') === wdLayer.id);

  const layersToPreload = [];

  if (!mapLayer) {
    // create the webgl layer
    const source = new WeatherDataGLSource({});
    source.setWDLayer(wdLayer, wdLayerType);
    const newMapLayer = new WeatherDataGLLayer({
      source,
      className: 'wd-layer-' + wdLayer.id,
    });
    newMapLayer.set('weatherDataLayerId', wdLayer.id);
    newMapLayer.setZIndex(wdLayer.zindex);
    newMapLayer.setOpacity(wdLayer.opacity);
    newMapLayer.setWDLayer(wdLayer);
    newMapLayer.setWDLayerType(wdLayerType);
    newMapLayer.setMapDefId(mapPanel.id);
    newMapLayer.setMapDef(mapPanel);
    // const rendererTyped = newMapLayer.getRenderer() as WeatherDataGLLayerRenderer;
    newMapLayer.setFrameRate(activeFramerate);
    newMapLayer.setMode(mode);
    newMapLayer.setSceneStartMs(sceneStartMs);
    newMapLayer.setSceneEndMs(sceneEndMs);
    newMapLayer.recalculateFrames();
    map.addLayer(newMapLayer);
    layersToPreload.push(wdLayer);
    newMapLayer.recalculateFrames();
  } else {
    const layerTyped = mapLayer as WeatherDataGLLayer;

    layerTyped.setZIndex(wdLayer.zindex);
    layerTyped.setOpacity(wdLayer.opacity);
    layerTyped.getSource()?.refresh();
    // const rendererTyped = layerTyped.getRenderer() as WeatherDataGLLayerRenderer;
    layerTyped.setWDLayer(wdLayer);
    layerTyped.setWDLayerType(wdLayerType);
    layerTyped.setMapDefId(mapPanel.id);
    layerTyped.setMapDef(mapPanel);
    layerTyped.setFrameRate(activeFramerate);
    layerTyped.setMode(mode);
    layerTyped.setSceneStartMs(sceneStartMs);
    layerTyped.setSceneEndMs(sceneEndMs);
    layerTyped.recalculateFrames();

    // When data frames are changed, if the first one is not preloaded, it should be
    if (
      wdLayer.dataFrames &&
      !WeatherDataLoader.getByFrameId(wdLayer.dataFrames[0]?.frameId, wdLayer.layerType)
    ) {
      layersToPreload.push(wdLayer);
    }

    rerenderWeatherDataLayers(map);
  }

  if (layersToPreload.length > 0) {
    const worker = WeatherDataLoader.getInstance();
    mapPanel.wdSpace[0].radarMapLayers[0].dataFrames.length > 0 &&
      worker.loadWeatherData(projectId, layersToPreload, mapPanel, true, () => {
        rerenderWeatherDataLayers(map);
        setTimeout(() => {
          // "fixes" some strange condition where it doesn't render the data even though it's loaded and available
          rerenderWeatherDataLayers(map);
        }, 10);
      });
  }
};

const rerenderWeatherDataLayers = (map: Map) => {
  const wdLayers = map
    .getAllLayers()
    .filter((l) => l.get('weatherDataLayerId') != undefined)
    .map((x) => x as WeatherDataGLLayer);
  wdLayers.forEach((l) => {
    l.renderFrame();
  });
};
