import AreaChart from './charts/AreaChart';
import ColumnChart from './charts/ColumnChart';
import ProbabilityChart from './charts/ProbabilityChart';
import RoadChart from './charts/RoadChart';
import SnowChart from './charts/SnowChart';
import RwisTable from './RwisTable';

const ForecastPlots = ({ description, timeSeries, rwis }) => {
  return (
    <div>
      <ColumnChart
        timeSeries={timeSeries}
        chartTitle={`Alert Summary for ${description}`}
        yAxisTitle="Alert Summary"
        type="alert"
      />
      <ColumnChart
        timeSeries={timeSeries}
        chartTitle={`Recommended Treatments for ${description}`}
        yAxisTitle="Recommended Treatments"
        type="treatment"
      />
      <SnowChart
        timeSeries={timeSeries}
        chartTitle={`Total Snow Accumulation for ${description}`}
        yAxisTitle="Snow Accum (in)"
        type="accumulation"
      />
      <SnowChart
        timeSeries={timeSeries}
        chartTitle={`Snow rate for ${description}`}
        yAxisTitle="Snow Rate (in/hr)"
        type="rate"
      />
      <ProbabilityChart
        timeSeries={timeSeries}
        chartTitle={`Probability Precip for ${description}`}
        yAxisTitle="% Probability Precip"
      />
      <RoadChart
        timeSeries={timeSeries}
        chartTitle={`Temperature for ${description}`}
        yAxisTitle="deg F"
        type="treatment"
      />
      <AreaChart
        timeSeries={timeSeries}
        chartTitle={`Wind speed/direction for ${description}`}
        yAxisTitle="mph"
      />
      <RwisTable data={rwis} />
    </div>
  );
};

export default ForecastPlots;
