import 'antd/dist/reset.css';
import './style.scss';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Input, Modal, Radio, Typography } from 'antd';
import { Button } from 'flowbite-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const { Text } = Typography;

function Landing() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [projectId, setProjectId] = useState<string>('');
  const [idError, setIdError] = useState(false);
  const [type, setType] = useState<'url' | 'id'>('id');
  const regex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
  const regexURL =
    /^https:\/\/cdot\.c9weather\.com\/studio\/\?projectId=[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;

  return (
    <>
      <div className="relative w-full h-screen bg-cover bg-center flex items-center justify-center landing-wrapper">
        <div className="pikalert-wrapper relative">
          <div className="bg-img"></div>
          <div className="cont-wrapper">
            <div className="cont">
              <h1>Pikalert</h1>
              <p>
                <mark>Each year in the U.S.</mark>, weather-related vehicle crashes cause
                approximately <mark>445,303 injuries and over 5,897 fatalities</mark>. These
                conditions also result in 554 <mark>million vehicle-hours</mark> of delay, with
                economic costs running into billions of dollars.
              </p>
              <Link
                to="/pikalert"
                className="px-7 py-4 text-xl text-white rounded-lg shadow-xl transition"
              >
                Enter Pikalert <ArrowRightOutlined />
              </Link>
            </div>
          </div>
        </div>
        <div className="studio-wrapper relative">
          <div className="cont-wrapper">
            <div className="bg-img"></div>
            <div className="cont">
              <h1>Player</h1>
              <p>
                <mark>Studio develops innovative</mark> weather alert systems to reduce
                weather-related <mark>vehicle crashes, delays</mark>, and economic costs. Our
                real-time updates enhance <mark>road safety</mark> and help drivers navigate
                challenging conditions.
              </p>

              <button
                onClick={() => setOpen(true)}
                //to="/studio?projectId=3b8e660d-6b4f-40a0-8003-79634c6e1fb7"
                className="px-7 py-4 text-xl text-white bg-green-500 rounded-lg shadow-xl transition"
              >
                Enter Player <ArrowRightOutlined />
              </button>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Modal
          title="Open Player"
          open={open}
          onOk={() => navigate('studio/?projectId=' + projectId)}
          onCancel={() => setOpen(false)}
          footer={
            <div className="flex items-center gap-2 justify-end w-full">
              <Button color={'light'} onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  const isValidId = regex.test(projectId);
                  const isValidURL = regexURL.test(projectId);
                  console.log(isValidURL);
                  type === 'id' && isValidId && navigate('studio/?projectId=' + projectId);
                  type === 'id' && !isValidId && setIdError(true);
                  if (type === 'url' && isValidURL) {
                    window.location.href = projectId;
                  }
                  type === 'url' && !isValidURL && setIdError(true);
                }}
              >
                Ok
              </Button>
            </div>
          }
        >
          <div className="flex items-center gap-2 w-full  mb-8">
            <Text>Enter projects </Text>
            <Radio.Group
              value={type}
              onChange={(e) => {
                setProjectId('');
                setIdError(false);
                setType(e.target.value);
              }}
            >
              <Radio value={'id'}>ID</Radio>
              <Radio value={'url'}>URL</Radio>
            </Radio.Group>
          </div>
          {type === 'id' && (
            <>
              <Input
                status={idError ? 'error' : undefined}
                onChange={(e) => {
                  idError && setIdError(false);
                  setProjectId(e.currentTarget.value);
                }}
                value={projectId}
              />
              {idError && <Text type="danger">Invalid project ID!</Text>}
            </>
          )}
          {type === 'url' && (
            <>
              <Input
                status={idError ? 'error' : undefined}
                onChange={(e) => {
                  idError && setIdError(false);
                  setProjectId(e.currentTarget.value);
                }}
                value={projectId}
              />
              {idError && <Text type="danger">Invalid project URL!</Text>}
            </>
          )}
        </Modal>
      )}
    </>
  );
}

export default Landing;
