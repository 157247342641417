import { IconType } from 'react-icons';

const SegmentIcon: IconType = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 256 256"
    >
      <path
        d="M57 183a24 24 0 11-34 0 24 24 0 0134 0zM79 79a24 24 0 1034 0 24 24 0 00-34 0zm64 64a24 24 0 1034 0 24 24 0 00-34 0zm90-104a24 24 0 100 34 24 24 0 000-34z"
        opacity="0.2"
      ></path>
      <path d="M238.64 33.36a32 32 0 00-45.26 0h0a32 32 0 000 45.26c.29.29.6.57.9.85l-26.63 49.46a32.19 32.19 0 00-23.9 3.5l-20.18-20.18a32 32 0 00-50.2-38.89h0a32 32 0 000 45.26c.29.29.59.57.89.85l-26.63 49.47a32 32 0 00-30.27 8.44h0a32 32 0 1045.26 0c-.29-.29-.6-.57-.9-.85l26.63-49.46A32.4 32.4 0 0096 128a32 32 0 0016.25-4.41l20.18 20.18a32 32 0 1050.2-6.38c-.29-.29-.59-.57-.89-.85l26.63-49.46A32.33 32.33 0 00216 88a32 32 0 0022.63-54.62zM51.3 211.33a16 16 0 01-22.63-22.64h0a16 16 0 1122.63 22.64zm33.38-104a16 16 0 010-22.63h0a16 16 0 110 22.63zm86.64 64a16 16 0 01-22.63-22.63h0a16 16 0 0122.63 22.63zm56-104a16 16 0 11-22.62-22.66h0a16 16 0 0122.63 22.64z"></path>
    </svg>
  );
};

export default SegmentIcon;
