import '../styles/style.css';

import { Table } from 'antd';
import moment from 'moment-timezone';
import { FaCloudRain, FaCloudShowersHeavy, FaSnowflake } from 'react-icons/fa';

import { alertColors } from '../utils/helper';
import Arrow from './Arrow';

const ForecastTable = ({ timeSeries }) => {
  const data = timeSeries?.site?.time_series_container;
  if (!data) return null;
  const iconSize = 24;

  const numberOfRows = data.series_alert_code.length;

  const alertLegend = timeSeries.alert_legend || {};
  const pavementConditionLegend = timeSeries.pavement_condition_legend || {};
  const precipTypeLegend = timeSeries.precip_type_legend || {};
  const precipIntensityLegend = timeSeries.precip_intensity_legend || {};
  const visibilityLegend = timeSeries.visibility_legend || {};
  const blowOverRiskLegend = timeSeries.blow_over_risk_legend || {};
  const treatmentExplanationLegend = timeSeries.treatment_explanation_legend || {};
  const plowLegend = timeSeries.plow_legend || {};
  const chemFormLegend = timeSeries.chem_form_legend || {};
  const treatmentTypeLegend = timeSeries.treatment_type_legend || {};

  const formatTime = (timestamp) => {
    const zonedDate = moment.unix(timestamp).tz('America/Denver');
    return zonedDate.format('hh:mm A / M-D');
  };

  const calculateTime = (index) => {
    const epochStart = parseInt(data.epoch_start_time, 10);
    const firstDelta = parseInt(data.first_time_delta_seconds, 10);
    const interval = parseInt(data.remaining_time_delta_seconds, 10);

    if (isNaN(epochStart) || isNaN(firstDelta) || isNaN(interval)) {
      return 'Invalid time data';
    }

    let time;
    if (index === 0) {
      time = epochStart;
    } else {
      time = epochStart + firstDelta + (index - 1) * interval;
    }

    return formatTime(time);
  };

  const getIcon = (type) => {
    switch (type) {
      case 'rain':
        return <FaCloudRain size={iconSize} />;
      case 'mix':
        return <FaCloudShowersHeavy size={iconSize} />;
      case 'snow':
        return <FaSnowflake size={iconSize} />;
      default:
        return null;
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'time',
      key: 'time',
      fixed: 'left',
      render: (text, record) => (
        <div className="w-[150px]">
          <div className="whitespace-nowrap font-semibold bg-[#e3eeff] rounded-[2px] px-[7px] py-[0px] inline-block">
            {text}
          </div>
          <p className="flex align-center mb-0 mt-1 pl-2 pr-2">
            <span className="font-semibold mr-2">VISIBILITY:</span>
            <span className="capitalize">{record.visibility}</span>
          </p>
          <p className="flex align-center mb-0 pl-2 pr-2">
            <span className="font-semibold mr-2">BLOWOVER:</span>
            <span className="capitalize">{record.blowover}</span>
          </p>
          <p className="flex align-center mb-0 pl-2 pr-2">
            <span className="font-semibold mr-2">PAV. CONDITION:</span>
            <span className="capitalize">{record.pavementCondition}</span>
          </p>
        </div>
      ),
    },
    {
      title: 'Alert Level',
      dataIndex: 'alertLevel',
      key: 'alertLevel',
      render: (text, record, index) => (
        <div
          className="w-[25px] h-[25px] flex justify-center items-center rounded-[2px] m-auto"
          style={{
            backgroundColor: alertColors[alertLegend[data.series_alert_code[index]] || '-9999'],
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0"
            viewBox="0 0 1024 1024"
            className="text-[18px]"
            style={{
              color: `#ffffff`,
            }}
          >
            <path d="M193 796c0 17.7 14.3 32 32 32h574c17.7 0 32-14.3 32-32V563c0-176.2-142.8-319-319-319S193 386.8 193 563v233zm72-233c0-136.4 110.6-247 247-247s247 110.6 247 247v193H404V585c0-5.5-4.5-10-10-10h-44c-5.5 0-10 4.5-10 10v171h-75V563zm-48.1-252.5l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3l-67.9-67.9a8.03 8.03 0 00-11.3 0l-39.6 39.6a8.03 8.03 0 000 11.3l67.9 67.9c3.1 3.1 8.1 3.1 11.3 0zm669.6-79.2l-39.6-39.6a8.03 8.03 0 00-11.3 0l-67.9 67.9a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l67.9-67.9c3.1-3.2 3.1-8.2 0-11.3zM832 892H192c-17.7 0-32 14.3-32 32v24c0 4.4 3.6 8 8 8h688c4.4 0 8-3.6 8-8v-24c0-17.7-14.3-32-32-32zM484 180h56c4.4 0 8-3.6 8-8V76c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v96c0 4.4 3.6 8 8 8z"></path>
          </svg>
        </div>
      ),
    },
    {
      title: 'Treatment Alert',
      dataIndex: 'treatmentAlert',
      key: 'treatmentAlert',
      render: (text) => <div className="bubble">{text}</div>,
    },
    {
      title: 'Road Temp',
      dataIndex: 'roadTemp',
      key: 'roadTemp',
      render: (text, record) => (
        <div className="flex items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path d="M10 13.5a4 4 0 104 0V5a2 2 0 00-4 0v8.5M10 9h4"></path>
          </svg>{' '}
          {text}
        </div>
      ),
    },
    {
      title: 'Air Temp',
      dataIndex: 'airTemp',
      key: 'airTemp',
      render: (text, record) => (
        <div className="flex items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path d="M10 13.5a4 4 0 104 0V5a2 2 0 00-4 0v8.5M10 9h4"></path>
          </svg>{' '}
          {text}
        </div>
      ),
    },
    {
      title: 'Dew Point',
      dataIndex: 'dewPoint',
      key: 'dewPoint',
      render: (text, record) => (
        <div className="flex items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path d="M10 13.5a4 4 0 104 0V5a2 2 0 00-4 0v8.5M10 9h4"></path>
          </svg>{' '}
          {text}
        </div>
      ),
    },
    {
      title: 'Prob Rain',
      dataIndex: 'probRain',
      key: 'probRain',
      render: (text, record) => (
        <div className="flex items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path fill="none" stroke="none" d="M0 0h24v24H0V0z"></path>
            <path
              stroke="none"
              d="M10 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM6 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-4 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            ></path>
          </svg>{' '}
          {text}
        </div>
      ),
    },
    {
      title: 'Prob Snow',
      dataIndex: 'probSnow',
      key: 'probSnow',
      render: (text) => (
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path d="M2 12L22 12"></path>
            <path d="M12 2L12 22"></path>
            <path d="M20 16l-4-4 4-4M4 8l4 4-4 4M16 4l-4 4-4-4M8 20l4-4 4 4"></path>
          </svg>{' '}
          {text}
        </div>
      ),
    },
    {
      title: 'Prob Ice',
      dataIndex: 'probIce',
      key: 'probIce',
      render: (text) => (
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z"
            ></path>
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z"
            ></path>
            <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z"></path>
          </svg>
          {text}
        </div>
      ),
    },
    {
      title: 'Wind Speed',
      dataIndex: 'windSpeed',
      key: 'windSpeed',
      render: (text) => (
        <div className="flex items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path d="M9.59 4.59A2 2 0 1111 8H2m10.59 11.41A2 2 0 1014 16H2m15.73-8.27A2.5 2.5 0 1119.5 12H2"></path>
          </svg>
          {text}
        </div>
      ),
    },
    {
      title: 'Wind Gust',
      dataIndex: 'windGust',
      key: 'windGust',
      render: (text) => (
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="text-[#081B37] text-[20px] mr-1"
          >
            <path d="M9.59 4.59A2 2 0 1111 8H2m10.59 11.41A2 2 0 1014 16H2m15.73-8.27A2.5 2.5 0 1119.5 12H2"></path>
          </svg>
          {text}
        </div>
      ),
    },
    {
      title: 'Wind Dir',
      dataIndex: 'windDir',
      key: 'windDir',
      render: (text) => <Arrow direction={text} />,
    },
    {
      title: 'Precip Type',
      dataIndex: 'precipType',
      key: 'precipType',
      render: (text) => getIcon(precipTypeLegend[text]),
    },
    {
      title: 'Precip Intensity',
      dataIndex: 'precipIntensity',
      key: 'precipIntensity',
    },
    { title: 'Snow Rate', dataIndex: 'snowRate', key: 'snowRate' },
    {
      title: 'Tot Snow Accum',
      dataIndex: 'totSnowAccum',
      key: 'totSnowAccum',
    },
    { title: 'Plow', dataIndex: 'plow', key: 'plow' },
    {
      title: 'Chemical Form',
      dataIndex: 'chemicalForm',
      key: 'chemicalForm',
    },
    { title: 'Explanation', dataIndex: 'explanation', key: 'explanation' },
    {
      title: 'Explanation Idx',
      dataIndex: 'explanationIdx',
      key: 'explanationIdx',
    },
    {
      title: 'Treatment Type',
      dataIndex: 'treatmentType',
      key: 'treatmentType',
    },
    {
      title: 'Treatment Rate',
      dataIndex: 'treatmentRate',
      key: 'treatmentRate',
    },
  ];

  const dataSource = Array.from({ length: numberOfRows }).map((_, index) => ({
    key: index,
    time: calculateTime(index),
    alertLevel: data.series_alert_code[index],
    roadTemp: `${data.series_road_temperature[index]}F`,
    pavementCondition: pavementConditionLegend[data.series_pavement_condition[index]],
    airTemp: `${data.series_temperature[index]}F`,
    dewPoint: `${data.series_dew_point[index]}F`,
    probRain: `${(data.series_prob_rain[index] * 100).toFixed(2)}%`,
    probSnow: `${(data.series_prob_snow[index] * 100).toFixed(2)}%`,
    probIce: `${(data.series_prob_ice[index] * 100).toFixed(2)}%`,
    windSpeed: `${data.series_wind_speed[index]}mph`,
    windGust: `${data.series_wind_gust[index]}mph`,
    windDir: data.series_wind_dir[index],
    precipType: data.series_precip_type_code[index],
    precipIntensity:
      precipIntensityLegend[data.series_precip_intensity[index]] !== 'none'
        ? precipIntensityLegend[data.series_precip_intensity[index]]
        : '',
    snowRate: data.series_snow_rate_inches[index] !== 0 ? data.series_snow_rate_inches[index] : '',
    totSnowAccum:
      data.series_snow_accum_total_inches[index] !== 0
        ? data.series_snow_accum_total_inches[index]
        : '',
    visibility: visibilityLegend[data.series_visibility_type_code[index]],
    blowover: blowOverRiskLegend[data.time_series_blow_over_code[index]],
    treatmentAlert: treatmentExplanationLegend[data.series_treatment_alert_code[index]],
    plow:
      plowLegend[data.series_plow_code[index]] !== 'none'
        ? plowLegend[data.series_plow_code[index]]
        : '',
    chemicalForm:
      chemFormLegend[data.series_treatment_chem_form_code[index]] !== 'missing'
        ? chemFormLegend[data.series_treatment_chem_form_code[index]]
        : '',
    explanation:
      treatmentExplanationLegend[data.series_treatment_explanation[index]] !== -9999
        ? treatmentExplanationLegend[data.series_treatment_explanation[index]]
        : '',
    explanationIdx:
      data.series_treatment_explanation_index[index] !== -9999
        ? data.series_treatment_explanation_index[index]
        : '',
    treatmentType:
      treatmentTypeLegend[data.series_treatment_type_code[index]] !== 'Not set'
        ? treatmentTypeLegend[data.series_treatment_type_code[index]]
        : '',
    treatmentRate:
      data.series_treatment_rate_string[index] !== -9999
        ? data.series_treatment_rate_string[index]
        : '',
  }));

  return <Table size="small" columns={columns} dataSource={dataSource} />;
};

export default ForecastTable;
