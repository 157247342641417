import axios from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export const key = ['LATEST_DATE'];
export interface ILatestTime {
  dir: string;
  latest_time: string;
}
export const useGetLatestDate = () => {
  const FETCH_INTERVAL = 1000 * 60;
  const [timestamp, setTimestamp] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, FETCH_INTERVAL);

    return () => clearInterval(interval);
  }, [FETCH_INTERVAL]);
  return useQuery({
    queryKey: [...key],
    queryFn: async (): Promise<Array<ILatestTime>> => {
      const { data } = await axios.get('https://pikalert.c9weather.com/datatime', {
        params: {
          path: '/datatime',
          state: 'colorado',
          client_ip: 'unknown',
          _dc: timestamp,
          page: 1,
          start: 0,
          limit: 25,
        },
      });
      return data;
    },
    refetchInterval: FETCH_INTERVAL,
    cacheTime: FETCH_INTERVAL,
    staleTime: FETCH_INTERVAL,
  });
};
