import axios from 'axios';
import { useQuery } from 'react-query';

export const key = ['THUMBNAILS'];

interface CameraSiteData {
  desc: string;
  file_date: string;
  img_color: string;
  img_label: string;
  large_height: number;
  large_image: string;
  large_width: number;
  site_num: number;
  thumb_height: number;
  thumb_image: string;
  thumb_width: number;
}
export interface ThumbnailsResponse {
  data_time: string;
  state: string;
  sites: CameraSiteData[];
}

export const useGetThumbnails = (timestamp?: string) => {
  return useQuery({
    queryKey: [...key, timestamp],
    queryFn: async (): Promise<ThumbnailsResponse> => {
      const { data } = await axios.get('https://pikalert.c9weather.com/thumbnail', {
        params: {
          path: '/thumbnail',
          state: 'colorado',
          client_ip: 'unknown',
          _dc: timestamp,
          page: 1,
          start: 0,
          limit: 25,
        },
      });
      return data;
    },
    cacheTime: 0,
    enabled: !!timestamp,
  });
};
