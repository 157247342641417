import axios from 'axios';
import { useQuery } from 'react-query';

export const key = ['DISTRICT_SUMMARY'];

export const useGetDistrictSummary = (timestamp?: string) => {
  return useQuery({
    queryKey: [...key, timestamp],
    queryFn: async () => {
      const { data } = await axios.get('https://pikalert.c9weather.com/district_alerts_summary', {
        params: {
          path: '/district_alerts_summary',
          state: 'colorado',
          client_ip: 'unknown',
          _dc: timestamp,
          page: 1,
          start: 0,
          limit: 25,
        },
      });
      return data;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !!timestamp,
  });
};
