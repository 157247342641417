// import { LottieIcon } from '../../atoms/LottieIcon';
import { LottieIconAnimation } from '../../atoms/LottieIconAnimation';
import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { AnimationPanelDef } from '../../model/definitions/AnimationPanelDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import ElementContainer from './ElementContainer';
import { Sequence } from './sequence/Sequence';

interface AnimationElementProps {
  panelProps: AnimationPanelDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  mapId?: string;
  geoPosterId?: string;
  sceneId: string;
}

export const AnimationElement = ({
  panelProps,
  canvas,
  disabled,
  posterId,
  inPoster,
  parentTime,
  parentSize,
  isMapOverlay = false,
  mapId,
  geoPosterId,
  sceneId,
}: AnimationElementProps) => {
  const { animationPanelDefTemplate, timeControls, background, positionControl } = panelProps;
  const { color } = background;
  const colorBgOpacity = color?.includes('linear-gradient')
    ? gradientOpacity(color)
    : singleColorOpacity(color);
  const { jsonAnimation } = animationPanelDefTemplate;
  return (
    <ElementContainer
      sceneId={sceneId}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      lock={panelProps.lockAspectRatio}
      type={'animationPanels'}
      visibility={panelProps.enabled}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
      geoPosterId={geoPosterId}
      opacity={panelProps.opacity}
    >
      <div
        style={{ overflow: 'hidden', width: '100%', height: '100%', background: colorBgOpacity }}
      >
        {jsonAnimation ? (
          <LottieIconAnimation
            versionId={animationPanelDefTemplate.versionId}
            inCanvas
            time={timeControls}
            parentStart={parentTime?.length ? parentTime[0].startMS : 0}
            frameRate={animationPanelDefTemplate.lottieFrameRate}
            repeat={panelProps.animationPanelDefTemplate.repeat}
          />
        ) : (
          <Sequence
            repeat={panelProps.animationPanelDefTemplate.repeat}
            id={panelProps.id}
            item={animationPanelDefTemplate}
            time={timeControls}
            position={positionControl}
            parentStart={parentTime?.length ? parentTime[0].startMS : 0}
          />
        )}
      </div>
    </ElementContainer>
  );
};
