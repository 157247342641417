import { useKeycloak } from '@react-keycloak/web';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { createPlayer } from '../../components/timeline/helpers';
import { useGetTranslation } from '../../core/api/translation/useGetTranslation';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { getProjectDuration } from '../../helpers/timelineUtil';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { precacheDrawings } from '../../molecules/mapElement/helpers';
import SceneCanvas from '../../organisms/SceneCanvas';
import {
  ActiveDef,
  resetActiveState,
  setProjectToPlay,
  setSceneTranslation,
  setTime,
  setTranslation,
} from '../../store/slices/active-slice';
import { selectActiveScene } from '../../store/slices/selectors';
import { RootState } from '../../store/store';
import { loadFrames } from './loadFrames';
import PlayerContext from './playerContext/PlayerContext';
import styles from './Playground.module.scss';
import StudioSkeleton from './StudioSkeleton';
import { useLoadProject } from './useLoadProject';

const Playground = () => {
  const active = useSelector<RootState>((state) => state.active) as ActiveDef;
  const { activeScene, projectToPlay } = active;
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const { isLoading } = useLoadProject(params.get('projectId'));
  const {
    keycloak: { idTokenParsed },
  } = useKeycloak();

  useEffect(() => {
    // Hide Zendesk Web Widget
    // @ts-ignore
    if (window.zE && process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      window.zE('webWidget', 'hide');
    }
  }, []);

  /**On leave reset active state */
  useEffect(() => {
    return () => {
      dispatch(resetActiveState());
    };
  }, [dispatch]);

  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const { data: language } = useGetTranslation(project.translationId ?? '');
  const { data: sceneLanguage } = useGetTranslation(activeSceneDef?.translationId ?? '');

  useEffect(() => {
    dispatch(setTranslation({ translation: language }));
  }, [dispatch, language, project.translationId]);

  useEffect(() => {
    activeSceneDef &&
      dispatch(setSceneTranslation({ translation: sceneLanguage, scene: activeSceneDef?.id }));
  }, [dispatch, sceneLanguage, activeSceneDef?.translationId, activeSceneDef?.id]);

  const durationScene = project.sceneDefs?.find((scene) => scene.id === activeScene)
    ? project.sceneDefs.find((scene) => scene.id === activeScene)?.durationInMS
    : 0;

  const {
    setIsPlaying,
    time,
    clear,
    interval,
    set,
    isPlaying,
    setTime: setContextTime,
    setSkip,
    // isRecording,
  } = useContext(PlayerContext);

  const [skippedTime, setSkippedTime] = useState<{
    val: number;
    ctrl: boolean;
  }>({ val: 0, ctrl: false });
  /*  function isBetween(startMs: number, endMs: number, num: number) {
    return num >= startMs && num <= endMs;
  }*/

  /*
  useEffect(() => {
    mode === ModeEnum.PROJECT && !isRecording && isInRange(project.skippedTime, time);
  }, [mode, project.skippedTime, time]);
*/

  /*  const changeTime = async (e: number, getTo: number) => {
    clear(interval);
    await setContextTime(time + e);
    isPlaying === PlaybackEnum.PLAYING && skip && skip(time + e);
    isPlaying === PlaybackEnum.PLAYING && (await dispatch(setTime({ activeTime: time + e })));
    // isPlaying !== PlaybackEnum.PLAYING && (await dispatch(setTime({ activeTime: time })));
  };*/
  /*const isInRange = (arr: SkipTimeDef[], num: number) => {
    for (let i = 0; i < arr.length; i++) {
      if (isBetween(arr[i].startMS, arr[i].endMS, num)) {
        if (arr[i].endMS === getProjectDuration(project)) {
          clear(interval);
          setIsPlaying(PlaybackEnum.STOP);
          setContextTime(arr[i].startMS);
          dispatch(
            setTime({
              activeTime: arr[i].startMS,
            }),
          );
          setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
          setSkip({ val: 0, ctrl: !skippedTime.ctrl });
          setIsPlaying(PlaybackEnum.STOP);
        } else {
          changeTime(arr[i].endMS - arr[i].startMS, arr[i].endMS);
        }
      }
    }
    return 0;
  };*/

  useEffect(() => {
    const { syncSpace, activeFramerate } = active;
    dispatch(
      setProjectToPlay({
        projectToPlay: createPlayer(project, syncSpace, activeFramerate),
      }),
    );
  }, [project]);

  const start = async () => {
    const { sceneDefs } = projectToPlay;

    await Promise.all([
      loadFrames(sceneDefs, dispatch, idTokenParsed!),
      precacheDrawings(sceneDefs),
    ]);

    setIsPlaying(PlaybackEnum.PLAYING);

    const timelineTime = time;
    const startTime = Date.now();

    const startPlay = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime + timelineTime + skippedTime.val;
      const projectDuration = getProjectDuration(projectToPlay);

      setContextTime(elapsedTime);
      if (elapsedTime >= projectDuration) {
        clearInterval(intv);
        setIsPlaying(PlaybackEnum.STOP);
        setContextTime(projectDuration);

        setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
        setSkip({ val: 0, ctrl: !skippedTime.ctrl });
      }
    };

    const intv = setInterval(startPlay, 16);
    set(intv);
  };

  const stop = () => {
    clear && clear(interval);
    setContextTime(0);
    setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
    setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    dispatch(setTime({ activeTime: 0 }));
    setIsPlaying(PlaybackEnum.STOP);
  };
  const skip = (e?: number) => {
    if (isPlaying === PlaybackEnum.PLAYING && e) {
      clear(interval);
      setContextTime(e);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const pause = () => {
    clear(interval);
    setIsPlaying(PlaybackEnum.PAUSE);
    clear && clear(interval);
    dispatch(setTime({ activeTime: time }));
    setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
    setSkip({ val: 0, ctrl: !skippedTime.ctrl });
  };
  const backFive = () => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time - 5000 > 0
        ? setSkippedTime({ val: -5000, ctrl: !skippedTime.ctrl })
        : setSkippedTime({ val: -time, ctrl: !skippedTime.ctrl });
      time - 5000 > 0
        ? setSkip({ val: -5000, ctrl: !skippedTime.ctrl })
        : setSkip({
            val: -time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time - 5000 > 0 ? setContextTime(time - 5000) : setContextTime(0);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const backScene = (timeToSkip: number) => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      setSkippedTime({ val: -timeToSkip, ctrl: !skippedTime.ctrl });
    } else {
      setContextTime(timeToSkip);
    }
  };
  const skipFive = () => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time + 5000 < durationScene!
        ? setSkippedTime({ val: 5000, ctrl: !skippedTime.ctrl })
        : setSkippedTime({
            val: durationScene! - time,
            ctrl: !skippedTime.ctrl,
          });
      time + 5000 < durationScene!
        ? setSkip({ val: 5000, ctrl: !skippedTime.ctrl })
        : setSkip({
            val: durationScene! - time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time + 5000 < durationScene! ? setContextTime(time + 5000) : setContextTime(durationScene!);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const skipScene = (timeToSkip: number, isProjectSkip?: boolean) => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      setSkippedTime({ val: timeToSkip, ctrl: !skippedTime.ctrl });
    } else {
      setContextTime(timeToSkip);
    }
  };
  const toStart = () => {
    clear(interval);
    setIsPlaying(PlaybackEnum.STOP);
    setContextTime(0);
  };
  const toEnd = () => {
    clear(interval);
    setIsPlaying(PlaybackEnum.STOP);
    setContextTime(durationScene!);
  };

  /*useEffect(() => {
    const el = document.getElementById('canvas-container');
    function handleListener(e: KeyboardEvent) {
      if (e.key === ' ') {
        if (isPlaying === PlaybackEnum.PLAYING) {
          pause();
        } else {
          start();
        }
      }
      if (e.key === 'Enter') {
        stop();
      }
      if (e.key === 'End') {
        toEnd();
      }
      if (e.key === 'Home') {
        toStart();
      }
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowRight') {
        skipFive();
      }
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowLeft') {
        backFive();
      }
    }
    el?.addEventListener('keydown', handleListener);
    return () => {
      el?.removeEventListener('keydown', handleListener);
    };
  });*/
  useEffect(() => {
    isPlaying === PlaybackEnum.PLAYING && start();
  }, [skippedTime]);

  return (
    <div className={`${styles.dark} flex h-full overflow-y-hidden overflow-x-hidden`}>
      {isLoading && <StudioSkeleton />}
      <SceneCanvas
        project={project}
        skip={skip}
        play={start}
        pause={pause}
        backFive={backFive}
        skipFive={skipFive}
        stop={stop}
        toEnd={toEnd}
        toStart={toStart}
        nextScene={skipScene}
        prevScene={backScene}
        active={active}
      />
    </div>
  );
};

export default Playground;
