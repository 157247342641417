import { useEffect } from 'react';

import styles from './styles.module.scss';

interface ControlButtonProps {
  icon: JSX.Element | string;
  onClick?: () => void;
  disabled?: boolean;
  bordered?: boolean;
  replay?: boolean;
  replayProject?: boolean;
}
const ControlButton = ({
  icon,
  onClick,
  disabled,
  bordered,
  replay,
  replayProject,
}: ControlButtonProps) => {
  useEffect(() => {
    replayProject && !disabled && replay && onClick && onClick();
  }, [onClick, replay, disabled, replayProject]);
  return (
    <button
      className={`${styles.controlButton} ${bordered ? styles.bordered : ''} ${
        replay ? styles.replay : ''
      } ${disabled ? styles.disabled : ''}`}
      onClick={() => onClick && onClick()}
    >
      {icon}
    </button>
  );
};
export default ControlButton;
