import { DraggableData } from 'react-rnd';

import CUT from '../animations/CUT';
import FADE_IN from '../animations/FADE_IN';
import FADE_OUT from '../animations/FADE_OUT';
import { SceneSimple } from '../components/timeline/Timeline';
import { C9ProjectDef } from '../model/definitions/C9ProjectDef';
import { SceneDef } from '../model/definitions/SceneDef';
import { TimeControlDef } from '../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../model/enums/AnimationsEnum';

export const findMinInArray = (array: Array<any>, property?: string | number) => {
  const min =
    array.length > 0 &&
    array.reduce((prev: any, current: any) => {
      if (property) return prev[property] < current[property] ? prev[property] : current[property];
      else return prev < current ? prev : current;
    });
  if (property) return min[property];
  else return min;
};
export const findMaxInArray = (array: Array<any>, property?: string | number) => {
  return (
    array.length > 0 &&
    array.reduce((prev: any, current: any) => {
      if (property) return prev[property] > current[property] ? prev[property] : current[property];
      else return prev > current ? prev : current;
    })
  );
};
const getSceneStartEnd = (scene: SceneDef) => {
  const sceneElements = [
    ...scene.imagePanels,
    ...scene.videoPanels,
    ...scene.audioElements,
    ...scene.mapPanels,
    ...scene.weatherPosters,
    ...scene.textPanels,
  ].filter(Boolean);
  const allMin: Array<number> = [];
  const allMax: Array<number> = [];
  sceneElements.forEach((element) => {
    allMin.push(findMinInArray(element.timeControls, 'startMS'));
    allMax.push(findMinInArray(element.timeControls, 'endMS'));
  });
  return { startMS: findMinInArray(allMin), endMS: findMaxInArray(allMax) };
};
const totalSkippedTime = (project: C9ProjectDef) => {
  return project.skippedTime.reduce((sum, obj) => sum + (obj.endMS - obj.startMS), 0);
};
// const projectDurationCache = new WeakMap(); // Cache to store project durations

const getProjectDuration = (project: C9ProjectDef) => {
  /* if (projectDurationCache.has(project)) {
    return projectDurationCache.get(project); // Return cached result if available
  }*/

  const projectDuration: Array<number> = project.sceneDefs.map((scene) => {
    const singleScene: Array<number> = [];

    // Loop through each panel type and collect endMS values
    [
      'mapPanels',
      'textPanels',
      'imagePanels',
      'videoPanels',
      'audioElements',
      'animationPanels',
      'weatherPosters',
      'observedWDElements',
      'forecastWDElements',
    ].forEach((panelType) => {
      // @ts-ignore
      scene[panelType]?.forEach((item) => {
        item.timeControls.forEach((control: TimeControlDef) =>
          singleScene.push(Math.min(control.endMS, scene.durationInMS)),
        );
      });
    });
    return singleScene.length > 0 ? Math.max(...singleScene) : 0;
  });
  const sumOfHolds = project.sceneDefs.reduce((total, current) => {
    return total + (current.hold || 0);
  }, 0);
  const transitionSum = project.sceneDefs
    .slice(1)
    .reduce((sum, obj) => sum + obj.timeControl.inAnimationDuration, 0);

  // projectDurationCache.set(project, totalDuration); // Cache the result

  return projectDuration.reduce((partialSum, a) => partialSum + a, 0) - transitionSum + sumOfHolds;
};

const getScenePlaybackLength = (scene: SceneDef, activeOnly?: boolean) => {
  const sceneDuration: Array<number> = [];

  const maps = activeOnly ? scene?.mapPanels.filter((item) => item.enabled) : scene?.mapPanels;
  const images = activeOnly
    ? scene?.imagePanels.filter((item) => item.enabled)
    : scene?.imagePanels;
  const animations = activeOnly
    ? scene?.animationPanels.filter((item) => item.enabled)
    : scene?.animationPanels;
  const text = activeOnly ? scene?.textPanels.filter((item) => item.enabled) : scene?.textPanels;
  const video = activeOnly ? scene?.videoPanels.filter((item) => item.enabled) : scene?.videoPanels;
  const posters = activeOnly
    ? scene?.weatherPosters.filter((item) => item.enabled)
    : scene?.weatherPosters;
  const audio = activeOnly
    ? scene?.audioElements.filter((item) => item.enabled)
    : scene?.audioElements;
  const observed = activeOnly
    ? scene?.observedWDElements.filter((item) => item.enabled)
    : scene?.observedWDElements;
  const forecast = activeOnly
    ? scene?.forecastWDElements.filter((item) => item.enabled)
    : scene?.forecastWDElements;

  maps.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  text?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  images?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  animations?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  video?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  audio?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  posters?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  observed?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));
  forecast?.map((item) => item.timeControls?.map((control) => sceneDuration.push(control.endMS)));

  return scene.durationInMS >= Math.max(...sceneDuration)
    ? Math.max(...sceneDuration) < 0
      ? 0
      : Math.max(...sceneDuration)
    : scene.durationInMS;
};
const getAnimationType = (type?: AnimationsEnum) => {
  switch (type) {
    case AnimationsEnum.FADE_IN:
      return FADE_IN;
    case AnimationsEnum.FADE_OUT:
      return FADE_OUT;
    default:
      return CUT;
  }
};
const getPrevShift = (project: C9ProjectDef, index: number) => {
  const prevScenes = project.sceneDefs.slice(1, index);
  return prevScenes.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.timeControl.inAnimationDuration;
  }, 0);
};

const parseScenes = (project: C9ProjectDef): SceneSimple[] => {
  const simpleScenesArray: Array<SceneSimple> = [];

  project?.sceneDefs?.forEach((scene, index) => {
    const start =
      index === 0
        ? 0
        : project?.sceneDefs.slice(0, index).reduce((a, b) => +a + +getScenePlaybackLength(b), 0);
    simpleScenesArray.push({
      id: scene.id,
      name: scene.name,
      startMS:
        index === 0
          ? start
          : start -
            scene.timeControl.inAnimationDuration -
            (index > 1 ? getPrevShift(project, index) : 0),
      endMS:
        (index === 0 ? start : start - scene.timeControl.inAnimationDuration) +
        getScenePlaybackLength(scene) -
        getPrevShift(project, index),
      inAnimationDuration: scene.timeControl?.inAnimationDuration,
      outAnimationDuration: scene.timeControl?.outAnimationDuration,
      inAnimationDef: scene.timeControl?.inAnimationDef,
      outAnimationDef: scene.timeControl?.outAnimationDef,
      durationInMS: scene.durationInMS,
      thumbnailUrls: scene.thumbnailUrls,
      hold: scene.hold,
      repeat: scene.repeat,
    });
  });
  return simpleScenesArray;
};
const getMaxZindex = (currentScene: string, project: C9ProjectDef) => {
  const activeScene = project.sceneDefs.find((scene) => scene.id === currentScene);
  const allElements = [
    activeScene?.textPanels,
    activeScene?.imagePanels,
    activeScene?.videoPanels,
    activeScene?.mapPanels,
    activeScene?.observedWDElements,
    activeScene?.forecastWDElements,
  ].flat();
  const zIndexes: Array<number> = allElements
    .map((item) => (item ? item.positionControl.zindex : 0))
    .filter(Boolean);
  if (zIndexes.length === 0) return 1;
  else return Math.max(...zIndexes) + 1;
};
export const getNumber = (percent: number, width?: number): number => {
  if (width) return (percent * width) / 100;
  return 0;
};
export const absoluteToPercent = (
  segments: TimeControlDef[],
  duration: number,
  offsetStart = 0,
  offsetEnd = 0,
) => {
  const values: TimeControlDef[] = [];
  segments &&
    segments?.forEach((item) => {
      values.push({
        ...item,
        startMS: ((item?.startMS + offsetStart) * 100) / duration,
        endMS: (item?.endMS * 100) / duration,
      });
    });
  return values;
};
export const partialAbsoluteToPercent = (
  segments: Array<Pick<TimeControlDef, 'startMS' | 'endMS'> & Partial<TimeControlDef>>,
  duration: number,
  offsetStart = 0,
  offsetEnd = 0,
) => {
  const values: Array<Pick<TimeControlDef, 'startMS' | 'endMS'> & Partial<TimeControlDef>> = [];
  segments &&
    segments?.forEach((item) => {
      values.push({
        ...item,
        startMS: ((item?.startMS + offsetStart) * 100) / duration,
        endMS: ((item?.endMS > duration ? duration : item?.endMS) * 100) / duration,
      });
    });
  return values;
};
export const getPercentFromData = (data: DraggableData, width?: number): number => {
  if (width) return (data.x * 100) / width;
  return 0;
};
export {
  getAnimationType,
  getMaxZindex,
  getProjectDuration,
  getScenePlaybackLength,
  getSceneStartEnd,
  parseScenes,
  totalSkippedTime,
};
