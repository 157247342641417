import { IconType } from 'react-icons';

const RadarIcon: IconType = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx="12" cy="12" r="1.5" fill="currentColor" />
      <circle cx="12" cy="12" r="4" fill="none" stroke="currentColor" strokeWidth="1" />
      <circle cx="12" cy="12" r="6" fill="none" stroke="currentColor" strokeWidth="1" />
      <circle cx="12" cy="12" r="8" fill="none" stroke="currentColor" strokeWidth="1" />
      <path d="M12 2v4" stroke="currentColor" strokeWidth="1" />
      <path d="M12 18v4" stroke="currentColor" strokeWidth="1" />
      <path d="M2 12h4" stroke="currentColor" strokeWidth="1" />
      <path d="M18 12h4" stroke="currentColor" strokeWidth="1" />
    </svg>
  );
};

export default RadarIcon;
