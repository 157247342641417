import { Oval } from 'react-loader-spinner';

function Loader() {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#1C64F2"
        secondaryColor="black"
        ariaLabel="oval-loading"
      />
    </div>
  );
}

export default Loader;
