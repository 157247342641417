import { equals, Extent } from 'ol/extent';
import Layer from 'ol/layer/Layer.js';
import { fromLonLat } from 'ol/proj';
import Source from 'ol/source/Source';

import { GribMapLayer } from '../../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../model/definitions/SatelliteMapLayer';
import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { InterpolationEnum } from '../../../model/enums/InterpolationEnum';
import { WDLayerTypeEnum } from '../../../model/enums/WDLayerTypeEnum';
import {
  checkShouldRenderFrame,
  getFramesDataCached,
  isTimeInLayersRange,
} from '../../../molecules/mapElement/helpers';
import { GlobalPlayerControl } from '../../../pages/playground/GlobalPlayerControl';
import { ModeEnum } from '../../ui/enums/ModeEnum';
import { WeatherDataLoader } from '../WeatherDataLoader';
import { FrameLoadingResult } from '../WeatherDataLoaderTypes';
import { recalculatePositions } from '../webgl/recalculatePositions';
import { WeatherDataGLLayerRenderer } from './WeatherDataGLLayerRenderer';

export class WeatherDataGLLayer extends Layer {
  createRenderer(): WeatherDataGLLayerRenderer {
    return new WeatherDataGLLayerRenderer(this, {});
  }

  renderFrame() {
    this.changed();
    return null;
  }

  wdLayer: GribMapLayer | SatelliteMapLayer | RadarMapLayer;
  wdLayerType: WDLayerTypeEnum;
  framesWithTime: {
    startTime: number;
    endTime: number;
    timestamp: number;
    frameId: string;
  }[];
  hold: number;
  frameRate: number;
  mode: ModeEnum;
  sceneStartMs: number;
  sceneHoldMs: number;
  sceneEndMs: number;
  mapDefId: string;
  mapDef: MapPanelDef;

  setMapDefId(id: string) {
    this.mapDefId = id;
  }
  setMapDef(m: MapPanelDef) {
    this.mapDef = m;
  }
  setWDLayer(wdLayer: GribMapLayer | SatelliteMapLayer | RadarMapLayer) {
    this.wdLayer = wdLayer;
  }
  setWDLayerType(wdt: WDLayerTypeEnum) {
    this.wdLayerType = wdt;
  }
  setFrameRate(fr: number) {
    this.frameRate = fr;
  }
  setMode(m: ModeEnum) {
    this.mode = m;
  }
  setSceneStartMs(m: number) {
    this.sceneStartMs = m;
  }
  setSceneHold(m: number) {
    this.sceneHoldMs = m;
  }
  setSceneEndMs(m: number) {
    this.sceneEndMs = m;
  }

  shouldInterpolate(): boolean {
    // always interpolate temperature, otherwise check the setting
    if (
      this.wdLayer.layerSetup.colorPaletteDef?.paletteParamType ==
      ColorPaletteParamTypeEnum.TEMPERATURE
    ) {
      return true;
    }

    return Boolean(this.wdLayer.enableInterpolation);
  }

  recalculateFrames() {
    const { framesWithTime } = getFramesDataCached(
      this.mode == ModeEnum.SEQUENCE,
      this.wdLayer,
      // @ts-ignore
      { startMS: this.sceneStartMs, hold: this.sceneHoldMs, endMS: this.sceneEndMs },
      this.frameRate,
    );
    this.framesWithTime = framesWithTime;
  }

  imageCache: Record<string, HTMLImageElement> = {};

  colorPaletteName = '';
  colorPaletteLastUpdate = 0;
  colorPaletteInterpolate: InterpolationEnum;
  colorPalette: HTMLImageElement | null = null;

  getFirstLoadedFrame(): {
    numberOfLoadedFrames: number;
    firstLoadedFrame: FrameLoadingResult | undefined;
  } {
    const frames = this.framesWithTime;

    let numberOfLoadedFrames = 0;
    let firstLoadedFrame: FrameLoadingResult | undefined;

    for (let i = 0; i < frames.length; i++) {
      const result = WeatherDataLoader.getByFrameId(frames[i].frameId, this.wdLayer.layerType);
      if (result && result.data) {
        numberOfLoadedFrames++;
        if (!firstLoadedFrame) firstLoadedFrame = result;
      }
      if (numberOfLoadedFrames > 1) {
        break;
      }
    }

    return { numberOfLoadedFrames, firstLoadedFrame };
  }

  getFirstFrame(): { result?: FrameLoadingResult; lastingTime: number; elapsedTime: number } {
    const time = GlobalPlayerControl.getTime();
    const frames = this.framesWithTime;

    const { numberOfLoadedFrames, firstLoadedFrame } = this.getFirstLoadedFrame();

    if (numberOfLoadedFrames === 1 && isTimeInLayersRange(time, this.wdLayer.timeControls)) {
      return {
        result: firstLoadedFrame,
        elapsedTime: 1,
        lastingTime: 1,
      };
    }

    for (let i = 0; i < frames.length; i++) {
      if (checkShouldRenderFrame(time, frames[i], this.wdLayer.timeControls)) {
        const result = WeatherDataLoader.getByFrameId(frames[i].frameId, this.wdLayer.layerType);
        // These two while loops fix interpolation when the same frame is repeated (when we fill in missing data)
        let startTime = frames[i].startTime;
        let endTime = frames[i].endTime;
        let j = i + 1;
        while (j < frames.length && frames[j].frameId === frames[i].frameId) {
          endTime = frames[j].endTime;
          j++;
        }
        let k = i - 1;
        while (k >= 0 && frames[k].frameId === frames[i].frameId) {
          startTime = frames[k].startTime;
          k--;
        }
        return {
          result,
          elapsedTime: time - startTime,
          lastingTime: endTime - startTime,
        };
      }
    }

    return {
      lastingTime: 0,
      elapsedTime: 0,
    };
  }

  getSecondFrame(): FrameLoadingResult {
    const time = GlobalPlayerControl.getTime();
    const frames = this.framesWithTime;
    for (let i = 0; i < frames.length; i++) {
      if (checkShouldRenderFrame(time, frames[i], this.wdLayer.timeControls)) {
        if (i + 1 < frames.length) {
          let j = i + 1;
          // increase j while the frame id is the same, or until the end of the array
          // this needs to be done for correct interpolation of duplicated frames
          while (j < frames.length && frames[j].frameId === frames[i].frameId) {
            j++;
          }
          return WeatherDataLoader.getByFrameId(frames[j]?.frameId, this.wdLayer.layerType);
        } else {
          return WeatherDataLoader.getByFrameId(frames[i]?.frameId, this.wdLayer.layerType);
        }
      }
    }
    return {
      status: 0,
      frameId: '0',
      layerId: '',
      layerType: '',
    };
  }

  getMinAndMaxChannelValues(): number[] {
    let minVal = 0;
    let maxVal = 0;
    let isDualChannel = false;
    const frames = this.framesWithTime;
    for (let i = 0; i < frames.length; i++) {
      const frame = WeatherDataLoader.getByFrameId(frames[i].frameId, this.wdLayer.layerType);
      if (!frame) continue;
      const u = frame.data?.frameInfo.values.unit;
      if (u == 'WDirWSpeed' || u == 'UV Components') {
        isDualChannel = true;
      }
      if (isDualChannel) {
        if (frame.data?.frameInfo.values.min_val_ch_2! < minVal || i == 0) {
          minVal = frame.data!.frameInfo.values.min_val_ch_2!;
        }
        if (frame.data?.frameInfo.values.max_val_ch_2! > maxVal || i == 0) {
          maxVal = frame.data!.frameInfo.values.max_val_ch_2!;
        }
      } else {
        if (frame.data?.frameInfo.values.min_val_ch_1! < minVal || i == 0) {
          minVal = frame.data!.frameInfo.values.min_val_ch_1;
        }
        if (frame.data?.frameInfo.values.max_val_ch_1! > maxVal || i == 0) {
          maxVal = frame.data!.frameInfo.values.max_val_ch_1;
        }
      }
    }
    return [minVal, maxVal];
  }

  coordsPositions: any = null;
  coordsPositionsExtent: Extent;

  async recalculateCoordinates(extent: Extent | null) {
    if (!this.framesWithTime || !this.framesWithTime.length) return;
    const data = WeatherDataLoader.getByFrameId(
      this.framesWithTime[0].frameId,
      this.wdLayer.layerType,
    );

    if (!data || !data.data?.frameInfo.coordinates.id) return;

    if (this.coordsPositions && (extent == null || equals(extent, this.coordsPositionsExtent))) {
      return;
    }

    this.coordsPositions = { loading: true };

    const { projectionCenterLat, projectionCenterLon } = this.mapDef.properties;

    const mapInternal = this.getMapInternal();
    const proj = mapInternal?.getView().getProjection();
    const isNsper = proj?.getCode().includes('ESRI:54049') || false;

    this.coordsPositionsExtent = extent!;
    this.coordsPositions = await recalculatePositions(
      data.data,
      // @ts-ignore
      mapInternal?.getView().getViewportSize_(),
      isNsper,
      Number(projectionCenterLat),
      Number(projectionCenterLon),
      (coordinate, projString) => {
        const resCoord = isNsper ? fromLonLat(coordinate, proj) : coordinate;
        return mapInternal!.getPixelFromCoordinateInternal(resCoord);
      },
    );

    const rendererTyped = this.getRenderer() as WeatherDataGLLayerRenderer;
    rendererTyped.setCoordinatesBuffer();
  }
}

export class WeatherDataGLSource extends Source {
  private wdLayer: GribMapLayer | SatelliteMapLayer | RadarMapLayer;
  private wdLayerType: WDLayerTypeEnum;

  setWDLayer(
    wdLayer: GribMapLayer | SatelliteMapLayer | RadarMapLayer,
    wdLayerType: WDLayerTypeEnum,
  ) {
    this.wdLayer = wdLayer;
    this.wdLayerType = wdLayerType;
  }

  getWDLayer(): GribMapLayer | SatelliteMapLayer | RadarMapLayer {
    return this.wdLayer;
  }

  getWDLayerType(): WDLayerTypeEnum {
    return this.wdLayerType;
  }
}
