import { Map, View } from 'ol';
import { FullScreen, Zoom } from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import { XYZ } from 'ol/source';
import OSM from 'ol/source/OSM';
import { MutableRefObject, useEffect, useRef } from 'react';

interface UseMapProps {
  mapRef: MutableRefObject<HTMLDivElement | null>;
  mapStyle: 'basic' | 'satellite';
}

const useMap = ({ mapRef, mapStyle }: UseMapProps) => {
  const mapInstanceRef = useRef<Map | null>(null);
  const osmLayerRef = useRef<TileLayer<OSM> | null>(null);
  const satelliteLayerRef = useRef<TileLayer<XYZ> | null>(null);

  useEffect(() => {
    const extent = [-12445561.798579674, 4172505.178748621, -11057234.27529601, 5248588.063144492];

    // Initialize OSM and Satellite layers once
    osmLayerRef.current = new TileLayer({
      source: new OSM(),
      visible: mapStyle === 'basic',
      extent,
    });

    satelliteLayerRef.current = new TileLayer({
      source: new XYZ({
        url: 'https://tiles-test.server.clicker.team/tiles/v2/milic.scekic@clicker.team/26b84ac1-5bf5-4393-9b5e-be6bf79b93c5/{z}/{x}/{y}.png',
        crossOrigin: 'anonymous',
        minZoom: 6,
        cacheSize: 1000,
        transition: 0,
      }),
      visible: mapStyle === 'satellite',
    });

    const map = new Map({
      target: mapRef.current as HTMLElement,
      controls: [new FullScreen(), new Zoom()],
      layers: [osmLayerRef.current, satelliteLayerRef.current],
      view: new View({
        center: fromLonLat([-105.9, 39.1]),
        zoom: 8,
        minZoom: 6,
        projection: 'EPSG:3857',
        extent,
      }),
    });

    mapInstanceRef.current = map;

    map.getView().fit(extent, { size: map.getSize() });

    return () => {
      map.setTarget(undefined);
    };
  }, [mapRef]);

  // Handle switching layers visibility based on mapStyle
  useEffect(() => {
    if (osmLayerRef.current && satelliteLayerRef.current) {
      osmLayerRef.current.setVisible(mapStyle === 'basic');
      satelliteLayerRef.current.setVisible(mapStyle === 'satellite');
    }
  }, [mapStyle]);

  return mapInstanceRef;
};

export default useMap;
