import { Card, Col, Divider, Row, Typography } from 'antd';
import React from 'react';

import { decimalToHex } from '../util';
import { eventColors } from '../variables/nwsAlerts';

const { Title, Paragraph, Text } = Typography;

interface Geometry {
  disposed: boolean;
  extent_: number[];
  layout: string;
  flatCoordinates: number[];
}

interface Geocode {
  SAME: string[];
  UGC: string[];
}

interface Parameters {
  AWIPSidentifier: string[];
  WMOidentifier: string[];
  NWSheadline: string[];
  eventMotionDescription: string[];
  maxWindGust: string[];
  maxHailSize: string[];
  BLOCKCHANNEL: string[];
  'EAS-ORG': string[];
}

export interface JsonData {
  geometry: Geometry;
  '@id': string;
  '@type': string;
  id: string;
  areaDesc: string;
  geocode: Geocode;
  affectedZones: string[];
  sent: string;
  effective: string;
  onset: string;
  expires: string;
  status: string;
  messageType: string;
  category: string;
  severity: string;
  certainty: string;
  urgency: string;
  event: string;
  sender: string;
  senderName: string;
  headline: string;
  description: string;
  instruction: string;
  response: string;
  parameters: Parameters;
  featType: string;
  region: string;
}

export function isJsonData(obj: any): obj is JsonData {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj['@id'] === 'string' &&
    typeof obj['@type'] === 'string' &&
    typeof obj.id === 'string' &&
    typeof obj.areaDesc === 'string' &&
    typeof obj.geocode === 'object' &&
    Array.isArray(obj.affectedZones) &&
    obj.affectedZones.every((zone: any) => typeof zone === 'string') &&
    typeof obj.sent === 'string' &&
    typeof obj.effective === 'string' &&
    typeof obj.onset === 'string' &&
    typeof obj.expires === 'string' &&
    typeof obj.status === 'string' &&
    typeof obj.messageType === 'string' &&
    typeof obj.category === 'string' &&
    typeof obj.severity === 'string' &&
    typeof obj.certainty === 'string' &&
    typeof obj.urgency === 'string' &&
    typeof obj.event === 'string' &&
    typeof obj.sender === 'string' &&
    typeof obj.senderName === 'string' &&
    typeof obj.headline === 'string' &&
    typeof obj.description === 'string' &&
    typeof obj.instruction === 'string' &&
    typeof obj.response === 'string' &&
    typeof obj.parameters === 'object' &&
    typeof obj.featType === 'string' &&
    typeof obj.region === 'string' &&
    Array.isArray(obj.geocode.SAME) &&
    obj.geocode.SAME.every((code: any) => typeof code === 'string') &&
    Array.isArray(obj.geocode.UGC) &&
    obj.geocode.UGC.every((code: any) => typeof code === 'string') &&
    typeof obj.parameters === 'object' &&
    Object.keys(obj.parameters).every(
      (key) =>
        Array.isArray(obj.parameters[key]) &&
        obj.parameters[key].every((param: any) => typeof param === 'string'),
    )
  );
}

export const AlertData: React.FC<{ jsonData: JsonData }> = ({ jsonData }) => {
  return (
    <Card style={{ margin: '20px', padding: '20px' }}>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={24} style={{ display: 'flex' }}>
          <Card
            bordered={false}
            style={{
              flex: 1,
              backgroundColor: eventColors[String(jsonData.event)] + decimalToHex(0.2),
            }}
          >
            <Paragraph>
              <Title level={4}>{jsonData.headline}</Title>
            </Paragraph>
            <Paragraph>
              <Text strong>Description:</Text> <div>{jsonData.description}</div>
            </Paragraph>
            <Paragraph>
              <Text strong>Instruction:</Text> {jsonData.instruction || 'N/A'}
            </Paragraph>
            <Paragraph>
              <Text strong>Response:</Text> {jsonData.response}
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex' }}>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Alert Details</Title>
            <Paragraph>
              <Text strong>Status:</Text> {jsonData.status}
            </Paragraph>
            <Paragraph>
              <Text strong>Message Type:</Text> {jsonData.messageType}
            </Paragraph>
            <Paragraph>
              <Text strong>Category:</Text> {jsonData.category}
            </Paragraph>
            <Paragraph>
              <Text strong>Severity:</Text> {jsonData.severity}
            </Paragraph>
            <Paragraph>
              <Text strong>Certainty:</Text> {jsonData.certainty}
            </Paragraph>
            <Paragraph>
              <Text strong>Urgency:</Text> {jsonData.urgency}
            </Paragraph>
            <Paragraph>
              <Text strong>Event:</Text> {jsonData.event}
            </Paragraph>
          </Card>
        </Col>
        <Col span={12} style={{ display: 'flex' }}>
          <Card style={{ flex: 1 }} bordered={false}>
            <Title level={4}>Alert Description</Title>
            <Paragraph>
              <Text strong>Sender:</Text> {jsonData.sender}
            </Paragraph>
            <Paragraph>
              <Text strong>Sender Name:</Text> {jsonData.senderName}
            </Paragraph>
            <Paragraph>
              <Text strong>Sent:</Text> {new Date(jsonData.sent).toLocaleString()}
            </Paragraph>
            <Paragraph>
              <Text strong>Effective:</Text> {new Date(jsonData.effective).toLocaleString()}
            </Paragraph>
            <Paragraph>
              <Text strong>Onset:</Text> {new Date(jsonData.onset).toLocaleString()}
            </Paragraph>
            <Paragraph>
              <Text strong>Expires:</Text> {new Date(jsonData.expires).toLocaleString()}
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex' }}>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Geocode</Title>
            <Paragraph>
              <Text strong>SAME Codes:</Text> {jsonData.geocode.SAME.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>UGC Codes:</Text> {jsonData.geocode.UGC.join(', ')}
            </Paragraph>
          </Card>
        </Col>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Affected Zones</Title>
            <ul>
              {jsonData.affectedZones.map((zone, index) => (
                <li key={index}>{zone}</li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex' }}>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Geometry</Title>
            <Paragraph>
              <Text strong>Disposed:</Text> {String(jsonData.geometry.disposed)}
            </Paragraph>
            <Paragraph>
              <Text strong>Extent:</Text> {jsonData.geometry.extent_.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>Layout:</Text> {jsonData.geometry.layout}
            </Paragraph>
            <Paragraph>
              <Text strong>Flat Coordinates Count:</Text> {jsonData.geometry.flatCoordinates.length}
            </Paragraph>
          </Card>
        </Col>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Additional Parameters</Title>
            <Paragraph>
              <Text strong>AWIPS Identifier:</Text> {jsonData.parameters.AWIPSidentifier.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>WMO Identifier:</Text> {jsonData.parameters.WMOidentifier.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>NWS Headline:</Text> {jsonData.parameters.NWSheadline.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>Event Motion Description:</Text>{' '}
              {jsonData.parameters.eventMotionDescription.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>Max Wind Gust:</Text> {jsonData.parameters.maxWindGust.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>Max Hail Size:</Text> {jsonData.parameters.maxHailSize.join(', ')}
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Divider />
    </Card>
  );
};
