import { useKeycloak } from '@react-keycloak/web';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getProjectDetails } from '../../core/api/ProjectsAPI';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { parseScenes } from '../../helpers/timelineUtil';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { DataFrameDef } from '../../model/definitions/DataFrameDef';
import { loadFrames } from '../../pages/playground/loadFrames';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { updateWdFramesFromNewProjectState } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
// import { coloradoClone } from './coloradoCloneProject';

const key = 'UPDATE_PROJECT_FRAMES';

interface Props {
  onPause: () => void;
  onPlay: () => void;
}

export function areFramesSame(current: DataFrameDef[], incoming: DataFrameDef[]) {
  return (
    current[0]?.frameId === incoming[0]?.frameId &&
    current[current.length - 1]?.frameId === incoming[incoming.length - 1]?.frameId
  );
}

function hasNewFrames(current: C9ProjectDef, incoming: C9ProjectDef) {
  let hasNew = false;
  incoming.sceneDefs.forEach((sc) => {
    const sceneFound = current.sceneDefs.find((s) => s.id === sc.id);
    if (sceneFound) {
      sc.mapPanels.forEach((mp) => {
        const mapFound = sceneFound.mapPanels.find((m) => m.id === mp.id);
        if (mapFound) {
          mp.wdSpace.forEach((wd) => {
            const wdFound = mapFound.wdSpace.find((w) => w.id === wd.id);

            if (wdFound) {
              wd.radarMapLayers.forEach((rl, idx) => {
                const rlFound = wdFound.radarMapLayers[idx];

                if (rlFound && !areFramesSame(rlFound.dataFrames, rl.dataFrames)) {
                  hasNew = true;
                }
              });
              wd.satelliteMapLayers.forEach((sl, idx) => {
                const slFound = wdFound.satelliteMapLayers[idx];
                if (slFound && !areFramesSame(slFound.dataFrames, sl.dataFrames)) {
                  hasNew = true;
                }
              });
              wd.gribMapLayers.forEach((gl, idx) => {
                const glFound = wdFound.gribMapLayers[idx];
                if (glFound && !areFramesSame(glFound.dataFrames, gl.dataFrames)) {
                  hasNew = true;
                }
              });
            }
          });
        }
      });
    }
  });
  return hasNew;
}

export const useUpdateProjectFrames = ({ onPause, onPlay }: Props) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const id = params.get('projectId');
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const isUpdatingFrames = useRef(false);
  const simpleScenes = useMemo(() => {
    return parseScenes(project);
  }, [project]);
  const [projectWithFramesToSet, setProjectWithFramesToSet] = useState<C9ProjectDef | null>(null);
  const contextValue = useContext(PlayerContext);
  // console.log('simple scenes ', simpleScenes);
  const {
    keycloak: { idTokenParsed },
  } = useKeycloak();
  const { data, isLoading } = useQuery(
    [key, id],
    async () => {
      const data = await getProjectDetails(id!);

      cb(data);
      return data;
      // cb(coloradoClone);
      // return coloradoClone;
    },
    {
      enabled: !!id,
      //   refetchInterval: 1000 * 60 * 5,
      refetchInterval: 60000,
    },
  );

  useEffect(
    () => {
      if (!projectWithFramesToSet || isUpdatingFrames.current) return;

      if (hasNewFrames(projectWithFramesToSet, project)) {
        const isPlaying = contextValue.isPlaying === PlaybackEnum.PLAYING;
        const sceneTransitionTimes = simpleScenes.map((sc) => sc.endMS);
        const isInUpdateTime = sceneTransitionTimes.some(
          (endTime) => endTime - 100 > contextValue.time && contextValue.time < endTime + 50,
        );
        const shouldUpdate = !isPlaying || isInUpdateTime;
        if (shouldUpdate) {
          if (isPlaying) onPause();
          isUpdatingFrames.current = true;
          setTimeout(() => {
            console.log('UPDATING FRAMES!!!! ');
            dispatch(updateWdFramesFromNewProjectState({ newProject: projectWithFramesToSet }));
            setProjectWithFramesToSet(null);
            isUpdatingFrames.current = false;
          }, 50);
          if (isPlaying)
            setTimeout(() => {
              onPlay();
            }, 550);
        }
      }
    } /*[contextValue.isPlaying, contextValue.time, projectWithFramesToSet, simpleScenes] */,
    [projectWithFramesToSet, contextValue.time, simpleScenes, project, contextValue.isPlaying],
  );

  async function cb(updatedProject: C9ProjectDef) {
    const currentData = updatedProject;
    const framesLoaded = await loadFrames(
      updatedProject.sceneDefs,
      dispatch,
      idTokenParsed!,
      undefined,
      true,
    );

    if (framesLoaded && currentData) {
      setProjectWithFramesToSet(currentData);
    }
  }
  return { data, isLoading };
};
