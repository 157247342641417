import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, set } from 'lodash';

import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { ZoomEnum } from '../../core/ui/enums/ZoomEnum';
import { AnimationPanelDef } from '../../model/definitions/AnimationPanelDef';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { ForecastWDElementDef } from '../../model/definitions/ForecastWDElementDef';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { ImagePanelDef } from '../../model/definitions/ImagePanelDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../../model/definitions/PointDateDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import { SceneDef } from '../../model/definitions/SceneDef';
import { SymbolLayerDef } from '../../model/definitions/SymbolLayerDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { DrawingTypeEnum } from '../../model/enums/DrawingTypeEnum';
import { FrontTypeEnum } from '../../model/enums/FrontTypeEnum';
import { ValueTypeEnum } from '../../model/enums/ValueTypeEnum';
import { GroupingEnum } from '../../model/UI/enums/GroupingEnum';
import { drawingColors } from '../../molecules/mapElement/drawHeplers';
import { calculateCanvasSizeForViewport } from '../../molecules/mapElement/helpers';
import { AllElementsDefs, ElementType, Multiselection } from '../../types/elements';

export type PosterContent = (
  | VideoPanelDef
  | ObservedWDElementDef
  | ImagePanelDef
  | AnimationPanelDef
  | TextPanelDef
  | ForecastWDElementDef
  | WeatherPosterDef
  | PointDateDef
) & {
  type: SceneKeys<SceneDef>;
};
type ClipboardType = {
  type:
    | 'imagePanels'
    | 'animationPanels'
    | 'videoPanels'
    | 'textPanels'
    | 'mapPanels'
    | 'observedWDElements'
    | 'forecastWDElements'
    | 'weatherPosters';
  element:
    | TextPanelDef
    | VideoPanelDef
    | ImagePanelDef
    | AnimationPanelDef
    | MapPanelDef
    | ObservedWDElementDef
    | ForecastWDElementDef
    | WeatherPosterDef;
};

export type DrawType = {
  enabled: boolean;
  drawingType: DrawingTypeEnum | '';
  strokeWidth: number;
  lineFill: string;
  objectFill: string;
  warmFront: string;
  stationaryWarm: string;
  stationaryCold: string;
  coldFront: string;
  occludedFront: string;
  occludedFrontV2: string;
  outflowFront: string;
  frontType: FrontTypeEnum | '';
  frontDensity: number;
  sizeOnePart: number;
  lineGap: number;
  oneDashLength: number;
  hornOrientation: boolean;
  // enableEditing: boolean;
  save: boolean;
  deleteFeature: boolean;
  modify: boolean;
  zIndex: number;
  image: string;
  imageWidth: number;
  imageHeight: number;
  name?: string;
};

export interface ActiveDef {
  biasMode: boolean;
  biasParam?: string;
  biasPanel?: MapPanelDef | WeatherPosterDef;
  biasedValues: Array<ForecastWDElementDef | ObservedWDElementDef>;
  addedBiasedValues: Array<ForecastWDElementDef | ObservedWDElementDef>;
  activeScene: string;
  activeElement: string;
  activeMap: string;
  activeProp: string;
  activeTime: number;
  activeDuration: number;
  activeZoom: number;
  mapsRendered: Record<string, boolean>;
  mapsAllowedZoom: Record<string, boolean>;
  activeFramerate: 25 | 30 | 50 | 60;
  mode: ModeEnum;
  projectToPlay: C9ProjectDef;
  activeFlyOvers: Array<string>;
  displayedFlyOvers: Array<string>;
  activeAspectRatio: [number, number];
  oceanMask: boolean;
  activeGribTime: number | null;
  grouping: GroupingEnum;
  activePoster: string;
  openGroups: string[];
  posterMode: boolean;
  layersToLoadRest: Record<string, boolean>;
  posterOpen: string[];
  posterContent: Array<PosterContent>;
  posterPosition: PositionControlDef;
  clipboard: ClipboardType | undefined;
  observedTemp: Array<WeatherPosterDef>;
  forecastTemp: Array<WeatherPosterDef>;
  dispatchedTemp: boolean;
  syncSpace: Array<string>;
  propertyGridActiveHash: Record<string, string>;
  previewSize: number;
  activeDraw: DrawType;
  activeDrawEdit: Record<string, Record<string, any>[]>;
  activeDrawing: { featureId: string | null; drawingId: string | null };
  viewportSize: [number, number];
  fullCanvasSize: [number, number];
  isRecording: boolean;
  selectedLayer: [
    mapId: string | undefined,
    layerType: 'grib' | 'radar' | 'satellite' | 'symbol' | undefined,
    layerId: string | undefined,
  ];
  applyLayer?: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef;
  dictionary: string;
  translation: any;
  sceneTranslation: Record<string, any>;
  multiselect: Multiselection;
  activeSymbolPoint?: [string, string];
  symbolEditingLayerId: string;
  symbolNewOverrides: number;
  reloadPoints: boolean;
  spaceId: string | undefined;
  forecastSource: string | undefined;
  observedSource: string | undefined;
}
const initialState: ActiveDef = {
  biasMode: false,
  biasParam: undefined,
  biasPanel: undefined,
  biasedValues: [],
  addedBiasedValues: [],
  activeScene: '',
  activeMap: '',
  activeElement: '',
  activeTime: 0,
  activeProp: '',
  activeDuration: 90000,
  activeZoom: 0,
  activeFramerate: 60,
  mode: ModeEnum.PROJECT,
  projectToPlay: new C9ProjectDef(),
  activeFlyOvers: [],
  displayedFlyOvers: [],
  activeAspectRatio: [16, 9],
  clipboard: undefined,
  activeGribTime: null,
  oceanMask: false,
  grouping: GroupingEnum.OFF,
  mapsRendered: {},
  mapsAllowedZoom: {},
  layersToLoadRest: {},
  posterMode: false,
  posterContent: [],
  posterPosition: new PositionControlDef(),
  openGroups: ['00000000-0000-0000-0000-000000000000'],
  activePoster: '',
  observedTemp: [],
  forecastTemp: [],
  posterOpen: [],
  dispatchedTemp: false,
  syncSpace: [],
  propertyGridActiveHash: {},
  activeDrawing: { drawingId: null, featureId: null },
  activeDraw: {
    enabled: false,
    drawingType: '',
    strokeWidth: 1,
    lineFill: drawingColors.lineFill,
    objectFill: drawingColors.objectFill,
    warmFront: drawingColors.warmFront,
    stationaryWarm: drawingColors.warmFront,
    stationaryCold: drawingColors.coldFront,
    coldFront: drawingColors.coldFront,
    occludedFront: drawingColors.occludedFront,
    occludedFrontV2: drawingColors.occludedFrontV2,
    outflowFront: drawingColors.outflowFront,
    frontDensity: 20,
    sizeOnePart: 50,
    frontType: '',
    lineGap: 13,
    oneDashLength: 17,
    hornOrientation: false,
    save: false,
    deleteFeature: false,
    modify: false,
    zIndex: 10,
    image: '',
    imageWidth: 5,
    imageHeight: 10,
  },
  activeDrawEdit: {},

  previewSize: localStorage.getItem('preview') ? Number(localStorage.getItem('preview')) : 350,
  isRecording: false,

  viewportSize: [window.innerHeight, window.innerWidth],
  fullCanvasSize: calculateCanvasSizeForViewport([window.innerHeight, window.innerWidth], [16, 9]),
  selectedLayer: [undefined, undefined, undefined],
  applyLayer: undefined,
  dictionary: '',
  translation: undefined,
  sceneTranslation: {},
  multiselect: [],
  activeSymbolPoint: undefined,
  symbolEditingLayerId: '',
  symbolNewOverrides: 0,
  reloadPoints: true,
  spaceId: undefined,
  forecastSource: undefined,
  observedSource: undefined,
};
function isObserved(obj: any): obj is ObservedWDElementDef {
  return 'observedWDSource' in obj;
}
function isForecast(obj: any): obj is ForecastWDElementDef {
  return 'forecastWDSource' in obj;
}
const activeSlice = createSlice({
  name: 'active',
  initialState,
  reducers: {
    addToMultiselect(
      state,
      action: PayloadAction<{
        element: { type: ElementType; element: AllElementsDefs; mapId?: string; parentId?: string };
      }>,
    ) {
      const {
        payload: { element },
      } = action;
      const { parentId, mapId } = element;
      state.activeElement = '';
      state.activeProp = '';
      const multiselect = state.multiselect;
      const isInType = multiselect.find((types) => types.type === element.type);
      if (isInType) {
        const existing = isInType.elements.find((item) => item.element.id === element.element.id);
        if (existing) {
          const index = isInType.elements.findIndex(
            (elmnt) => elmnt.element.id === element.element.id,
          );
          isInType.elements.splice(index, 1);
          if (isInType.elements.length === 0) {
            const index = multiselect.findIndex((section) => section.type === element.type);
            state.multiselect.splice(index, 1);
          }
        } else {
          isInType.elements.push({
            element: element.element,
            parentId,
            mapId,
          });
        }
      } else {
        state.multiselect = [
          ...multiselect,
          {
            type: element.type,
            elements: [
              {
                element: element.element,
                parentId,
                mapId,
              },
            ],
          },
        ];
      }
      return state;
    },
    updateMultiselectTime(
      state,
      action: PayloadAction<{ prop: keyof TimeControlDef; value: any }>,
    ) {
      const {
        payload: { prop, value },
      } = action;
      state.multiselect.forEach((selection) =>
        selection.elements.forEach(
          (element) =>
            (element.element.timeControls[0] = {
              ...element.element.timeControls[0],
              [prop]: value,
            }),
        ),
      );
    },
    setScene(state, action: PayloadAction<{ activeScene: string }>) {
      const {
        payload: { activeScene },
      } = action;
      state.activeScene = activeScene;
      //state.activeDrawing = { drawingId: null, featureId: null };
      state.activeDraw = {
        enabled: false,
        drawingType: '',
        strokeWidth: 1,
        lineFill: drawingColors.lineFill,
        objectFill: drawingColors.objectFill,
        warmFront: drawingColors.warmFront,
        stationaryWarm: drawingColors.warmFront,
        stationaryCold: drawingColors.coldFront,
        coldFront: drawingColors.coldFront,
        occludedFront: drawingColors.occludedFront,
        occludedFrontV2: drawingColors.occludedFrontV2,
        outflowFront: drawingColors.outflowFront,
        frontDensity: 20,
        sizeOnePart: 50,
        frontType: '',
        lineGap: 13,
        oneDashLength: 17,
        hornOrientation: false,
        save: false,
        deleteFeature: false,
        modify: false,
        zIndex: 10,
        image: '',
        imageWidth: 5,
        imageHeight: 10,
      };
    },
    setDuration(state, action: PayloadAction<{ duration: number }>) {
      const {
        payload: { duration },
      } = action;
      state.activeDuration = duration === 0 ? 90000 : duration;
    },
    setForecastSource(state, action: PayloadAction<{ source: string }>) {
      const {
        payload: { source },
      } = action;
      state.forecastSource = source;
    },
    setObservedSource(state, action: PayloadAction<{ source: string }>) {
      const {
        payload: { source },
      } = action;
      state.observedSource = source;
    },
    setLayerType(
      state,
      action: PayloadAction<{
        mapId: string | undefined;
        layerType: 'grib' | 'radar' | 'satellite' | 'symbol' | undefined;
        layerId: string | undefined;
      }>,
    ) {
      const {
        payload: { mapId, layerType, layerId },
      } = action;
      state.selectedLayer = [mapId, layerType, layerId];
      return state;
    },
    setApplyLayer(
      state,
      action: PayloadAction<{
        layer?: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef;
      }>,
    ) {
      const {
        payload: { layer },
      } = action;
      state.applyLayer = layer;
      return state;
    },
    setClipboard(
      state,
      action: PayloadAction<{
        type:
          | 'imagePanels'
          | 'animationPanels'
          | 'videoPanels'
          | 'textPanels'
          | 'mapPanels'
          | 'observedWDElements'
          | 'forecastWDElements'
          | 'weatherPosters';
        element:
          | TextPanelDef
          | VideoPanelDef
          | ImagePanelDef
          | AnimationPanelDef
          | MapPanelDef
          | WeatherPosterDef
          | ObservedWDElementDef
          | ForecastWDElementDef;
      }>,
    ) {
      const {
        payload: { element, type },
      } = action;
      state.clipboard = { type: type, element: element };
    },
    setElement(
      state,
      action: PayloadAction<{
        activeElement: string;
        activeProp:
          | keyof SceneDef
          | ''
          | 'layerLegend'
          | 'cityGeoPoster'
          | 'mapTimeframeTextIndicator'
          | 'drawLayer'
          | 'videoOver';
        parentId?: string;
        mapId?: string;
        spaceId?: string;
      }>,
    ) {
      const {
        payload: { activeElement, activeProp, parentId, mapId, spaceId },
      } = action;
      state.multiselect = [];
      state.activeElement = activeElement;
      state.activeProp = activeProp;
      state.activePoster = parentId ?? '';
      state.activeMap = mapId ?? '';
      state.spaceId = spaceId ?? '';
    },
    setSymbolPoint(state, action: PayloadAction<{ point?: [string, string] }>) {
      const {
        payload: { point },
      } = action;
      state.activeSymbolPoint = point;
      return state;
    },
    setTime(state, action: PayloadAction<{ activeTime: number }>) {
      const {
        payload: { activeTime },
      } = action;
      state.activeTime = activeTime;
    },
    setMode(state, action: PayloadAction<{ mode: ModeEnum }>) {
      const {
        payload: { mode },
      } = action;
      state.activeDraw.enabled = false;
      state.mode = mode;
    },
    setDictionary(state, action: PayloadAction<{ dictionary: string }>) {
      const {
        payload: { dictionary },
      } = action;
      state.dictionary = dictionary;
      return state;
    },
    setTranslation(state, action: PayloadAction<{ translation: any }>) {
      const {
        payload: { translation },
      } = action;
      state.translation = translation;
      return state;
    },
    setSceneTranslation(state, action: PayloadAction<{ translation: any; scene: string }>) {
      const {
        payload: { translation, scene },
      } = action;
      state.sceneTranslation[scene] = translation;
      return state;
    },
    setGrouping(state, action: PayloadAction<{ mode: GroupingEnum }>) {
      const {
        payload: { mode },
      } = action;
      state.grouping = mode;
    },
    setProjectToPlay(state, action: PayloadAction<{ projectToPlay: C9ProjectDef }>) {
      const {
        payload: { projectToPlay },
      } = action;
      state.projectToPlay = projectToPlay;
    },
    updateMapLayerToPlay(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<MapPanelDef> | keyof MapPanelDef;
        activeScene: string | number;
        elementId: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue },
      } = action;
      const toBeChanged = state.projectToPlay.sceneDefs
        .find((sc) => sc.id === activeScene)
        ?.mapPanels?.find((tp) => tp.id === elementId);
      if (toBeChanged) set(toBeChanged!, propertyPath, newValue);
      return state;
    },
    removeActiveScene(state) {
      state.activeScene = '';
      state.activeElement = '';
      state.activeProp = '';
    },
    setZoom(
      state,
      action: PayloadAction<{
        type: ZoomEnum;
      }>,
    ) {
      const {
        payload: { type },
      } = action;
      if (type === ZoomEnum.FIT) state.activeZoom = ZoomEnum.FIT;
      if (state.activeZoom > 1 && type === ZoomEnum.OUT)
        state.activeZoom = state.activeZoom + Number(type);
      if (type === ZoomEnum.IN) state.activeZoom = state.activeZoom + Number(type);
    },
    setActiveFrameRate(
      state,
      action: PayloadAction<{
        fps: 25 | 30 | 50 | 60;
      }>,
    ) {
      const {
        payload: { fps },
      } = action;
      state.activeFramerate = fps;
    },
    setObservedTemp(state, action: PayloadAction<{ observed: WeatherPosterDef }>) {
      const {
        payload: { observed },
      } = action;
      const index = state.observedTemp.findIndex((poster) => poster.id === observed.id);
      if (index > -1) {
        const array = [...state.observedTemp];
        array[index] = observed;
        state.observedTemp = array;
      } else state.observedTemp.push(observed);
      return state;
    },
    updateObservedTemp(
      state,
      action: PayloadAction<{ id: string; data: Array<ObservedWDElementDef> }>,
    ) {
      const {
        payload: { id, data },
      } = action;
      const posterToChange = state.observedTemp.find((poster) => poster.id === id);
      if (posterToChange) {
        const elements = posterToChange.observedWDElements;
        posterToChange.observedWDElements = elements.map((item1) => {
          const item2 = data.find((item2) => item2.id === item1.id);
          if (item2) {
            return { ...item1, ...item2 };
          }
          return item1;
        });
      }
      return state;
    },
    resetObservedTemp(state) {
      state.observedTemp = [];
    },
    setForecastTemp(state, action: PayloadAction<{ forecast: WeatherPosterDef }>) {
      const {
        payload: { forecast },
      } = action;
      const index = state.forecastTemp.findIndex((poster) => poster.id === forecast.id);
      if (index > -1) {
        const array = [...state.forecastTemp];
        array[index] = forecast;
        state.forecastTemp = array;
      } else state.forecastTemp.push(forecast);
      return state;
    },
    updateForecastTemp(
      state,
      action: PayloadAction<{ id: string; data: Array<ForecastWDElementDef> }>,
    ) {
      const {
        payload: { id, data },
      } = action;
      const posterToChange = state.forecastTemp.find((poster) => poster.id === id);
      if (posterToChange) {
        const elements = posterToChange.forecastWDElements;
        posterToChange.forecastWDElements = elements.map((item1) => {
          const item2 = data.find((item2) => item2.id === item1.id);
          if (item2) {
            return { ...item1, ...item2 };
          }
          return item1;
        });
      }
      return state;
    },
    resetForecastTemp(state) {
      state.forecastTemp = [];
    },
    tempDone(state) {
      state.dispatchedTemp = false;
    },
    setActiveAspectRatio(
      state,
      action: PayloadAction<{
        aspectRatio: [number, number];
      }>,
    ) {
      const {
        payload: { aspectRatio },
      } = action;
      state.activeAspectRatio = aspectRatio;
    },
    setOpenPosters(state, action: PayloadAction<{ poster: string }>) {
      const {
        payload: { poster },
      } = action;
      const index = state.posterOpen.indexOf(poster);
      index < 0 ? state.posterOpen.push(poster) : state.posterOpen.splice(index, 1);
    },
    setOpenGroup(state, action: PayloadAction<{ groupId: string }>) {
      const {
        payload: { groupId },
      } = action;
      const index = state.openGroups.indexOf(groupId);
      if (index < 0) state.openGroups.push(groupId);
      else state.openGroups.splice(index, 1);
    },
    setSyncSpace(state, action: PayloadAction<{ spaceId: string }>) {
      const {
        payload: { spaceId },
      } = action;
      const index = state.syncSpace.indexOf(spaceId);
      if (index < 0) state.syncSpace.push(spaceId);
      else state.syncSpace.splice(index, 1);
    },
    setDisplayedFlyOvers(state, action: PayloadAction<{ displayedFlyOver: string }>) {
      const {
        payload: { displayedFlyOver },
      } = action;
      const index = state.displayedFlyOvers.indexOf(displayedFlyOver);
      index < 0 && state.displayedFlyOvers.push(displayedFlyOver);
    },
    setActiveGribTime(state, action: PayloadAction<{ activeGribTime: number | null }>) {
      const {
        payload: { activeGribTime },
      } = action;
      state.activeGribTime = activeGribTime;
    },
    removeDisplayedFlyOvers(state, action: PayloadAction<{ displayedFlyOvers: string }>) {
      const {
        payload: { displayedFlyOvers },
      } = action;
      const index = state.displayedFlyOvers.indexOf(displayedFlyOvers);
      index >= 0 && state.displayedFlyOvers.splice(index, 1);
    },
    setOceanMask(state, action: PayloadAction<{ active: boolean }>) {
      const {
        payload: { active },
      } = action;
      state.oceanMask = active;
    },
    setMapsRendered(state, action: PayloadAction<{ mapId: string; rendered: boolean }>) {
      const {
        payload: { mapId, rendered },
      } = action;
      state.mapsRendered[mapId] = rendered;
    },
    setMapAllowedZoom(state, action: PayloadAction<{ mapId: string; allow: boolean }>) {
      const {
        payload: { mapId, allow },
      } = action;
      state.mapsAllowedZoom[mapId] = allow;
    },
    setPosterMode(state) {
      if (state.posterMode) state.posterContent = [];
      state.posterMode = !state.posterMode;
    },
    resetPoster(state) {
      state.posterContent = [];
    },
    editPreviewSize(state, action: PayloadAction<{ size: number }>) {
      const {
        payload: { size },
      } = action;

      state.previewSize = size;
    },
    setPoster(state, action: PayloadAction<{ posterId: string }>) {
      const {
        payload: { posterId },
      } = action;
      state.activePoster = posterId;
    },
    populatePoster(
      state,
      action: PayloadAction<{
        element: (
          | VideoPanelDef
          | ObservedWDElementDef
          | ImagePanelDef
          | TextPanelDef
          | ForecastWDElementDef
          | WeatherPosterDef
          | PointDateDef
        ) & { type: SceneKeys<SceneDef> };
      }>,
    ) {
      const {
        payload: { element },
      } = action;
      const { posterContent } = state;
      const index = posterContent.findIndex((elem) => elem.id === element.id);
      if (index < 0) state.posterContent.push(element);
      else state.posterContent.splice(index, 1);
      let smallestX = Infinity;
      let smallestY = Infinity;
      let highestW = 0;
      let highestH = 0;
      posterContent.forEach((obj) => {
        if (obj.positionControl && obj.positionControl.y && obj.positionControl.y < smallestY) {
          smallestY = obj.positionControl.y;
        }
      });
      posterContent.forEach((obj) => {
        if (obj.positionControl && obj.positionControl.x && obj.positionControl.x < smallestX) {
          smallestX = obj.positionControl.x;
        }
      });
      posterContent.forEach((obj) => {
        if (obj.positionControl && obj.positionControl.w && obj.positionControl.x) {
          const currentW = obj.positionControl.x + obj.positionControl.w;
          if (currentW > highestW) {
            highestW = currentW;
          }
        }
      });
      posterContent.forEach((obj) => {
        if (obj.positionControl && obj.positionControl.h && obj.positionControl.y) {
          const currentH = obj.positionControl.y + obj.positionControl.h;
          if (currentH > highestH) {
            highestH = currentH;
          }
        }
      });
      state.posterPosition = new PositionControlDef(
        highestW - smallestX,
        highestH - smallestY,
        smallestX,
        smallestY,
      );
      return state;
    },
    setPropertyGridActiveHash(
      state,
      action: PayloadAction<{ activeElement: string; focusedEl: string }>,
    ) {
      const {
        payload: { activeElement, focusedEl },
      } = action;
      state.propertyGridActiveHash[activeElement] = focusedEl;
    },
    setActiveDraw(
      state,
      action: PayloadAction<{ newValue: number | string | boolean; path: Paths<DrawType> }>,
    ) {
      const {
        payload: { newValue, path },
      } = action;
      set(state.activeDraw, path, newValue);
      return state;
    },
    updateActiveDrawEdit(
      state,
      action: PayloadAction<{
        newValue: Record<string, any>[];
        layerId: string;
      }>,
    ) {
      const {
        payload: { newValue, layerId },
      } = action;
      state.activeDrawEdit[layerId] = newValue;
    },
    setActiveDrawEdit(
      state,
      action: PayloadAction<{
        value: Record<string, Record<string, any>[]>;
      }>,
    ) {
      const {
        payload: { value },
      } = action;
      state.activeDrawEdit = value;
    },
    alignPoster(state, action: PayloadAction<{ elements: Array<PosterContent> }>) {
      const {
        payload: { elements },
      } = action;
      state.posterContent = elements;
      return state;
    },
    refreshActiveDrawEdit(state) {
      // trigger useEffect
      state.activeDrawEdit = cloneDeep(state.activeDrawEdit);
    },
    setActiveDrawing(
      state,
      action: PayloadAction<{ featureId: string | null; drawingId: string | null }>,
    ) {
      const {
        payload: { featureId, drawingId },
      } = action;
      state.activeDrawing = { featureId, drawingId };
    },
    setViewportSize(state, action: PayloadAction<{ width: number; height: number }>) {
      const {
        payload: { width, height },
      } = action;
      state.viewportSize = [height, width];
      state.fullCanvasSize = calculateCanvasSizeForViewport(
        [height, width],
        state.activeAspectRatio,
      );
    },
    setRecordingState(state, action: PayloadAction<{ isRecording: boolean }>) {
      const {
        payload: { isRecording },
      } = action;
      state.isRecording = isRecording;
      return state;
    },
    toggleBiasMode(
      state,
      action: PayloadAction<{ biasPanel?: MapPanelDef | WeatherPosterDef } | undefined>,
    ) {
      if (!state.biasMode) state.biasPanel = action.payload?.biasPanel;
      if (state.biasMode) {
        state.biasedValues = [];
        state.addedBiasedValues = [];
        state.biasParam = undefined;
        state.biasPanel = undefined;
      }
      state.biasMode = !state.biasMode;
      return state;
    },
    setActiveMap(
      state,
      action: PayloadAction<{
        mapId: string;
      }>,
    ) {
      const {
        payload: { mapId },
      } = action;
      state.activeMap = mapId;
      return state;
    },
    addPointForBiasObserved(
      state,
      action: PayloadAction<{
        point: ObservedWDElementDef;
      }>,
    ) {
      const {
        payload: { point },
      } = action;
      const visible = state.biasedValues;
      let edited = state.addedBiasedValues;
      const param =
        point.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
          ? point.observedWDSource.parameterType
          : 'icon';
      if (state.biasParam === undefined) {
        state.biasParam = param;
        const itemsToMove = edited.filter(
          (item) => isObserved(item) && item.observedWDSource.parameterType === param,
        );
        visible.push(...itemsToMove);
        edited = edited.filter(
          (item) => isObserved(item) && item.observedWDSource.parameterType !== param,
        );
      }
      const index = visible.findIndex((item) => item.id === point.id);
      if (index < 0 && (param === state.biasParam || state.biasParam === undefined)) {
        visible.push(point);
      }
      if (index < 0 && param !== state.biasParam && state.biasParam !== undefined) {
        return;
      }
      if (index >= 0) {
        visible.splice(index, 1);
      }
      if (visible.length === 0) {
        state.biasParam = undefined;
      }
      state.biasedValues = visible;
      state.addedBiasedValues = edited;
      return state;
    },
    addPointForBiasForecast(
      state,
      action: PayloadAction<{
        point: ForecastWDElementDef;
      }>,
    ) {
      const {
        payload: { point },
      } = action;
      const visible = state.biasedValues;
      let edited = state.addedBiasedValues;
      const param =
        point.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL
          ? point.forecastWDSource.parameterType
          : 'icon';
      if (state.biasParam === undefined) {
        state.biasParam = param;
        const itemsToMove = edited.filter(
          (item) => isForecast(item) && item.forecastWDSource.parameterType === param,
        );
        visible.push(...itemsToMove);
        edited = edited.filter(
          (item) => isForecast(item) && item.forecastWDSource.parameterType !== param,
        );
      }
      const index = visible.findIndex((item) => item.id === point.id);
      if (index < 0 && (param === state.biasParam || state.biasParam === undefined)) {
        visible.push(point);
      }
      if (index < 0 && param !== state.biasParam && state.biasParam !== undefined) {
        return;
      }
      if (index >= 0) {
        visible.splice(index, 1);
      }
      if (visible.length === 0) {
        state.biasParam = undefined;
      }
      state.biasedValues = visible;
      state.addedBiasedValues = edited;
      return state;
    },
    resetActiveState() {
      return { ...initialState };
    },
    updateObservedBiasValue(state, action: PayloadAction<{ id: string; value: string | number }>) {
      const {
        payload: { id, value },
      } = action;
      const element = state.biasedValues.find((item) => item.id === id) as ObservedWDElementDef;
      if (element) {
        const valueObject = element.observedWDSource.value.find(
          (val: { unit: string }) => val.unit === element.observedWDSource.unitOfMeasurement,
        );
        if (valueObject) {
          valueObject.value = value;
        }
      }
      return state;
    },
    updateObservedBiasIcon(state, action: PayloadAction<{ id: string; value: any }>) {
      const {
        payload: { id, value },
      } = action;
      const element = state.biasedValues.find((item) => item.id === id) as ObservedWDElementDef;
      if (element) {
        element.observedWDSource.value = value;
      }
      return state;
    },
    updateForecastBiasValue(state, action: PayloadAction<{ id: string; value: string | number }>) {
      const {
        payload: { id, value },
      } = action;
      const element = state.biasedValues.find((item) => item.id === id) as ForecastWDElementDef;
      if (element) {
        const valueObject = element.forecastWDSource.value.find(
          (val: { unit: string }) => val.unit === element.forecastWDSource.unitOfMeasurement,
        );
        if (valueObject) {
          valueObject.value = value;
        }
      }
      return state;
    },
    updateForecastBiasIcon(state, action: PayloadAction<{ id: string; value: any }>) {
      const {
        payload: { id, value },
      } = action;
      const element = state.biasedValues.find((item) => item.id === id) as ForecastWDElementDef;
      if (element) {
        element.forecastWDSource.value = value;
      }
      return state;
    },
    removeFromBiasFilter(state, action: PayloadAction<{ id: string }>) {
      const {
        payload: { id },
      } = action;
      const indexInCurrent = state.biasedValues.findIndex((obj) => obj.id === id);
      const indexInAdded = state.addedBiasedValues.findIndex((obj) => obj.id === id);

      if (indexInCurrent !== -1) {
        // Element with matching ID found
        state.biasedValues.splice(indexInCurrent, 1);
      }
      if (indexInAdded !== -1) {
        // Element with matching ID found
        state.addedBiasedValues.splice(indexInAdded, 1);
      }
    },
    addToConfirmedBias(state) {
      state.addedBiasedValues = [...state.addedBiasedValues, ...state.biasedValues];
      state.biasParam = undefined;
      state.biasedValues = [];
    },
    setSymbolEditingLayerId(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.symbolEditingLayerId = payload;
    },
    addSymbolNewOverrides(state) {
      state.symbolNewOverrides = state.symbolNewOverrides + 1;
    },
    setNewPoint(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.reloadPoints = payload;
    },
  },
});

export const {
  addPointForBiasObserved,
  addPointForBiasForecast,
  addToConfirmedBias,
  alignPoster,
  editPreviewSize,
  populatePoster,
  refreshActiveDrawEdit,
  removeActiveScene,
  removeFromBiasFilter,
  removeDisplayedFlyOvers,
  resetActiveState,
  resetForecastTemp,
  resetObservedTemp,
  resetPoster,
  setActiveAspectRatio,
  setActiveDraw,
  setActiveDrawEdit,
  setActiveDrawing,
  setActiveFrameRate,
  setActiveGribTime,
  setActiveMap,
  setClipboard,
  setDisplayedFlyOvers,
  setDuration,
  setElement,
  setSymbolPoint,
  setForecastTemp,
  setGrouping,
  setLayerType,
  setApplyLayer,
  setMapAllowedZoom,
  setMapsRendered,
  setMode,
  setObservedTemp,
  setOceanMask,
  setOpenGroup,
  setOpenPosters,
  setPoster,
  setPosterMode,
  setProjectToPlay,
  setPropertyGridActiveHash,
  setScene,
  setSyncSpace,
  setTime,
  setViewportSize,
  setZoom,
  tempDone,
  toggleBiasMode,
  updateActiveDrawEdit,
  updateForecastTemp,
  updateMapLayerToPlay,
  updateForecastBiasValue,
  updateObservedBiasIcon,
  updateForecastBiasIcon,
  updateObservedBiasValue,
  updateObservedTemp,
  setRecordingState,
  addToMultiselect,
  updateMultiselectTime,
  setDictionary,
  setTranslation,
  setSymbolEditingLayerId,
  setSceneTranslation,
  addSymbolNewOverrides,
  setNewPoint,
  setObservedSource,
  setForecastSource,
} = activeSlice.actions;
export default activeSlice.reducer;
