import { ConfigProvider, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { WeatherDataLoader } from '../../core/weather-data/WeatherDataLoader';
import { clearRenderCache } from '../../molecules/mapElement/helpers';
import { resetMapLoading } from '../../store/slices/map-loading.slice';
import { resetProject } from '../../store/slices/project-slice';
import { GlobalPlayerControl } from './GlobalPlayerControl';
import { MapsRenderCache } from './MapsRenderCache';
import { PlayerProvider, SkipInterface } from './playerContext/PlayerContext';
import Playground from './Playground';

const { darkAlgorithm } = theme;

const Studio = () => {
  const dispatch = useDispatch();
  const [playback, setPlayback] = useState<PlaybackEnum>(PlaybackEnum.STOP);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [playTime, setPlayTime] = useState<number>(0);
  const [skip, setSkip] = useState<SkipInterface>({ val: 0, ctrl: true });
  const [width, setWidth] = useState<number>(0);
  const [interval, setIntervalId] = useState<NodeJS.Timer>();
  const [boardSkip, setBoardSkip] = useState<number>(0);

  useEffect(() => {
    return () => {
      // Clear maps from cache to prevent memory leak
      MapsRenderCache.clear();
      // Reset map loading state
      dispatch(resetMapLoading());
      // Reset project state
      dispatch(resetProject());
      // Reset layer render cache
      clearRenderCache();
      // Reset loading cache
      WeatherDataLoader.clearCache();
    };
  }, [dispatch]);

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <PlayerProvider
        value={{
          isRecording: isRecording,
          setIsRecording: (e) => setIsRecording(e),
          skip: skip,
          setSkip: setSkip,
          time: playTime,
          setTime: (e) => {
            setPlayTime(e);
            GlobalPlayerControl.setTime(e);
          },
          isPlaying: playback,
          setIsPlaying: (e) => {
            setPlayback(e);
          },
          interval: 0 as unknown as ReturnType<typeof setInterval>,
          set: (e) => {
            setIntervalId(e);
          },
          clear: () => {
            clearInterval(interval);
          },
          width: width,
          setWidth: (e) => setWidth(e),
          boardSkip: boardSkip,
          setBoardSkip: (e) => setBoardSkip(e++),
        }}
      >
        <Playground />
      </PlayerProvider>
    </ConfigProvider>
  );
};
export default Studio;
