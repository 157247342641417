import { Button, Modal, Tabs } from 'antd';
import { useEffect, useRef } from 'react';

import { useGetSiteData } from '../hooks/apiPikAlert/useGetSiteData';
import DailySummary from './DailySummary';
import ForecastPlots from './ForecastPlots';
import ForecastTable from './ForecastTable';
import Loader from './Loader';

const SiteTable = ({ site, onClose }) => {
  const { data, isLoading } = useGetSiteData({
    site,
  });
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (data) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [data, onClose]);
  return (
    <Modal
      open={site}
      onClose={onClose}
      onCancel={onClose}
      ref={modalRef}
      width={'90%'}
      footer={
        <div>
          <Button>Close</Button>
        </div>
      }
    >
      <div className="h-full flex flex-col">
        <Tabs
          defaultActiveKey="2"
          tabBarGutter={24}
          tabBarStyle={{ borderBottom: '2px solid #e5e7eb' }}
          className="flex-1"
          items={[
            {
              label: 'Forecast Plots',
              key: '1',
              children: (
                <>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="overflow-x-auto pt-6 pb-6 h-[80vh] relative">
                      <ForecastPlots
                        description={''}
                        timeSeries={data?.time_series}
                        rwis={data?.rwis}
                      />
                    </div>
                  )}
                </>
              ),
            },
            {
              label: 'Forecast Table',
              key: '2',
              children: (
                <div className="h-full flex items-center justify-center max-h-[80vh]">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="overflow-x-auto pt-6 pb-6 h-[80vh] relative">
                      <ForecastTable timeSeries={data?.time_series} />
                    </div>
                  )}
                </div>
              ),
            },
            {
              label: 'Daily Summary',
              key: '3',
              children: (
                <>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="overflow-x-auto pt-6 pb-6 h-[80vh] relative">
                      <DailySummary data={data?.daily_summary_container} />
                    </div>
                  )}
                </>
              ),
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default SiteTable;
