import { Modal } from 'antd';

import { IVehicle } from '../../hooks/apiPikAlert/useGetVehicles';

const keyMappings: { [key: string]: string } = {
  auto_brake: 'Automatic Brake',
  elevation: 'Elevation',
  heading_deg: 'Heading (Degrees)',
  humidity: 'Humidity',
  id: 'Vehicle ID',
  lat: 'Latitude',
  lon: 'Longitude',
  obs_time: 'Observation Time',
  pressure: 'Pressure',
  road_temp_f: 'Road Temperature (°F)',
  speed_mph: 'Speed (MPH)',
  spreader: 'Spreader Status',
  temp_f: 'Temperature (°F)',
  traction_control: 'Traction Control',
  wiper_status: 'Wiper Status',
};

const formatKey = (key: string) => {
  return keyMappings[key] || key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

const formatValue = (value: string) => {
  return value.includes(String('-999')) ? 'Unknown' : value;
};

const VehicleRow = ({ name, value }: { name: string; value: string }) => {
  return (
    <div className="flex justify-between">
      <strong>{formatKey(name)}:</strong>
      <span>{formatValue(value)}</span>
    </div>
  );
};

export const vehicleModal = (e: IVehicle) => {
  const modal = Modal.confirm({
    title: 'Vehicle Information',
    content: (
      <div>
        {Object.keys(e).map((key) => (
          <VehicleRow key={key} name={key} value={e[key as keyof IVehicle]} />
        ))}
      </div>
    ),
    okText: 'Confirm',
    cancelButtonProps: { style: { display: 'none' } },
    onOk() {
      modal.destroy();
    },
    okButtonProps: {
      style: {
        backgroundColor: '#1890ff',
        color: '#fff',
        borderRadius: '5px',
        border: 'none',
      },
    },
  });
};
