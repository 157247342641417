import { Card, Col, Divider, Row, Typography } from 'antd';
import React from 'react';

import { decimalToHex } from '../util';
import { eventColors } from '../variables/nwsAlerts';

const { Title, Paragraph, Text } = Typography;

interface Geometry {
  disposed: boolean;
  extent_: number[];
  layout: string;
  flatCoordinates: number[];
}

interface Geocode {
  SAME: string[];
  UGC: string[];
}

interface Parameters {
  AWIPSidentifier: string[];
  WMOidentifier: string[];
  NWSheadline: string[];
  BLOCKCHANNEL: string[];
}

export interface CountyJsonData {
  geometry: Geometry;
  id: string;
  type: string;
  name: string;
  effectiveDate: string;
  expirationDate: string;
  state: string;
  forecastOffice: string;
  gridIdentifier: string;
  awipsLocationIdentifier: string;
  cwa: string[];
  forecastOffices: string[];
  timeZone: string[];
  observationStations: string[];
  radarStation: string | null;
  areaDesc: string;
  geocode: Geocode;
  affectedZones: string[];
  sent: string;
  effective: string;
  onset: string;
  expires: string;
  status: string;
  messageType: string;
  category: string;
  severity: string;
  certainty: string;
  urgency: string;
  event: string;
  sender: string;
  senderName: string;
  headline: string;
  description: string;
  instruction: string | null;
  response: string;
  parameters: Parameters;
  featType: string;
  region: string;
}
export function isCountyJsonData(obj: any): obj is CountyJsonData {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj.id === 'string' &&
    typeof obj.type === 'string' &&
    typeof obj.name === 'string' &&
    typeof obj.effectiveDate === 'string' &&
    typeof obj.expirationDate === 'string' &&
    typeof obj.state === 'string' &&
    typeof obj.forecastOffice === 'string' &&
    typeof obj.gridIdentifier === 'string' &&
    typeof obj.awipsLocationIdentifier === 'string' &&
    Array.isArray(obj.cwa) &&
    obj.cwa.every((item: any) => typeof item === 'string') &&
    Array.isArray(obj.forecastOffices) &&
    obj.forecastOffices.every((item: any) => typeof item === 'string') &&
    Array.isArray(obj.timeZone) &&
    obj.timeZone.every((item: any) => typeof item === 'string') &&
    Array.isArray(obj.observationStations) &&
    obj.observationStations.every((item: any) => typeof item === 'string') &&
    (typeof obj.radarStation === 'string' || obj.radarStation === null) &&
    typeof obj.areaDesc === 'string' &&
    typeof obj.geocode === 'object' &&
    Array.isArray(obj.geocode.SAME) &&
    obj.geocode.SAME.every((code: any) => typeof code === 'string') &&
    Array.isArray(obj.geocode.UGC) &&
    obj.geocode.UGC.every((code: any) => typeof code === 'string') &&
    Array.isArray(obj.affectedZones) &&
    obj.affectedZones.every((zone: any) => typeof zone === 'string') &&
    typeof obj.sent === 'string' &&
    typeof obj.effective === 'string' &&
    typeof obj.onset === 'string' &&
    typeof obj.expires === 'string' &&
    typeof obj.status === 'string' &&
    typeof obj.messageType === 'string' &&
    typeof obj.category === 'string' &&
    typeof obj.severity === 'string' &&
    typeof obj.certainty === 'string' &&
    typeof obj.urgency === 'string' &&
    typeof obj.event === 'string' &&
    typeof obj.sender === 'string' &&
    typeof obj.senderName === 'string' &&
    typeof obj.headline === 'string' &&
    typeof obj.description === 'string' &&
    (typeof obj.instruction === 'string' || obj.instruction === null) &&
    typeof obj.response === 'string' &&
    typeof obj.parameters === 'object' &&
    Object.keys(obj.parameters).every(
      (key) =>
        Array.isArray(obj.parameters[key]) &&
        obj.parameters[key].every((param: any) => typeof param === 'string'),
    ) &&
    typeof obj.featType === 'string' &&
    typeof obj.region === 'string'
  );
}
function parseAlertText(inputText?: string) {
  // Regular expression to match capital letters ending with three dots
  const regex = /([A-Z\s]+)\.\.\./g;

  // Split the text into parts based on the regex
  const parts = inputText?.split(regex);

  // Initialize an array to hold the result
  const result = [];

  if (parts)
    for (let i = 0; i < parts.length; i++) {
      if (i % 2 === 1) {
        // This is a matched group (capital letters with three dots)
        result.push(
          <Divider key={i} orientation={'left'}>
            <Text italic>{parts[i]}</Text>
          </Divider>,
        );
      } else {
        // This is the normal text
        result.push(parts[i]);
      }
    }

  return result;
}
export const CountyData: React.FC<{ jsonData: CountyJsonData }> = ({ jsonData }) => {
  return (
    <Card style={{ margin: '20px', padding: '20px' }}>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={24} style={{ display: 'flex' }}>
          <Card
            bordered={false}
            style={{
              flex: 1,
              backgroundColor: eventColors[String(jsonData.event)] + decimalToHex(0.2),
            }}
          >
            <Paragraph>
              <Title level={4}>{jsonData.headline}</Title>
            </Paragraph>
            <Paragraph>
              <Text strong>Description:</Text> <div>{parseAlertText(jsonData.description)}</div>
            </Paragraph>
            <Paragraph>
              <Text strong>Instruction:</Text> {jsonData.instruction ? jsonData.instruction : 'N/A'}
            </Paragraph>
            <Paragraph>
              <Text strong>Response:</Text> {jsonData.response}
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex' }}>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Alert Details</Title>
            <Paragraph>
              <Text strong>Status:</Text> {jsonData.status}
            </Paragraph>
            <Paragraph>
              <Text strong>Message Type:</Text> {jsonData.messageType}
            </Paragraph>
            <Paragraph>
              <Text strong>Category:</Text> {jsonData.category}
            </Paragraph>
            <Paragraph>
              <Text strong>Severity:</Text> {jsonData.severity}
            </Paragraph>
            <Paragraph>
              <Text strong>Certainty:</Text> {jsonData.certainty}
            </Paragraph>
            <Paragraph>
              <Text strong>Urgency:</Text> {jsonData.urgency}
            </Paragraph>
            <Paragraph>
              <Text strong>Event:</Text> {jsonData.event}
            </Paragraph>
          </Card>
        </Col>
        <Col span={12} style={{ display: 'flex' }}>
          <Card style={{ flex: 1 }} bordered={false}>
            <Title level={4}>Alert Description</Title>
            <Paragraph>
              <Text strong>Sender:</Text> {jsonData.sender}
            </Paragraph>
            <Paragraph>
              <Text strong>Sender Name:</Text> {jsonData.senderName}
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex' }}>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Basic Information</Title>
            <Paragraph>
              <Text strong>Name:</Text> {jsonData.name}
            </Paragraph>
            <Paragraph>
              <Text strong>State:</Text> {jsonData.state}
            </Paragraph>
          </Card>
        </Col>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Time Information</Title>
            <Paragraph>
              <Text strong>Effective Date:</Text>{' '}
              {new Date(jsonData.effectiveDate).toLocaleString()}
            </Paragraph>
            <Paragraph>
              <Text strong>Expiration Date:</Text>{' '}
              {new Date(jsonData.expirationDate).toLocaleString()}
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Divider />

      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex' }}>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Forecast Details</Title>
            <Paragraph>
              <Text strong>Forecast Office:</Text>{' '}
              <a href={jsonData.forecastOffice}>{jsonData.forecastOffice}</a>
            </Paragraph>
            <Paragraph>
              <Text strong>Grid Identifier:</Text> {jsonData.gridIdentifier}
            </Paragraph>
            <Paragraph>
              <Text strong>AWIPS Location Identifier:</Text> {jsonData.awipsLocationIdentifier}
            </Paragraph>
            <Paragraph>
              <Text strong>CWA:</Text> {jsonData.cwa.join(', ')}
            </Paragraph>
            <Paragraph>
              <Text strong>Forecast Offices:</Text> {jsonData.forecastOffices.join(', ')}
            </Paragraph>
          </Card>
        </Col>
        <Col span={12} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Observations</Title>
            <Paragraph>
              <Text strong>Observation Stations:</Text>{' '}
              {jsonData.observationStations.length > 0
                ? jsonData.observationStations.join(', ')
                : 'None'}
            </Paragraph>
            <Paragraph>
              <Text strong>Radar Station:</Text>{' '}
              {jsonData.radarStation ? jsonData.radarStation : 'N/A'}
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Divider />

      <Row gutter={16} style={{ display: 'flex' }}>
        <Col span={24} style={{ display: 'flex' }}>
          <Card bordered={false} style={{ flex: 1 }}>
            <Title level={4}>Additional Parameters</Title>
            <Paragraph>
              <Text strong>AWIPS Identifier:</Text> {jsonData.parameters.AWIPSidentifier.join(', ')}
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
