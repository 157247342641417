import './App.css';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import { routes } from './core/Routes';
import useWindowDimensions from './hooks/useWindowDimensions';
import { setViewportSize } from './store/slices/active-slice';

const App = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    dispatch(setViewportSize({ width, height }));
  }, [width, height]);

  return useRoutes(routes);
};

export default App;
