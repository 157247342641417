import { Feature, Map as OLMap, Overlay } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { useEffect, useRef } from 'react';

import { Camera } from '../../../../assets/mapIcons';
import { queryClient } from '../../../../index';
import { LayerEnum } from '../../variables/layerEnum';
import { key } from '../apiPikAlert/useGetDistrictSummary';
import { ILatestTime } from '../apiPikAlert/useGetLatestDate';
import { useGetThumbnails } from '../apiPikAlert/useGetThumbnails';
import { Site } from './useSitesLayer';

export const useCameraLayer = (
  map: OLMap | null,
  time: string,
  overlay: Overlay,
  latest: Array<ILatestTime>,
  display: boolean,
) => {
  const prop = 'images_thumbnail';
  const timestamp = (latest ?? [])?.find((item) => item.dir === prop)?.latest_time;
  const { data } = useGetThumbnails(timestamp);
  const summary = queryClient.getQueriesData([...key]).map(([, data]) => data);
  // @ts-ignore
  const siteArrays = summary[1]?.district_array ?? [];
  const siteData = siteArrays[0]?.site_array;
  const cameraData = data?.sites;

  const clusterLayerRef = useRef<VectorLayer<Cluster> | null>(null); // Ref for cluster layer

  useEffect(() => {
    if (!summary || !siteData || !map || !cameraData || !timestamp || !display) return;

    // Create point features for cameras
    const pointFeatures = cameraData
      .map((site) => {
        const loc = siteData.find((loc: Site) => loc.site_num === site.site_num);
        if (!loc) return null;

        return new Feature({
          geometry: new Point(fromLonLat([loc.lon, loc.lat])),
          imageUrl: `data:image/png;base64, ${site.large_image}`,
          width: site.large_width,
          height: site.large_height,
          ...site,
          desc: loc.desc,
          type: LayerEnum.CAMERA_LAYER,
        });
      })
      .filter((feature): feature is Feature<Point> => feature !== null);

    // Check if the cluster layer already exists
    if (!clusterLayerRef.current) {
      const clusterSource = new Cluster({
        distance: 20, // Cluster distance in pixels
        source: new VectorSource({
          features: pointFeatures,
        }),
      });

      const clusterLayer = new VectorLayer({
        source: clusterSource,
        properties: {
          type: 'camera',
        },
        zIndex: 9,
        style: (feature) => {
          const size = feature.get('features').length;
          if (size > 1) {
            return new Style({
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({
                  color: '#673ab7',
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: 2,
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: '#fff',
                }),
                // stroke: new Stroke({
                //   color: '#000',
                //   width: 3,
                // }),
              }),
            });
          } else {
            return new Style({
              image: new Icon({
                src: Camera,
                anchor: [1, 0.9],
                scale: 1,
              }),
            });
          }
        },
      });

      // Add the layer to the map and store the reference
      map.addLayer(clusterLayer);
      clusterLayerRef.current = clusterLayer;
    } else {
      // If the layer exists, update the source with the new features
      const clusterSource = clusterLayerRef.current.getSource() as Cluster;
      const vectorSource = clusterSource.getSource() as VectorSource;
      vectorSource.clear(); // Clear the existing features
      vectorSource.addFeatures(pointFeatures); // Add the new features
    }

    return () => {
      if (map && clusterLayerRef.current) {
        map.removeLayer(clusterLayerRef.current);
        clusterLayerRef.current = null;
      }
    };
  }, [cameraData, data, display, map, overlay, siteData, summary, time, timestamp]);
};
