import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const getRelativeDate = (
  date: string,
  tz: string,
  format?: string,
  trans?: Record<string, any>,
) => {
  const now = dayjs().tz(tz);
  const targetDate = dayjs(date).tz(tz);
  if (now.isSame(targetDate, 'day')) {
    return trans?.calendar.sameDay ?? 'today';
  } else if (now.add(1, 'day').isSame(targetDate, 'day')) {
    return trans?.calendar.nextDay ?? 'tomorrow';
  } else if (now.subtract(1, 'day').isSame(targetDate, 'day')) {
    return trans?.calendar.lastDay ?? 'yesterday';
  } else {
    return dayjs(date).format(format);
  }
};

export { getRelativeDate };
