export const placeholder = {
  id: '1d02a21e-5de5-41a3-ae21-22324f982810',
  name: 'My project ce67ab84',
  description: null,
  currentVersion: true,
  published: false,
  createdBy: 'milic.scekic@clicker.team',
  updatedBy: 'milic.scekic@clicker.team',
  creationTime: 1724783180065,
  updateTime: 1725017147502,
  lastActivityDateTime: 1725017147502,
  lastActivityBy: 'milic.scekic@clicker.team',
  createdDomainModelVersion: 1,
  templateId: null,
  templateVersionId: null,
  properties: {
    baseAppUrl: 'https://api-test.c9weather.com',
    timezone: 'Europe/Budapest',
    imageType: 'JPG',
    renderCompression: '10Mbps',
  },
  externalId: null,
  enterpriseAccountId: '12ad811c-ea1e-45e8-b257-e4e790493d3c',
  isSharedEntity: false,
  sharedDate: null,
  sharedBy: null,
  inEditMode: null,
  isEditVersion: null,
  editor: null,
  editingStartDate: null,
  editEntityId: null,
  versionId: 'b8100d5f-11eb-47c5-8c22-deb7aba6b4a4',
  thumbnailUrls: [
    'https://media.istockphoto.com/vectors/no-thumbnail-image-vector-graphic-vector-id1147544806?k=20&m=1147544806&s=170667a&w=0&h=5rN3TBN7bwbhW_0WyTZ1wU_oW5Xhan2CNd-jlVVnwD0=',
  ],
  projectFormat: 'BROADCAST_25_50',
  projectExportFormat: 'HD_1920x1080_50i',
  sceneDefaultDuration: 10000,
  sceneDefs: [
    {
      id: '2ae6f84a-8c48-4010-99b3-9298a53c8def',
      name: 'Scene 1',
      description: null,
      currentVersion: false,
      published: false,
      createdBy: 'milic.scekic@clicker.team',
      updatedBy: null,
      creationTime: 1724783180082,
      updateTime: null,
      lastActivityDateTime: 1724783180082,
      lastActivityBy: 'milic.scekic@clicker.team',
      createdDomainModelVersion: 1,
      templateId: null,
      templateVersionId: null,
      properties: {},
      externalId: null,
      enterpriseAccountId: '12ad811c-ea1e-45e8-b257-e4e790493d3c',
      isSharedEntity: false,
      sharedDate: null,
      sharedBy: null,
      inEditMode: null,
      isEditVersion: null,
      editor: null,
      editingStartDate: null,
      editEntityId: null,
      videoPanels: [],
      imagePanels: [],
      animationPanels: [],
      mapPanels: [
        {
          id: '39c632f7-289d-4327-971d-551445ad1550',
          name: 'Map 1ce4_EG0aa',
          description: '',
          currentVersion: false,
          published: false,
          createdBy: 'milic.scekic@clicker.team',
          updatedBy: null,
          creationTime: 1724918326107,
          updateTime: null,
          lastActivityDateTime: 1724918326106,
          lastActivityBy: 'milic.scekic@clicker.team',
          createdDomainModelVersion: 1,
          templateId: null,
          templateVersionId: null,
          properties: {
            projectionCenterLat: '38.999969',
            projectionCenterLon: '-105.549469',
            boundingBox:
              '{"upperLeft":{"longitude":-13415446.602686873,"latitude":5658976.956915818},"bottomLeft":{"longitude":-13415446.602686873,"latitude":3784357.307278326},"bottomRight":{"latitude":3784357.307278326,"longitude":-10083979.682473956},"upperRight":{"latitude":5658976.956915818,"longitude":-10083979.682473956}}',
            boundingBoxMerc:
              '[-120.51300726482926,32.160196362619345,-90.58593073517073,45.23742498603846]',
            north: 'true',
            resolution: '5356.056141821408',
            mapResolution: '2601.5625003621385',
            defaultCityStyle:
              '{"fontSize":3.5,"fontFamily":"Arial","fontType":"Regular","fontVariantId":"652df005-06c6-4149-88d8-d0ce17e1b59e","fontColor":"rgba(255, 255, 255, 255)","fontAlignment":"left","type":"shape","imageURL":null,"shape":"circle","size":1.2,"width":20,"height":20,"fillColor":"rgba(80, 10, 10, 255)","borderColor":"rgba(255, 255, 255, 255)","borderWidth":3,"zindex":20,"strokeWidth":0,"strokeColor":"rgba(50, 10, 10, 255)","textTransform":"none"}',
          },
          externalId: null,
          enterpriseAccountId: '12ad811c-ea1e-45e8-b257-e4e790493d3c',
          isSharedEntity: false,
          sharedDate: null,
          sharedBy: null,
          inEditMode: null,
          isEditVersion: null,
          editor: null,
          editingStartDate: null,
          editEntityId: null,
          timeControls: [
            {
              startMS: 0,
              endMS: 10000,
              seekToTimeMS: 0,
              inAnimationDef: 'CUT',
              outAnimationDef: 'CUT',
              inAnimationDuration: 0,
              outAnimationDuration: 0,
            },
          ],
          positionControl: {
            x: 0.0,
            y: 0.0,
            w: 100.0,
            h: 100.0,
            rotation: 0.0,
            zindex: 1,
          },
          mapType: 'BASIC',
          flyOver: {
            keyFrames: [],
          },
          flyOverEnabled: false,
          mapPositionControl: {
            longitude: -105.549469,
            latitude: 38.999969,
            zoom: 5.9114704,
            bearing: null,
            pitch: null,
            defaultZoom: 4.8697596,
          },
          baseMapSetup: {
            id: '116117f3-bfe2-41ef-ae49-a0d2ebf41f64',
            name: null,
            description: null,
            currentVersion: false,
            published: false,
            createdBy: 'milic.scekic@clicker.team',
            updatedBy: null,
            creationTime: 1724918326045,
            updateTime: null,
            lastActivityDateTime: 1724918326106,
            lastActivityBy: 'milic.scekic@clicker.team',
            createdDomainModelVersion: 1,
            templateId: null,
            templateVersionId: null,
            properties: {},
            externalId: null,
            enterpriseAccountId: null,
            isSharedEntity: false,
            sharedDate: null,
            sharedBy: null,
            inEditMode: null,
            isEditVersion: null,
            editor: null,
            editingStartDate: null,
            editEntityId: null,
            mapStyleId: 'cd129f0f-5572-44eb-97c1-d5ea14249cd8',
            baseMapUrl:
              'https://tiles-test.server.clicker.team/tiles/v2/milic.scekic@clicker.team/116117f3-bfe2-41ef-ae49-a0d2ebf41f64/{z}/{x}/{y}.png',
            minZoom: 3.0,
            maxZoom: 8.0,
            baseMapConfigurationBounds: [
              -121.51300726482926, 48.444201656024745, -89.58593073517073, 31.160196362619345,
            ],
            baseMapConfigurationProj4:
              '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs',
            mapStyle: {
              id: 'cd129f0f-5572-44eb-97c1-d5ea14249cd8',
              name: 'SATELLITE',
              description: null,
              currentVersion: false,
              published: false,
              createdBy: null,
              updatedBy: null,
              creationTime: 1724918326045,
              updateTime: null,
              lastActivityDateTime: 1724918326106,
              lastActivityBy: null,
              createdDomainModelVersion: 1,
              templateId: null,
              templateVersionId: null,
              properties: {},
              externalId: '12d4ccf1-6de6-442c-9ce4-52bd0f7ae67c',
              enterpriseAccountId: null,
              isSharedEntity: false,
              sharedDate: null,
              sharedBy: null,
              inEditMode: null,
              isEditVersion: null,
              editor: null,
              editingStartDate: null,
              editEntityId: null,
              thumbnailUrl: null,
              basicPreviewUrl: null,
              styleDefinition: null,
              updatedDomainModelVersion: null,
              compatibleWithCurrentRequester: null,
            },
            projection: {
              id: 'e76d0601-87c1-4557-9740-5d82584905ab',
              name: 'EPSG:3857',
              description: null,
              currentVersion: false,
              published: false,
              createdBy: null,
              updatedBy: null,
              creationTime: 1724918326045,
              updateTime: null,
              lastActivityDateTime: 1724918326106,
              lastActivityBy: null,
              createdDomainModelVersion: 1,
              templateId: null,
              templateVersionId: null,
              properties: {},
              externalId: null,
              enterpriseAccountId: null,
              isSharedEntity: false,
              sharedDate: null,
              sharedBy: null,
              inEditMode: null,
              isEditVersion: null,
              editor: null,
              editingStartDate: null,
              editEntityId: null,
              updatedDomainModelVersion: null,
              compatibleWithCurrentRequester: null,
            },
            projectionParams:
              '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs',
            boundingBox: {
              upperLeft: {
                latitude: 48.444201656024745,
                longitude: -121.51300726482926,
              },
              upperRight: {
                latitude: 48.444201656024745,
                longitude: -89.58593073517073,
              },
              bottomRight: {
                latitude: 31.160196362619345,
                longitude: -89.58593073517073,
              },
              bottomLeft: {
                latitude: 31.160196362619345,
                longitude: -121.51300726482926,
              },
            },
            osmId: '161961',
            hidden: false,
            inverted: false,
            oceanLayerColor: null,
            zindex: 1,
            updatedDomainModelVersion: null,
            compatibleWithCurrentRequester: null,
          },
          wdSpace: [
            {
              id: 'ed3d8588-f8f7-4b5d-81df-3c4a58663150',
              name: null,
              timeControls: [
                {
                  startMS: 0,
                  endMS: 10000,
                  seekToTimeMS: 0,
                  inAnimationDef: 'CUT',
                  outAnimationDef: 'CUT',
                  inAnimationDuration: 0,
                  outAnimationDuration: 0,
                },
              ],
              gribMapLayers: [],
              radarMapLayers: [
                {
                  id: 'f5dc027c-42d7-40ff-8849-6d5575150ce7',
                  createdBy: 'milic.scekic@clicker.team',
                  creationTime: 1725017122764,
                  layerSetup: {
                    id: 'f8fd26b1-e797-4640-925c-7d8f08e7ffd6',
                    baseAppUrl: 'https://api-test.c9weather.com',
                    mapLayerUrl: null,
                    initiateFramesBaseUrl: null,
                    interpolation: 'nearest',
                    preprocessing: 'low-bandpass',
                    numberOfIterations: 4,
                    postprocessing: null,
                    embossEffect: 7,
                    unitOfMeasurement: null,
                    visualisationType: null,
                    colorPaletteDef: {
                      id: '41db7187-85c9-4b2d-aadd-9ba7638ff381',
                      name: 'Default METno',
                      description: null,
                      currentVersion: false,
                      published: false,
                      createdBy: null,
                      updatedBy: null,
                      creationTime: 1725017147501,
                      updateTime: null,
                      lastActivityDateTime: 1725017147501,
                      lastActivityBy: null,
                      createdDomainModelVersion: 1,
                      templateId: null,
                      templateVersionId: null,
                      properties: {},
                      externalId: null,
                      enterpriseAccountId: null,
                      isSharedEntity: false,
                      sharedDate: null,
                      sharedBy: null,
                      inEditMode: null,
                      isEditVersion: null,
                      editor: null,
                      editingStartDate: null,
                      editEntityId: null,
                      versionId: '41db7187-85c9-4b2d-aadd-9ba7638ff381',
                      visualisationType: 'heatmap',
                      type: 'SYSTEM',
                      source: null,
                      paletteParamType: 'RADAR',
                      colorStops: {
                        unit: 'dBZ',
                        interval: 5.0,
                        pallet: {
                          32: '0,0,0,0',
                          33: '255,255,255,0',
                          35: '113,168,250,250',
                          39: '72,161,250,250',
                          43: '21,135,250,250',
                          45: '135,240,5,250',
                          65: '250,235,20,250',
                          68: '245,140,20,250',
                          73: '255,85,20,191',
                          75: '255,20,120,191',
                          85: '255,40,220,191',
                          110: '0,0,0,0',
                        },
                      },
                      setup: {
                        defaultStep: 0,
                        scale: [
                          {
                            degree: 32.0,
                            color: '0,0,0,0',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 33.0,
                            color: '255,255,255,0',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 35.0,
                            color: '113,168,250,250',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 39.0,
                            color: '72,161,250,250',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 43.0,
                            color: '21,135,250,250',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 45.0,
                            color: '135,240,5,250',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 65.0,
                            color: '250,235,20,250',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 68.0,
                            color: '245,140,20,250',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 73.0,
                            color: '255,85,20,191',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 75.0,
                            color: '255,20,120,191',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 85.0,
                            color: '255,40,220,191',
                            interpolationSteps: 0,
                            active: true,
                          },
                          {
                            degree: 110.0,
                            color: '0,0,0,0',
                            interpolationSteps: 0,
                            active: true,
                          },
                        ],
                      },
                      dataProductId: null,
                      paletteLegendScaling: null,
                      updatedDomainModelVersion: null,
                      compatibleWithCurrentRequester: null,
                    },
                    jsonColorPallet: null,
                    isolineGeoJson: null,
                    isolineWidth: null,
                    displayPaletteLegend: false,
                    displayLegendValues: true,
                    paletteLegendPositionControl: {
                      x: 2.344,
                      y: 88.889,
                      w: 39.063,
                      h: 8.333,
                      rotation: 0.0,
                      zindex: 1,
                    },
                    paletteLegendOrientation: 'HORIZONTAL',
                    windStreamlines: null,
                    windParticles: {
                      fadeOpacity: 0.9,
                      speedFactor: 0.25,
                      dropRate: 0.2,
                      dropRateBump: 0.3,
                      particleNumber: 50000,
                      particleSize: 2.0,
                      particleDensity: null,
                    },
                    paletteLegendScaling: null,
                    legendFontSize: 3.0,
                    legendFontScale: 4,
                    fontFamily: 'Arial',
                    fontType: 'Regular',
                    fontVariantId: null,
                  },
                  timeControls: [
                    {
                      startMS: 0,
                      endMS: 10000,
                      seekToTimeMS: 0,
                      inAnimationDef: 'CUT',
                      outAnimationDef: 'CUT',
                      inAnimationDuration: 0,
                      outAnimationDuration: 0,
                    },
                  ],
                  dataFrames: [],
                  unLoadedDataFrames: [],
                  minZoom: null,
                  maxZoom: null,
                  baseMapUrl: null,
                  layerType: 'RADAR',
                  enabled: true,
                  realtime: false,
                  indicatorPriority: 1,
                  name: 'Radar US National Weather Service - NCEP',
                  dataFrameDensity: null,
                  dataFramesDensity: 1,
                  enableInterpolation: false,
                  isContouring: true,
                  brightness: null,
                  opacity: 1.0,
                  radarSource: {
                    id: '9fc79e51-f138-4baa-a085-b2f184a355df',
                    name: null,
                    description: null,
                    currentVersion: false,
                    published: false,
                    createdBy: null,
                    updatedBy: null,
                    creationTime: 1724918336720,
                    updateTime: null,
                    lastActivityDateTime: 1724918336915,
                    lastActivityBy: null,
                    createdDomainModelVersion: 1,
                    templateId: null,
                    templateVersionId: null,
                    properties: {},
                    externalId: null,
                    enterpriseAccountId: null,
                    isSharedEntity: false,
                    sharedDate: null,
                    sharedBy: null,
                    inEditMode: null,
                    isEditVersion: null,
                    editor: null,
                    editingStartDate: null,
                    editEntityId: null,
                    radarSource: {
                      id: 32,
                      name: 'US National Weather Service - NCEP',
                    },
                    location: {
                      leftLongitude: -121.51300726482926,
                      rightLongitude: -89.58593073517073,
                      upperLatitude: 48.444201656024745,
                      lowerLatitude: 31.160196362619345,
                    },
                    datetime: null,
                    timeFrameFrom: null,
                    timeframeTo: null,
                    dataProductId: 'df39baea-672a-4c72-8337-88ff45ba9285',
                    updatedDomainModelVersion: null,
                    compatibleWithCurrentRequester: null,
                  },
                  zindex: 1,
                },
              ],
              satelliteMapLayers: [],
              vectorMapLayers: [],
              mapTimeframeTextIndicator: {
                id: '5c9cc70e-b7bf-46f2-839a-358cfce41f54',
                name: null,
                description: null,
                currentVersion: false,
                published: false,
                createdBy: null,
                updatedBy: null,
                creationTime: 1724918326044,
                updateTime: null,
                lastActivityDateTime: 1724918326107,
                lastActivityBy: null,
                createdDomainModelVersion: 1,
                templateId: null,
                templateVersionId: null,
                properties: {},
                externalId: null,
                enterpriseAccountId: null,
                isSharedEntity: false,
                sharedDate: null,
                sharedBy: null,
                inEditMode: null,
                isEditVersion: null,
                editor: null,
                editingStartDate: null,
                editEntityId: null,
                value: null,
                timeControls: [
                  {
                    startMS: 0,
                    endMS: 10000,
                    seekToTimeMS: 0,
                    inAnimationDef: 'CUT',
                    outAnimationDef: 'CUT',
                    inAnimationDuration: 0,
                    outAnimationDuration: 0,
                  },
                ],
                positionControl: {
                  x: 5.0,
                  y: 5.0,
                  w: 30.0,
                  h: 5.0,
                  rotation: 0.0,
                  zindex: 1000,
                },
                background: null,
                fontColor: 'rgba(255, 255, 255, 255)',
                fontSize: 4.0,
                fontFamily: 'Arial',
                fontAlignment: null,
                fontType: 'Regular',
                fontVariantId: '652df005-06c6-4149-88d8-d0ce17e1b59e',
                textAnimation: {
                  active: false,
                  speed: 8.0,
                },
                fontScale: null,
                boxDef: {
                  background: {
                    aspectFit: null,
                    color: 'rgba(255, 255, 255, 0)',
                    gradient: null,
                  },
                  paddingTop: 0.0,
                  paddingBottom: 0.0,
                  paddingLeft: 0.0,
                  paddingRight: 0.0,
                  borderTop: {
                    color: 'rgba(255, 255, 255, 0)',
                    width: 0.0,
                    style: 'none',
                    padding: 0.0,
                  },
                  borderBottom: {
                    color: 'rgba(255, 255, 255, 0)',
                    width: 0.0,
                    style: 'none',
                    padding: 0.0,
                  },
                  borderLeft: {
                    color: 'rgba(255, 255, 255, 0)',
                    width: 0.0,
                    style: 'none',
                    padding: 0.0,
                  },
                  borderRight: {
                    color: 'rgba(255, 255, 255, 0)',
                    width: 0.0,
                    style: 'none',
                    padding: 0.0,
                  },
                  marginTop: 0.0,
                  marginBottom: 0.0,
                  marginLeft: 0.0,
                  marginRight: 0.0,
                  opacity: 1.0,
                },
                displayText: true,
                enabled: true,
                richText: null,
                strokeColor: 'rgba(255, 255, 255, 255)',
                strokeWidth: 0.0,
                textTransform: null,
                parentGroups: [
                  {
                    groupId: '00000000-0000-0000-0000-000000000000',
                    orderNumInGroup: 0,
                  },
                ],
                updatedDomainModelVersion: null,
                compatibleWithCurrentRequester: null,
              },
              timeframeIndicatorStep: 'HOURLY',
              timeframeIndicatorFormat: 'DD-MM-yyyy HH:mm',
              enableTimeframeIndicator: true,
              roundTimeframeIndicator: false,
              relativeTime: false,
              enabled: true,
              layersSync: false,
              observedDataLayers: [],
              forecastDataLayers: [],
              enabledLayers: [],
              symbolLayers: [],
              eventLayers: [],
              layerSync: false,
            },
          ],
          geoPosters: [],
          cityPosters: [
            {
              id: '469892ee-2cba-451d-afa8-8b0a5bea545c',
              name: 'Colorado-United States of America',
              description: null,
              currentVersion: false,
              published: false,
              createdBy: 'milic.scekic@clicker.team',
              updatedBy: null,
              creationTime: 1725000099289,
              updateTime: null,
              lastActivityDateTime: 1725000099289,
              lastActivityBy: 'milic.scekic@clicker.team',
              createdDomainModelVersion: 1,
              templateId: null,
              templateVersionId: null,
              properties: {
                defaultStyle:
                  '{"fontSize":3.5,"fontFamily":"Arial","fontType":"Regular","fontVariantId":"652df005-06c6-4149-88d8-d0ce17e1b59e","fontColor":"rgba(255, 255, 255, 255)","fontAlignment":"left","type":"shape","imageURL":null,"shape":"circle","size":1.2,"width":20,"height":20,"fillColor":"rgba(80, 10, 10, 255)","borderColor":"rgba(255, 255, 255, 255)","borderWidth":3,"zindex":20,"strokeWidth":0,"strokeColor":"rgba(50, 10, 10, 255)","textTransform":"none"}',
                defaultLon: '-105.549469',
                defaultLat: '38.999969',
                originalName: 'Colorado',
              },
              externalId: null,
              enterpriseAccountId: null,
              isSharedEntity: false,
              sharedDate: null,
              sharedBy: null,
              inEditMode: null,
              isEditVersion: null,
              editor: null,
              editingStartDate: null,
              editEntityId: null,
              posterType: null,
              timeControls: [],
              positionControl: {
                x: 0.0,
                y: 0.0,
                w: 0.0,
                h: 0.0,
                rotation: 0.0,
                zindex: 20,
              },
              background: null,
              videoPanels: [],
              imagePanels: [],
              animationPanels: [],
              textPanels: [
                {
                  id: '657e3108-ce31-47dc-9edf-5dff9cbf1b03',
                  name: null,
                  description: null,
                  currentVersion: false,
                  published: false,
                  createdBy: null,
                  updatedBy: null,
                  creationTime: 1725000099623,
                  updateTime: null,
                  lastActivityDateTime: 1725000099289,
                  lastActivityBy: null,
                  createdDomainModelVersion: 1,
                  templateId: null,
                  templateVersionId: null,
                  properties: {},
                  externalId: null,
                  enterpriseAccountId: null,
                  isSharedEntity: false,
                  sharedDate: null,
                  sharedBy: null,
                  inEditMode: null,
                  isEditVersion: null,
                  editor: null,
                  editingStartDate: null,
                  editEntityId: null,
                  value: 'Colorado',
                  timeControls: [
                    {
                      startMS: 0,
                      endMS: 10000,
                      seekToTimeMS: 0,
                      inAnimationDef: 'CUT',
                      outAnimationDef: 'CUT',
                      inAnimationDuration: 0,
                      outAnimationDuration: 0,
                    },
                  ],
                  positionControl: {
                    x: 5.0,
                    y: 5.0,
                    w: 30.0,
                    h: 5.0,
                    rotation: 0.0,
                    zindex: 1,
                  },
                  background: null,
                  fontColor: 'rgba(255, 255, 255, 255)',
                  fontSize: 3.5,
                  fontFamily: 'Arial',
                  fontAlignment: 'left',
                  fontType: 'Regular',
                  fontVariantId: '652df005-06c6-4149-88d8-d0ce17e1b59e',
                  textAnimation: {
                    active: false,
                    speed: 8.0,
                  },
                  fontScale: null,
                  boxDef: {
                    background: {
                      aspectFit: null,
                      color: 'rgba(255, 255, 255, 0)',
                      gradient: null,
                    },
                    paddingTop: 0.0,
                    paddingBottom: 0.0,
                    paddingLeft: 0.0,
                    paddingRight: 0.0,
                    borderTop: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.0,
                      style: 'none',
                      padding: 0.0,
                    },
                    borderBottom: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.0,
                      style: 'none',
                      padding: 0.0,
                    },
                    borderLeft: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.0,
                      style: 'none',
                      padding: 0.0,
                    },
                    borderRight: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.0,
                      style: 'none',
                      padding: 0.0,
                    },
                    marginTop: 0.0,
                    marginBottom: 0.0,
                    marginLeft: 0.0,
                    marginRight: 0.0,
                    opacity: 1.0,
                  },
                  displayText: true,
                  enabled: true,
                  richText: null,
                  strokeColor: 'rgba(50, 10, 10, 255)',
                  strokeWidth: 0.0,
                  textTransform: 'none',
                  parentGroups: [
                    {
                      groupId: '00000000-0000-0000-0000-000000000000',
                      orderNumInGroup: 0,
                    },
                  ],
                  updatedDomainModelVersion: null,
                  compatibleWithCurrentRequester: null,
                },
              ],
              audioElements: [],
              observedWDElements: [],
              forecastWDElements: [],
              useAsGeoPoster: true,
              enabled: true,
              pointDates: [],
              pointLocation: [],
              visualisation: null,
              weatherDataSourceMapping: null,
              parentGroups: [],
              anchorOffsetX: 0.0,
              anchorOffsetY: 0.0,
              longitude: -105.549469,
              latitude: 38.999969,
              countryName: 'United States of America',
              anchor: {
                enable: true,
                longitude: -105.549469,
                latitude: 38.999969,
                anchorOffsetX: -105.54947,
                anchorOffsetY: 38.99997,
                type: 'shape',
                shape: 'circle',
                size: 1.2,
                fillColor: 'rgba(80, 10, 10, 255)',
                borderColor: 'rgba(255, 255, 255, 255)',
                height: 20.0,
                width: 20.0,
                borderWidth: 3.0,
                imageURL: null,
                imageFile: null,
              },
              updatedDomainModelVersion: null,
              compatibleWithCurrentRequester: null,
            },
          ],
          drawingElements: [],
          oceanMask: null,
          customBackgroundImage: null,
          showCities: true,
          customMapLayer: [],
          parentGroups: [
            {
              groupId: '00000000-0000-0000-0000-000000000000',
              orderNumInGroup: 0,
            },
          ],
          enabled: true,
          graticule: {
            enabled: false,
            latitudeInterval: 5.0,
            longitudeInterval: 10.0,
            strokeWidth: 1,
            strokeColor: 'rgba(178, 190, 181, 255)',
            enableLabels: true,
            zindex: 10,
          },
          weatherDataSource: null,
          weatherDataVisibilityControl: null,
          updatedDomainModelVersion: null,
          compatibleWithCurrentRequester: null,
        },
      ],
      textPanels: [],
      weatherPosters: [],
      audioElements: [],
      observedWDElements: [],
      forecastWDElements: [],
      logicalGroups: [],
      durationInMS: 10000,
      timeControl: {
        startMS: 0,
        endMS: 10000,
        seekToTimeMS: 0,
        inAnimationDef: 'CUT',
        outAnimationDef: 'CUT',
        inAnimationDuration: 0,
        outAnimationDuration: 0,
      },
      c9SceneDefProperties: [],
      thumbnailUrls: [],
      enabled: true,
      pointDates: [],
      pointLocation: [],
      translationId: null,
      updatedDomainModelVersion: null,
      compatibleWithCurrentRequester: null,
    },
  ],
  skippedTime: [],
  voiceOver: null,
  videoOver: null,
  c9ProjectDefProperties: [],
  disabled: false,
  disableReason: null,
  exportDefs: [
    {
      name: '',
      type: 'SFTP',
      sftpUrl: '',
      folderPath: '',
      sftpUsername: '',
      sftpPassword: '',
    },
  ],
  automaticallyAddToRepoVAOutput: false,
  outputVideoCompression: '10Mbps',
  historicalType: 'RELATIVE',
  timeReference: 1725017147502,
  isSuccessfulTimeReference: true,
  translationId: null,
  sqsConfigs: [],
  renderQualityDef: {
    gammaCorrection: 0.8,
    saturation: 1.0,
  },
  updatedDomainModelVersion: null,
  compatibleWithCurrentRequester: null,
};
