import Highcharts from 'highcharts';
import HC_accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';

import { calculateTime, formatTime } from '../../utils/helper';

HC_accessibility(Highcharts);

const ProbabilityChart = ({ timeSeries, chartTitle, yAxisTitle }) => {
  const data = timeSeries?.site?.time_series_container || {};
  if (!data) return null;

  const rainProbData = data.series_prob_rain || [];
  const snowProbData = data.series_prob_snow || [];
  const iceProbData = data.series_prob_ice || [];

  const epochStart = parseInt(data.epoch_start_time, 10);
  const firstDelta = parseInt(data.first_time_delta_seconds, 10);
  const interval = parseInt(data.remaining_time_delta_seconds, 10);

  const seriesData = rainProbData.map((_, index) => ({
    x: calculateTime(index, epochStart, firstDelta, interval),
    rainProb: parseFloat((rainProbData[index] * 100).toFixed(2)),
    snowProb: parseFloat((snowProbData[index] * 100).toFixed(2)),
    iceProb: parseFloat((iceProbData[index] * 100).toFixed(2)),
  }));

  const options = {
    chart: {
      type: 'column',
      height: 350,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
    },
    title: {
      align: 'left',
      text: chartTitle,
    },
    subtitle: {
      align: 'left',
      text: 'Click chart and drag to zoom',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          return formatTime(this.value / 1000);
        },
      },
      tickInterval: 12 * 3600 * 1000,
    },
    yAxis: {
      visible: true,
      title: {
        text: yAxisTitle,
      },
      labels: {
        enabled: true,
        formatter: function () {
          return `${this.value} %`;
        },
      },
      gridLineWidth: 0,
      max: 100,
      tickAmount: 5,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        let tooltipHtml = `<div>Time: ${formatTime(this.x / 1000)}<br/><ul>`;

        this.points.forEach((point) => {
          tooltipHtml += `<li><span style="color:${point.series.color}">${point.series.name}: </span><strong>${point.y} %</strong></li>`;
        });

        tooltipHtml += '</ul></div>';
        return tooltipHtml;
      },
      positioner: function (labelWidth, labelHeight, point) {
        const chartWidth = this.chart.chartWidth;
        const chartHeight = this.chart.chartHeight;
        const tooltipX = point.plotX + this.chart.plotLeft - labelWidth / 2;
        const tooltipY = point.plotY + this.chart.plotTop - labelHeight - 10;

        return {
          x: Math.max(0, Math.min(tooltipX, chartWidth - labelWidth)),
          y: Math.max(0, Math.min(tooltipY, chartHeight - labelHeight)),
        };
      },
      zIndex: 1000,
    },
    series: [
      {
        name: 'Rain Probability',
        data: seriesData.map((point) => [point.x, point.rainProb]),
        color: '#1f77b4',
      },
      {
        name: 'Snow Probability',
        data: seriesData.map((point) => [point.x, point.snowProb]),
        color: '#ff7f0e',
      },
      {
        name: 'Ice Probability',
        data: seriesData.map((point) => [point.x, point.iceProb]),
        color: '#2ca02c',
      },
    ],
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
      backgroundColor: '#FFFFFF',
      itemStyle: {
        color: '#333333',
      },
      itemHoverStyle: {
        color: '#000000',
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div className="mb-10">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ProbabilityChart;
