import { Select } from 'antd';

import TimeIcon from '../../../assets/icons/Time';

const { Option } = Select;

const Time = ({ options, time, setTime }) => {
  const handleChange = (value) => {
    const selectedValue = value;
    const selectedOption = options.find((option) => option.value === selectedValue);
    if (selectedOption) {
      setTime(selectedOption);
    }
  };

  return (
    <>
      <label>
        <span className="pl-1">State summary</span>
        <Select defaultValue={options[0]} value={time} onChange={handleChange} className="w-full">
          {options.map((filter) => (
            <Option key={filter.value} value={filter.value}>
              <div className="flex items-center gap-2">
                <TimeIcon /> {filter.label}
              </div>
            </Option>
          ))}
        </Select>
      </label>
    </>
  );
};

export default Time;
