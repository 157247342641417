import { Feature, Map as OLMap } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';
import { useEffect } from 'react';

import { SitePin } from '../../../../assets/mapIcons';
import { LayerEnum } from '../../variables/layerEnum';
import { useGetDistrictSummary } from '../apiPikAlert/useGetDistrictSummary';
import { ILatestTime } from '../apiPikAlert/useGetLatestDate';
import { useGetThumbnails } from '../apiPikAlert/useGetThumbnails';

export interface Site {
  hr72_alert_code: string;
  hr24_alert_code: string;
  hr06_alert_code: string;
  site_num: number;
  lat: number;
  lon: number;
  desc: string;
  is_road_cond_site?: boolean;
}

interface DistrictArray {
  site_array: Site[];
}

interface DistrictSummaryResponse {
  district_array: DistrictArray[];
}

export const useSitesLayer = (
  map: OLMap | null,
  time: string,
  latest: Array<ILatestTime>,
  display: boolean,
) => {
  const prop = 'district_alerts_summary';
  const timestamp = (latest ?? [])?.find((item) => item.dir === prop)?.latest_time;
  const { data: cameras } = useGetThumbnails(timestamp);
  const { data } = useGetDistrictSummary(timestamp) as {
    data: DistrictSummaryResponse | undefined;
  };

  useEffect(() => {
    if (!data || !map || !timestamp || !display || !cameras) return;

    const cameraSiteNums = new Set(cameras?.sites.map((camera) => camera.site_num));

    const siteArray = data.district_array[0].site_array.filter(
      (site) => !site.is_road_cond_site && !cameraSiteNums.has(site.site_num),
    );

    const pointFeatures = siteArray.map((site) => {
      return new Feature({
        geometry: new Point(fromLonLat([site.lon, site.lat])),
        ...site,
        type: LayerEnum.SITE_LAYER,
      });
    });

    const pointVectorSource = new VectorSource({
      features: pointFeatures,
    });

    const pointVectorLayer = new VectorLayer({
      source: pointVectorSource,
      zIndex: 2,
      style: () =>
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: SitePin,
            scale: 1,
          }),
        }),
    });

    map.addLayer(pointVectorLayer);

    return () => {
      if (map) {
        map.removeLayer(pointVectorLayer);
      }
    };
  }, [cameras, data, map, time, timestamp, display]);
};
