import ReactSlider from 'react-slider';

function TimeSlider({ time, duration, isPlaying, setTime }) {
  const handleTrackClick = (e) => {
    if (isPlaying) return;
    const trackRect = e.target.getBoundingClientRect();
    const clickPosition = e.clientX - trackRect.left;
    const clickPositionPercentage = (clickPosition / trackRect.width) * 100;
    const newTime = (clickPositionPercentage / 100) * duration;
    setTime(Math.round(newTime));
  };

  return (
    <div className="flex items-center flex-col space-x-4 bg-white w-full">
      <div className="flex-1 w-full">
        <div className="flex justify-end text-gray-600 text-sm mb-1">
          <span>{duration / 1000} seconds</span>
        </div>
        <ReactSlider
          id="timestamp-slider"
          min={0}
          max={duration}
          value={[0, time]}
          renderTrack={(props, state) => {
            const progress = (time / duration) * 100;

            return (
              <div
                {...props}
                className="track"
                style={{
                  ...props.style,
                  background: `linear-gradient(to right, #007bff ${progress}%, #081B37 ${progress}%)`,
                  cursor: isPlaying ? 'not-allowed' : 'pointer',
                }}
                onClick={(e) => handleTrackClick(e)}
              />
            );
          }}
          renderThumb={() => null}
          disabled={isPlaying}
          className="slider flex-grow w-full"
        />
      </div>
    </div>
  );
}

export default TimeSlider;
