import { Map, Overlay } from 'ol';
import { MutableRefObject, useEffect, useRef } from 'react';

import { cameraTooltip, roadTooltip, siteTooltip } from '../tooltips';
import { LayerEnum } from '../variables/layerEnum';

const useTooltipOverlay = (mapInstanceRef: MutableRefObject<Map | null>, time: string) => {
  const tooltipOverlayRef = useRef<Overlay | null>(null); // Define type Overlay or null

  useEffect(() => {
    const tooltipElement = document.createElement('div');
    tooltipElement.setAttribute('id', 'tooltip');
    tooltipElement.setAttribute(
      'style',
      'display: block; background: white; padding: 10px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)',
    );

    // Initialize the Overlay
    tooltipOverlayRef.current = new Overlay({
      element: tooltipElement,
      positioning: 'bottom-center',
      stopEvent: false,
      offset: [0, -20],
    });

    const mapInstance = mapInstanceRef.current;
    if (mapInstance && tooltipOverlayRef.current) {
      // Add overlay to the map
      mapInstance.addOverlay(tooltipOverlayRef.current);

      mapInstance.on('pointermove', (event) => {
        if (event.dragging) return;

        const tooltipElement = tooltipOverlayRef.current?.getElement();
        if (!tooltipElement) return;

        const feature = mapInstance.forEachFeatureAtPixel(event.pixel, (feature) => feature);
        if (feature) {
          const properties = feature.getProperties();
          tooltipElement.style.display = 'block';

          const type = properties.type || properties.features?.[0]?.getProperties().type;
          switch (type) {
            case LayerEnum.SITE_LAYER:
              tooltipElement.innerHTML = siteTooltip(properties, time);
              break;
            case LayerEnum.ROAD_LAYER:
              tooltipElement.innerHTML = roadTooltip(properties, time);
              break;
            case LayerEnum.CAMERA_LAYER:
              tooltipElement.innerHTML = cameraTooltip(properties.features[0]?.getProperties());
              break;
            default:
              tooltipElement.innerHTML = '';
              tooltipElement.style.display = 'none';
          }

          tooltipOverlayRef.current?.setPosition(event.coordinate);
        } else {
          tooltipElement.style.display = 'none';
        }
      });

      mapInstance.getViewport().addEventListener('mouseout', () => {
        const tooltipElement = tooltipOverlayRef.current?.getElement();
        if (tooltipElement) {
          tooltipElement.style.display = 'none';
        }
      });
    }

    return () => {
      if (mapInstance && tooltipOverlayRef.current) {
        mapInstance.removeOverlay(tooltipOverlayRef.current);
      }
    };
  }, [mapInstanceRef, time]);

  return tooltipOverlayRef;
};

export default useTooltipOverlay;
