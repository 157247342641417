import Highcharts from 'highcharts';
import HC_accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { memo } from 'react';

import { alertColors, calculateTime, formatTime } from '../../utils/helper';

HC_accessibility(Highcharts);

const ColumnChart = ({ timeSeries, chartTitle, yAxisTitle, type }) => {
  const sData =
    type === 'alert'
      ? timeSeries?.site?.time_series_container?.series_alert_code
      : timeSeries?.site?.time_series_container?.series_treatment_alert_code || [];
  const data = timeSeries?.site?.time_series_container || {};
  if (!data) return null;

  const alertLegend = timeSeries.alert_legend || {};
  const pavementConditionLegend = timeSeries.pavement_condition_legend || {};
  const precipTypeLegend = timeSeries.precip_type_legend || {};
  const visibilityLegend = timeSeries.visibility_legend || {};
  const blowOverRiskLegend = timeSeries.blow_over_risk_legend || {};

  const epochStart = parseInt(data.epoch_start_time, 10);
  const firstDelta = parseInt(data.first_time_delta_seconds, 10);
  const interval = parseInt(data.remaining_time_delta_seconds, 10);

  const seriesData = sData.map((code, index) => ({
    x: calculateTime(index, epochStart, firstDelta, interval),
    y: 1,
    color: alertColors[alertLegend[code] || '-9999'],
    alertLevel: alertLegend[code],
    precip: precipTypeLegend[data.series_precip_type_code[index]],
    pavement: pavementConditionLegend[data.series_pavement_condition[index]],
    visibility: visibilityLegend[data.series_visibility_type_code[index]],
    blowover: blowOverRiskLegend[data.time_series_blow_over_code[index]],
    roadTemp: data.series_road_temperature[index],
  }));

  const uniqueAlertLevels = Array.from(new Set(sData.map((code) => alertLegend[code]))).filter(
    Boolean,
  );

  const series = uniqueAlertLevels.map((level) => ({
    name: level,
    data: seriesData.filter((point) => point.alertLevel === level),
    color: alertColors[level] || '#000000',
  }));

  const options = {
    chart: {
      type: 'column',
      height: 250,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
    },
    title: {
      align: 'left',
      text: chartTitle,
    },
    subtitle: {
      align: 'left',
      text: 'Click chart and drag to zoom',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          return formatTime(this.value / 1000);
        },
      },
      tickInterval: 12 * 3600 * 1000,
    },
    yAxis: {
      visible: true,
      title: {
        text: yAxisTitle,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        pointWidth: 10,
        pointPadding: 0.3,
        groupPadding: 0.3,
        borderWidth: 0,
        showInLegend: true,
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const { alertLevel, precip, pavement, visibility, blowover, roadTemp } = this.point;
        return `<div>Time: ${formatTime(this.x / 1000)}<br/>
       <ul>
        <li>Alert Level: ${alertLevel}</li>
        ${
          type === 'alert'
            ? `
              <li>Precip: <strong>${precip}</strong></li>
              <li>Pavement: <strong>${pavement}</strong></li>
              <li>Visibility: <strong>${visibility}</strong></li>
              <li>Blowover: <strong>${blowover}</strong></li>
              `
            : `<li>Road temp: <strong>${roadTemp}</strong></li>`
        }
      </ul>`;
      },
      positioner: function (labelWidth, labelHeight, point) {
        return {
          x: point.plotX + this.chart.plotLeft - labelWidth / 2,
          y: point.plotY + this.chart.plotTop - labelHeight - 10,
        };
      },
    },
    series: series,
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
      backgroundColor: '#FFFFFF',
      itemStyle: {
        color: '#333333',
      },
      itemHoverStyle: {
        color: '#000000',
      },
      labelFormatter: function () {
        return this.name.charAt(0).toUpperCase() + this.name.slice(1);
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div className="mb-10">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

// Memoize the component
export default memo(ColumnChart);
