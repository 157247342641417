import './index.css';

import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
// import ReactSlider from 'react-slider';

const timeRanges = [
  { label: 'Last 30 minutes', value: 30 },
  { label: '1 hour', value: 60 },
  { label: '12 hours', value: 720 },
  { label: '1 day', value: 1440 },
];

const FrameSelector = ({
  timestamps,
  fromTimestamp,
  toTimestamp,
  setFromTimestamp,
  setToTimestamp,
  isPlaying,
}) => {
  // const coloradoTimezone = 'America/Denver';
  const [filteredTimestamps, setFilteredTimestamps] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null);

  useEffect(() => {
    if (!timestamps) return;

    const now = moment().unix();
    const thirtyMinutesAgo = moment().subtract(30, 'minutes').unix();

    const maxTimestamp = Math.max(...timestamps.map((ts) => ts.timestamp));
    const minTimestamp = moment().subtract(24, 'hours').unix();
    const filtered = timestamps.filter(
      (ts) => ts.timestamp >= minTimestamp && ts.timestamp <= maxTimestamp,
    );
    setFilteredTimestamps(filtered);

    if (!fromTimestamp && !toTimestamp) {
      setFromTimestamp(thirtyMinutesAgo);
      setToTimestamp(now);
      setSelectedRange(30);
    }
  }, [timestamps]);

  // const formatTimestamp = (ts) => {
  //   return moment.unix(ts).tz(coloradoTimezone).format('M/D h:mm a');
  // };

  // const handleSliderChange = (values) => {
  //   setFromTimestamp(values[0]);
  //   setToTimestamp(values[1]);
  // };

  const handleRangeButtonClick = (minutes) => {
    if (toTimestamp) {
      const startTime = moment.unix(toTimestamp).subtract(minutes, 'minutes').unix();
      const newFromTimestamp = Math.max(startTime, minTimestamp);
      setFromTimestamp(newFromTimestamp);
      const actualRange = toTimestamp - newFromTimestamp;
      const desiredRange = minutes * 60;
      const timeLeftToAdd = desiredRange - actualRange;
      if (timeLeftToAdd > 0) {
        const newToTimestamp = toTimestamp + timeLeftToAdd;
        setToTimestamp(newToTimestamp);
      }
    }
    setSelectedRange(minutes);
  };
  if (!filteredTimestamps.length) return null;

  const minTimestamp = Math.min(...filteredTimestamps.map((ts) => ts.timestamp));
  // const maxTimestamp = Math.max(...filteredTimestamps.map((ts) => ts.timestamp));

  return (
    <div className="flex items-center flex-col space-x-4 bg-white w-full">
      {/* <div className="flex-1 w-full">
        <div className="flex justify-between text-gray-600 text-sm">
          <span>{formatTimestamp(fromTimestamp)}</span>
          <label
            htmlFor="timestamp-slider"
            className="text-gray-700 font-semibold mb-2 flex items-center"
          >
            TIME RANGE
          </label>
          <span>{formatTimestamp(toTimestamp)}</span>
        </div>
        <ReactSlider
          id="timestamp-slider"
          min={minTimestamp}
          max={maxTimestamp}
          value={[fromTimestamp, toTimestamp]}
          onChange={handleSliderChange}
          renderTrack={(props, state) => (
            <div {...props} className={`track ${state.index === 0 ? 'track--active' : ''}`} />
          )}
          renderThumb={(props, state) => (
            <div {...props} className={`thumb thumb-${state.index}`} />
          )}
          disabled={isPlaying}
          className="slider flex-grow w-full"
        />
      </div> */}

      <div className="flex items-center justify-center gap-2 w-full" style={{ marginLeft: 0 }}>
        {timeRanges.map((range) => (
          <button
            key={range.value}
            onClick={() => handleRangeButtonClick(range.value)}
            disabled={isPlaying}
            className={`leading-[1.2] p-[3px_10px] text-[13px] border border-[#d5d3cf] rounded-[20px] disabled:opacity-30 disabled:cursor-not-allowed ${
              selectedRange === range.value ? 'bg-[#081B37] text-white' : 'text-[#4b5563]'
            }`}
          >
            {range.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FrameSelector;
