import { WeatherDataResponseInfoFrame } from './WeatherDataHttpTypes';

export interface FrameLoadingResult {
  status: FrameLoadingStatus;
  frameId: string;
  data?: FrameLoadingResultData;
  layerId: string;
  geojson?: any;
  symbolData?: FrameLoadingSymbolData;
  points?: PointWithValue[];
  layerType: string;
}

export interface FramePoints {
  frameId: string;
  layerId: string;
  points?: PointWithValue[];
}

export interface FrameLoadingResultData {
  frameId: string;
  coordinates: string;
  image: string;
  imageBlob?: HTMLImageElement;
  frameInfo: WeatherDataResponseInfoFrame;
}

export interface FrameLoadingSymbolData {
  frameId: string;
  points: PointWithValue[];
  unit: string;
  frameTimestamp?: number;
}
export interface PointWithValue {
  lat: number;
  lon: number;
  val: number[];
  weatherType?: string;
  locationId?: string;
  city?: {
    name: string;
    lat: number;
    lon: number;
  };
  old_val?: number[];
  timestamp?: number;
  updated_at?: number;
  user_id?: string;
  userId?: string;
  overrideId?: number;
}

export enum FrameLoadingStatus {
  Loading,
  Success,
  Error,
}
