import moment from 'moment-timezone';

export const alertColors = {
  clear: 'rgba(0, 44, 75, 0.5)', // 0fc70f
  notice: '#e5d729',
  caution: 'orange',
  warning: 'red',
  '-9999': 'missing',
};

export const getAlertColor = (site, alert, time) => {
  if (alert === 'all') {
    return alertColors[site[`${time}_alert_code`]] || alertColors.clear;
  }

  return alertColors[site[`${alert}_${time}_alert_code`]] || alertColors.clear;
};

export const formatTime = (timestamp, timezone = 'America/Denver', format = 'MMM D, HH:mm') => {
  const zonedDate = moment.unix(timestamp).tz(timezone);
  return zonedDate.format(format);
};

export const calculateTime = (index, epochStart, firstDelta, interval) => {
  if (isNaN(epochStart) || isNaN(firstDelta) || isNaN(interval)) {
    return NaN;
  }

  let time;
  if (index === 0) {
    time = epochStart;
  } else {
    time = epochStart + firstDelta + (index - 1) * interval;
  }

  return time * 1000;
};
