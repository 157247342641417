import { Card } from 'antd';
import moment from 'moment';

const DailySummary = ({ data }) => {
  return (
    <div className="flex justify-start flex-wrap gap-5">
      {data.map((day, index) => (
        <WeatherBlock
          key={index}
          date={moment.unix(day.epoch_day_time).format('dddd, MMM D')}
          minTemp={day.local_min_temp}
          maxTemp={day.local_max_temp}
          windSpeed={day.local_avg_wind_speed.toFixed(1)}
          windGust={day.local_avg_wind_gust.toFixed(1)}
          snowAccum={day.total_snow_accum}
        />
      ))}
    </div>
  );
};

const WeatherBlock = ({ date, minTemp, maxTemp, windSpeed, windGust, snowAccum }) => (
  <Card size="small" title={<h3 className="uppercase mb-0">{date}</h3>} className="w-[250px]">
    <div className="flex flex-center justify-between min-w-[220px]">
      <b className="font-semibold">High</b>{' '}
      <span className="flex items-center w-[90px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          className="text-[#081B37] text-[15px] mr-1"
        >
          <path d="M10 13.5a4 4 0 104 0V5a2 2 0 00-4 0v8.5M10 9h4"></path>
        </svg>{' '}
        {maxTemp}° F
      </span>
    </div>
    <div className="flex flex-center justify-between min-w-[220px]">
      <b className="font-semibold">Low</b>{' '}
      <span className="flex items-center w-[90px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          className="text-[#081B37] text-[15px] mr-1"
        >
          <path d="M10 13.5a4 4 0 104 0V5a2 2 0 00-4 0v8.5M10 9h4"></path>
        </svg>{' '}
        {minTemp}° F
      </span>
    </div>
    <div className="flex flex-center justify-between min-w-[220px]">
      <b className="font-semibold">Wind Speed</b>{' '}
      <span className="flex items-center w-[90px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          className="text-[#081B37] text-[15px] mr-1"
        >
          <path d="M9.59 4.59A2 2 0 1111 8H2m10.59 11.41A2 2 0 1014 16H2m15.73-8.27A2.5 2.5 0 1119.5 12H2"></path>
        </svg>{' '}
        {windSpeed} mph
      </span>
    </div>
    <div className="flex flex-center justify-between min-w-[220px]">
      <b className="font-semibold">Wind Gust</b>{' '}
      <span className="flex items-center w-[90px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          className="text-[#081B37] text-[15px] mr-1"
        >
          <path d="M9.59 4.59A2 2 0 1111 8H2m10.59 11.41A2 2 0 1014 16H2m15.73-8.27A2.5 2.5 0 1119.5 12H2"></path>
        </svg>{' '}
        {windGust} mph
      </span>
    </div>
    <div className="flex flex-center justify-between min-w-[220px]">
      <b className="font-semibold">Snow depth</b>{' '}
      <span className="flex items-center w-[90px]">
        {' '}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0"
          viewBox="0 0 32 32"
          className="text-[#081B37] text-[15px] mr-1"
        >
          <path d="M8 0v32h16V0H8zm2 2h12v3h-7v2h7v2h-4v2h4v2h-7v2h7v2h-4v2h4v2h-7v2h7v2h-4v2h4v3H10V2z"></path>
        </svg>{' '}
        {snowAccum} in
      </span>
    </div>
  </Card>
);

export default DailySummary;
