import axios from 'axios';
import moment from 'moment-timezone';
import { useQuery } from 'react-query';

export const key = ['SITE_DATA'];
export const useGetSiteData = ({ site }: { site: number }) =>
  useQuery({
    queryKey: [...key, site],
    queryFn: async () => {
      const { data } = await axios.get('https://pikalert.c9weather.com/site_data', {
        params: {
          path: '/site_data',
          date_str: moment().utc().format('YYYYMMDDHHmm'),
          site: site,
          state: 'colorado',
          _dc: Date.now(),
          page: 1,
          limit: 25,
        },
      });
      return data;
    },
    enabled: !!site,
  });
