import axios from 'axios';
import { useQuery } from 'react-query';

export interface Road {
  segment_id: string;
  dicast_id: string;
  center_lat: number;
  center_lon: number;
  coordinates: {
    lat: number;
    lon: number;
  }[];
}
export interface RoadsResponse {
  segments: Road[];
}
export const key = ['ROADS_KEY'];
export const useGetRoads = () =>
  useQuery([...key], async (): Promise<RoadsResponse> => {
    const { data } = await axios.get(
      'https://pikalert.c9weather.com/Pikalert_issue153/resources/road_segments/co_roads.json',
    );
    return data;
  });
