import { Card, Select } from 'antd';

const { Option } = Select;

const options = [
  { label: 'Basic', value: 'osm' },
  { label: 'Satellite', value: 'satellite' },
];

const MapStyle = ({ switchToOSM, switchToSatellite }) => {
  const handleChange = (value) => {
    if (value === 'osm') {
      switchToOSM();
    } else if (value === 'satellite') {
      switchToSatellite();
    }
  };

  return (
    <div className="absolute top-[180px] left-[75px] z-10 bg-transparent">
      <Card size="small" style={{ width: 300 }}>
        <label>
          <span className="pl-1">Map style</span>
          <Select defaultValue={options[0].value} onChange={handleChange} className="w-full">
            {options.map((filter) => (
              <Option key={filter.value} value={filter.value}>
                {filter.label}
              </Option>
            ))}
          </Select>
        </label>
      </Card>
    </div>
  );
};

export default MapStyle;
