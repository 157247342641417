import Highcharts from 'highcharts';
import HC_accessibility from 'highcharts/modules/accessibility';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

import { calculateTime, formatTime } from '../../utils/helper';

HC_exporting(Highcharts);
HC_accessibility(Highcharts);

const AreaChart = ({ timeSeries, chartTitle, yAxisTitle }) => {
  const data = timeSeries?.site?.time_series_container || {};
  if (!data) return null;

  const windSpeedData = data.series_wind_speed || [];
  const windDirData = data.series_wind_dir || [];

  const epochStart = parseInt(data.epoch_start_time, 10);
  const firstDelta = parseInt(data.first_time_delta_seconds, 10);
  const interval = parseInt(data.remaining_time_delta_seconds, 10);

  const seriesData = windSpeedData.map((_, index) => ({
    x: calculateTime(index, epochStart, firstDelta, interval),
    windSpeed: windSpeedData[index],
    windDir: windDirData[index],
  }));

  const options = {
    chart: {
      type: 'area',
      height: 250,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
    },
    title: {
      align: 'left',
      text: chartTitle,
    },
    subtitle: {
      align: 'left',
      text: 'Click chart and drag to zoom',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          return formatTime(this.value / 1000);
        },
      },
      tickInterval: 12 * 3600 * 1000,
    },
    yAxis: [
      {
        title: {
          text: yAxisTitle,
        },
        labels: {
          enabled: true,
        },
        gridLineWidth: 0,
      },
      {
        title: {
          text: 'Wind Direction',
        },
        labels: {
          enabled: false,
        },
        opposite: true,
        gridLineWidth: 0,
      },
    ],
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        threshold: null,
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        let tooltipHtml = `<div>Time: ${formatTime(this.x / 1000)}<br/><ul>`;

        this.points.forEach((point) => {
          tooltipHtml += `
            <li><span style="color:${point.series.color}">${point.series.name}: </span><strong>${point.y}</strong></li>`;
        });

        tooltipHtml += '</ul></div>';
        return tooltipHtml;
      },
      positioner: function (labelWidth, labelHeight, point) {
        const chartWidth = this.chart.chartWidth;
        const chartHeight = this.chart.chartHeight;
        const tooltipX = point.plotX + this.chart.plotLeft - labelWidth / 2;
        const tooltipY = point.plotY + this.chart.plotTop - labelHeight - 10;

        return {
          x: Math.max(0, Math.min(tooltipX, chartWidth - labelWidth)),
          y: Math.max(0, Math.min(tooltipY, chartHeight - labelHeight)),
        };
      },
    },
    series: [
      {
        name: 'Wind Speed',
        data: seriesData.map((point) => [point.x, point.windSpeed]),
        color: '#FF6600',
        tooltip: {
          valueSuffix: ' km/h',
        },
      },
      {
        name: 'Wind Direction',
        data: seriesData.map((point) => [point.x, point.windDir]),
        color: '#0099FF',
        yAxis: 1,
        tooltip: {
          valueSuffix: '°',
        },
        lineWidth: 0,
        fillOpacity: 0,
        showInLegend: false,
        marker: {
          enabled: false,
        },
        enableMouseTracking: true,
        states: {
          hover: {
            enabled: false,
          },
        },
        // marker: {
        //   enabled: true,
        //   symbol:
        //     'url(https://www.reshot.com/preview-assets/icons/ZGEKU95YAJ/arrow-up-ZGEKU95YAJ.svg)',
        //   radius: 10,
        //   height: 15,
        // },
      },
    ],
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
      backgroundColor: '#FFFFFF',
      itemStyle: {
        color: '#333333',
      },
      itemHoverStyle: {
        color: '#000000',
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div className="mb-10">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default AreaChart;
