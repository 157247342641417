import { IconType } from 'react-icons';

const MapStyleIcon: IconType = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path d="M3 7l6-3 6 3 6-3v13l-6 3-6-3-6 3V7M9 12v.01M6 13v.01M17 15l-4-4M13 15l4-4"></path>
    </svg>
  );
};

export default MapStyleIcon;
