const labelMap = {
  temp: 'Temperature',
  road_temp_1: 'Road Temperature 1',
  road_temp_2: 'Road Temperature 2',
  road_state_1: 'Road State',
  surface_friction: 'Road Surface Friction',
  sub_surface_1: 'Sub Surface Temp 1',
  sub_surface_2: 'Sub Surface Temp 2',
  wind_spd: 'Wind Speed',
  wind_dir: 'Wind Dir',
  wind_gust: 'Wind Gust',
  visibility: 'Visibility',
  rel_hum: 'Relative Humidity',
  dew_temp: 'Dewpoint Temperature',
};

const RwisTable = ({ data }) => {
  if (!data || Object.keys(data).length === 0) return null;
  const sortedKeys = Object.keys(labelMap)?.filter((key) => key in data);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead className="bg-blue-600 text-white">
          <tr>
            <th colSpan="2" className="text-left py-2 px-4">
              Observations at {data.obstime}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedKeys?.map((key) => (
            <tr key={key} className="border-b border-gray-300">
              <td className="text-left py-2 px-4 font-medium capitalize">{labelMap[key]}</td>
              <td className="text-left py-2 px-4">{data[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RwisTable;
