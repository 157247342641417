import { InputNumber } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { MdOutlineReplayCircleFilled, MdOutlineSettings, MdRepeat } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import Next from '../../assets/icons/Next';
import Pause from '../../assets/icons/Pause';
import Play from '../../assets/icons/Play';
import Previous from '../../assets/icons/Previous';
import Stop from '../../assets/icons/Stop';
import Button from '../../atoms/button/Button';
import ControlButton from '../../atoms/controlButton/ControlButton';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { getProjectDuration, parseScenes } from '../../helpers/timelineUtil';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import Modal from '../../molecules/modal/Modal';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import styles from '../../pages/playground/Playground.module.scss';
import { ActiveDef } from '../../store/slices/active-slice';
import { updateScene } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { useUpdateProjectFrames } from './useUpdateProject';

interface PlayerProps {
  project: C9ProjectDef;
  onPlay: () => void;
  onPause: () => void;
  onStop: () => void;
  backFive: () => void;
  skipFive: () => void;
  toStart: () => void;
  toEnd: () => void;
  prevScene: (e: number) => void;
  nextScene: (e: number) => void;
  exitfullscreen?: () => void;
}

const Player = ({
  project,
  onPlay,
  onPause,
  onStop,
  nextScene,
  prevScene,
  exitfullscreen,
}: PlayerProps) => {
  const { mode, projectToPlay } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const mapIsLoading = useSelector<RootState>((state) => state.mapLoading.mapIsLoading) as boolean;
  const { isPlaying, time } = useContext(PlayerContext);
  const [isReplay, setIsReplay] = useState(false);
  const [pass, setPass] = useState<{ [key: string]: number }>({});
  const [isSettings, setIsSettings] = useState(false);
  const [replayProject, setReplayProject] = useState(false);
  useUpdateProjectFrames({ onPause, onPlay });
  const dispatch = useDispatch();
  const convertToSceneObject = (
    scenes: { sceneId: string; repeat: number }[],
  ): { [key: string]: number } => {
    return scenes.reduce((acc, scene) => {
      acc[scene.sceneId] = scene.repeat ?? 1;
      return acc;
    }, {} as { [key: string]: number });
  };
  useEffect(() => {
    if (time === 0 && isReplay) {
      startReplay().then(() => {
        const repeats = projectToPlay.sceneDefs.map((scene) => {
          return { sceneId: scene.id, repeat: 1 };
        });
        const passes = convertToSceneObject(repeats);
        setPass(passes);
      });
    }
  }, [isReplay, time]);
  const findNextSceneTime = () => {
    const currScene = parseScenes(project).find(
      (scene) => scene.startMS <= time && scene.endMS + Number(scene.hold) > time,
    )?.endMS;
    if (currScene && isPlaying === PlaybackEnum.PLAYING) return currScene - time;
    else return currScene ?? 0;
  };
  const findPreviousSceneTime = () => {
    const currScene = parseScenes(project).findIndex(
      (scene) => scene.startMS <= time && scene.endMS + Number(scene.hold) > time,
    );
    if (currScene > 0 && isPlaying === PlaybackEnum.PLAYING) {
      return time - parseScenes(project)[currScene - 1].startMS;
    } else {
      return parseScenes(project)[currScene - 1]?.startMS || 0;
    }
  };
  const maxProjectTime = getProjectDuration(projectToPlay);
  useEffect(() => {
    const repeats = projectToPlay.sceneDefs.map((scene) => {
      return { sceneId: scene.id, repeat: 1 };
    });
    const passes = convertToSceneObject(repeats);
    setPass(passes);
  }, [projectToPlay]);
  useEffect(() => {
    const scene = projectToPlay.sceneDefs.find(
      (scene) => scene.timeControl.startMS <= time && scene.timeControl.endMS > time,
    );
    const currScene = projectToPlay.sceneDefs.findIndex(
      (scene) => scene.timeControl.startMS <= time && scene.timeControl.endMS > time,
    );
    const repeat = scene?.repeat;
    if (
      scene &&
      Number(repeat) > pass[scene.id] &&
      time >= projectToPlay.sceneDefs[currScene]?.timeControl.endMS - 50
    ) {
      prevScene(
        time - projectToPlay.sceneDefs[currScene]?.timeControl.startMS,
        //parseScenes(project)[currScene].durationInMS + Number(parseScenes(project)[currScene].hold),
      );
      setPass((prevState) => {
        return { ...prevState, [scene.id]: prevState[parseScenes(project)[currScene].id] + 1 };
      });
    }
  }, [projectToPlay, time]);
  const startReplay = async () => {
    await onPlay();
    setIsReplay(false);
  };
  const replay = () => {
    onStop();
    setIsReplay(true);
  };
  const renderSceneHoldDuration = () => {
    return project.sceneDefs?.map((scene) => (
      <div
        key={scene?.id}
        className={'grid grid-cols-3 gap-8 items-center border-b border-b-gray-50/[.15] pb-4'}
      >
        <div className={'whitespace-nowrap text-xl'}>{scene?.name}</div>
        <div className={'flex gap-2 items-center'}>
          <div className={'whitespace-nowrap'}>hold for</div>
          <InputNumber
            className={'!w-24 text-center'}
            value={isNaN(scene.hold) || !scene.hold ? 0 : scene.hold / 1000}
            precision={2}
            onChange={(e) => {
              (e || e === 0) &&
                dispatch(
                  updateScene({
                    newValue: e * 1000,
                    propertyPath: 'hold',
                    activeScene: scene.id,
                  }),
                );
            }}
          />
          <div className={'whitespace-nowrap'}>seconds;</div>
        </div>
        <div className={'flex gap-2 items-center'}>
          <div className={'whitespace-nowrap'}>repeat</div>
          <InputNumber
            className={'!w-24 text-center'}
            precision={0}
            value={isNaN(scene.repeat) || !scene.repeat ? 1 : scene.repeat}
            onChange={(e) => {
              (e || e === 0) &&
                dispatch(
                  updateScene({
                    newValue: Number(e),
                    propertyPath: 'repeat',
                    activeScene: scene.id,
                  }),
                );
            }}
          />
          <div className={'whitespace-nowrap'}>times</div>
        </div>
      </div>
    ));
  };
  return (
    <>
      <div className={'grid grid-cols-12 w-full relative'}>
        <div className={'col-span-3'} />
        <div className={`${styles.playControls} col-span-6`}>
          {mode === ModeEnum.PROJECT && (
            <ControlButton icon={<Previous />} onClick={() => prevScene(findPreviousSceneTime())} />
          )}
          {mode === ModeEnum.PROJECT &&
            (time < maxProjectTime ? (
              <ControlButton
                disabled={isPlaying === PlaybackEnum.PLAYING || mapIsLoading}
                icon={<Play />}
                onClick={() => onPlay()}
              />
            ) : (
              <ControlButton
                replay
                replayProject={replayProject}
                disabled={isPlaying === PlaybackEnum.PLAYING || mapIsLoading}
                icon={<MdOutlineReplayCircleFilled size={28} />}
                onClick={() => replay()}
              />
            ))}
          <ControlButton
            disabled={isPlaying !== PlaybackEnum.PLAYING}
            icon={<Pause />}
            onClick={onPause}
          />
          <ControlButton
            disabled={isPlaying !== PlaybackEnum.PLAYING}
            icon={<Stop />}
            onClick={() => onStop()}
          />
          {mode === ModeEnum.PROJECT && (
            <ControlButton icon={<Next />} onClick={() => nextScene(findNextSceneTime())} />
          )}
          <ControlButton
            replay={replayProject}
            icon={<MdRepeat size={24} style={{ borderRadius: 4 }} />}
            onClick={() => setReplayProject(!replayProject)}
          />
        </div>
        <div className={'col-span-3 flex justify-end'}>
          <div>
            <button
              className={
                'flex gap-2 items-center px-2 py-0 text-xs bg-white/[.15] border border-white/[.25] border-solid rounded text-white'
              }
              onClick={() => {
                setIsSettings(true);
                document.exitFullscreen();
                exitfullscreen && exitfullscreen();
              }}
            >
              <MdOutlineSettings />
              loop settings
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isSettings}
        onClose={() => setIsSettings(false)}
        style={{
          overlay: { zIndex: 999999 },
          content: { width: 'fit-content', height: 'fit-content' },
        }}
        header={'Play/Loop settings'}
        footer={
          <div className={'w-full flex justify-end px-8'}>
            <Button
              className={'text-white'}
              label={'Close'}
              onClick={() => {
                setIsSettings(false);
              }}
            />
          </div>
        }
      >
        <div className={'flex flex-col gap-4 items-center justify-center'}>
          {renderSceneHoldDuration()}
        </div>
      </Modal>
    </>
  );
};

export default Player;
