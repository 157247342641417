import { cloneDeep } from 'lodash';
import moment, { LocaleSpecification } from 'moment';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useFontLoader } from '../../core/api/useLoadFont';
import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { TimeIndicatorContainer } from './TimeIndicatorContainer';
import { translateText } from './utils';

interface TextElementElementProps {
  panelProps: TextPanelDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapTextElement?: boolean;
  mapId: string;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  geoPosterId?: string;
  mainIndicator: { timeControls: TimeControlDef; value: string; dateValue: string }[];
  timeFrameIndicator: string;
  relativeTime: boolean;
  sceneId: string;
}

export const IndicatorElement = ({
  panelProps,
  canvas,
  disabled,
  mapId,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
  mainIndicator,
  timeFrameIndicator,
  relativeTime,
  sceneId,
}: TextElementElementProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { activeAspectRatio, translation, activeScene, sceneTranslation, projectToPlay } =
    useSelector<RootState, ActiveDef>((state) => state.active);

  const {
    boxDef,
    fontSize,
    fontFamily,
    fontType,
    fontColor,
    fontAlignment,
    textAnimation,
    timeControls,
    positionControl,
    strokeWidth,
    strokeColor,
    textTransform,
  } = panelProps;

  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    background,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;

  const dict = useMemo(
    () => sceneTranslation[activeScene]?.customWords || translation?.customWords,
    [sceneTranslation, activeScene, translation],
  );

  const fontUnit = useMemo(
    () => (canvas.cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100,
    [canvas.cnvHeight],
  );
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;

  const borderString = useCallback(
    (val: BorderDef) =>
      val && `${fontUnit * val.width}px ${val.style} ${singleColorOpacity(val.color)}`,
    [fontUnit],
  );

  const bgColor = useMemo(
    () =>
      background.color?.includes('linear-gradient')
        ? gradientOpacity(background.color)
        : singleColorOpacity(background.color),
    [background.color],
  );

  const extractTimeFormat = useCallback((inputFormat: string) => {
    const tokens = cloneDeep(inputFormat).split(/\s+/);
    const timeTokens = tokens.filter((token) => /[HhmAas]+/.test(token));
    return timeTokens.length > 0 ? timeTokens.join(' ') : '';
  }, []);

  const parseLocale = useCallback(
    (trans: any): moment.LocaleSpecification => {
      const timeFormat = extractTimeFormat(timeFrameIndicator);
      return trans
        ? {
            ...trans,
            longDateFormat: { ...trans.longDateFormat, LT: timeFormat, L: timeFrameIndicator },
            meridiem: (hour: number, minute: number, isLowercase: boolean) =>
              hour < 12
                ? isLowercase
                  ? trans.meridiem.am
                  : trans.meridiem.AM
                : isLowercase
                ? trans.meridiem.pm
                : trans.meridiem.PM,
            calendar: {
              sameDay: `[${trans.calendar?.sameDay}] ${timeFormat ? 'LT' : ''}`,
              nextDay: `[${trans.calendar?.nextDay}] ${timeFormat ? 'LT' : ''}`,
              nextWeek: `${timeFrameIndicator} [${trans.calendar?.nextWeek}] ${
                timeFormat ? 'LT' : ''
              }`,
              lastDay: `[${trans.calendar?.lastDay}] ${timeFormat ? 'LT' : ''}`,
              lastWeek: `${timeFrameIndicator} [${trans.calendar?.lastWeek}] ${
                timeFormat ? 'LT' : ''
              }`,
              sameElse: 'LT',
            },
          }
        : undefined;
    },
    [timeFrameIndicator, extractTimeFormat],
  );

  useEffect(() => {
    const trans = sceneTranslation[activeScene] ?? translation;
    if (trans) {
      moment.defineLocale('custom', parseLocale(trans) as unknown as LocaleSpecification);
      moment.locale('custom');
    } else {
      moment.updateLocale('en-US', {
        calendar: {
          sameDay: `[Today] ${extractTimeFormat(timeFrameIndicator) ? 'LT' : ''}`,
          nextDay: `[Tomorrow] ${extractTimeFormat(timeFrameIndicator) ? 'LT' : ''}`,
          lastDay: `[Yesterday] ${extractTimeFormat(timeFrameIndicator) ? 'LT' : ''}`,
          sameElse: timeFrameIndicator,
        },
      });
    }
  }, [
    activeScene,
    sceneTranslation,
    translation,
    parseLocale,
    extractTimeFormat,
    timeFrameIndicator,
  ]);

  useFontLoader(fontFamily);

  const getValue = useMemo(() => {
    return mainIndicator?.find((val) => {
      const index = mainIndicator.indexOf(val);
      const sceneHold =
        index === mainIndicator.length - 1
          ? projectToPlay?.sceneDefs.find((scene) => scene.id === sceneId)?.hold ?? 0
          : 0;
      return val.timeControls.startMS <= time && val.timeControls.endMS + sceneHold >= time;
    });
  }, [mainIndicator, time, projectToPlay, sceneId]);

  const renderStringWithPlaceholders = useCallback(
    (inputString?: string | null, valueObject: Record<string, string> = {}) => {
      const regex = /\$(\w+)/g;
      if (!dict) {
        return inputString
          ?.replace(/\$Night/g, 'Night')
          .replace(/\$NIGHT/g, 'NIGHT')
          .replace(/\$Day/g, 'Day')
          .replace(/\$DAY/g, 'DAY')
          .replace(/\$Morning/g, 'Morning')
          .replace(/\$MORNING/g, 'MORNING')
          .replace(/\$Evening/g, 'Evening')
          .replace(/\$EVENING/g, 'EVENING');
      }
      return inputString?.replace(regex, (match, placeholder) => valueObject[placeholder] || match);
    },
    [dict],
  );

  const formattedDate = useMemo(() => {
    if (!getValue) return null;
    const parsedDate = moment(getValue.dateValue, 'DD-MM-YYYY HH:mm');
    return relativeTime ? parsedDate.calendar() : getValue.value;
  }, [getValue, relativeTime]);

  return (
    <TimeIndicatorContainer
      sceneId={sceneId}
      visibility={true}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      type={'textPanels'}
      lock={false}
      style={{
        fontFamily: `${fontFamily} ${fontType}`,
        textTransform,
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: Math.floor(fontUnit * fontSize),
      }}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
    >
      {formattedDate && (
        <div style={{ height: '100%', background: bgColor }}>
          <div
            className="text-panel-container"
            key={fontColor + 'container'}
            style={{
              height: '100%',
              overflow: 'hidden',
              background: fontColor.includes('linear') ? gradientOpacity(fontColor) : 'transparent',
              paddingTop: fontUnit * paddingTop,
              paddingLeft: fontUnit * paddingLeft,
              paddingRight: fontUnit * paddingRight,
              paddingBottom: fontUnit * paddingBottom,
              borderLeft: borderString(borderLeft),
              borderRight: borderString(borderRight),
              borderTop: borderString(borderTop),
              borderBottom: borderString(borderBottom),
              WebkitBackgroundClip: fontColor.includes('linear') ? 'text' : 'initial',
            }}
          >
            <div style={{ overflow: 'hidden', width: '100%' }}>
              <div
                style={{
                  height: '100%',
                  minWidth: textAnimation.active ? '100%' : 'auto',
                  width: textAnimation.active ? 'fit-content' : 'auto',
                  whiteSpace: 'nowrap',
                  lineHeight: 1.15,
                  textOverflow: 'hidden',
                  transform: textAnimation?.active
                    ? translateText(
                        positionControl.w,
                        activeAspectRatio,
                        time,
                        ref,
                        textAnimation.speed,
                        timeControls[0].startMS,
                        MAX_FULLSCREEN_HEIGHT,
                        textAnimation.direction,
                      )
                    : 'none',
                  WebkitTextStroke: strokeWidth
                    ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
                    : '0px',
                }}
                ref={ref}
              >
                {renderStringWithPlaceholders(formattedDate, dict)}
              </div>
            </div>
          </div>
        </div>
      )}
    </TimeIndicatorContainer>
  );
};
