import { Feature, Map as OLMap, Overlay } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';
import { useEffect } from 'react';

import { RedTruck, Truck, YellowTruck } from '../../../../assets/mapIcons';
import { ILatestTime } from '../apiPikAlert/useGetLatestDate';
import { IVehicle, useGetVehicles } from '../apiPikAlert/useGetVehicles';

export const useVehiclesLayer = (
  map: OLMap | null,
  time: string,
  overlay: Overlay,
  latest: Array<ILatestTime>,
  display: boolean,
  onVehicleClick: (e: IVehicle) => void,
) => {
  const prop = 'latest_vehicles';
  const timestamp = (latest ?? [])?.find((item) => item.dir === prop)?.latest_time;
  const { data } = useGetVehicles(timestamp);

  useEffect(() => {
    if (!data || !map || !timestamp || !data?.districts?.length || !display) return;
    const siteArray = data.districts[0].vehicles;

    const pointFeatures = siteArray.map((site) => {
      return new Feature({
        geometry: new Point(fromLonLat([Number(site.lon), Number(site.lat)])),
        vehicleData: site, // Ensure vehicleData is included in the properties
      });
    });

    const pointVectorSource = new VectorSource({
      features: pointFeatures,
    });

    const pointVectorLayer = new VectorLayer({
      source: pointVectorSource,
      zIndex: 5,
      style: (feature) => {
        let truck;
        const now = new Date();
        const utcDate = new Date(Number(feature.getProperties().vehicleData?.obs_time) * 1000);
        const yellowAgeMilliseconds = 30 * 60 * 1000;
        const redAgeMilliseconds = 60 * 60 * 1000;
        const timezoneOffsetMilliseconds = now.getTime() - utcDate.getTime();
        if (timezoneOffsetMilliseconds > yellowAgeMilliseconds) {
          truck = YellowTruck;
        } else if (timezoneOffsetMilliseconds > redAgeMilliseconds) {
          truck = RedTruck;
        } else {
          truck = Truck;
        }
        return new Style({
          image: new Icon({
            src: truck,
            scale: [Number(feature.getProperties().vehicleData?.heading_deg) > 180 ? -1 : 1, 1],
            rotation: Number(feature.getProperties().vehicleData?.heading_deg) * (Math.PI / 180),
          }),
        });
      },
    });

    map.addLayer(pointVectorLayer);

    const clickListener = map.on('click', (event) => {
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const vehicleData = feature.getProperties().vehicleData as IVehicle;
        if (vehicleData) {
          console.log(vehicleData);
          onVehicleClick(vehicleData);
        }
        return true;
      });
    });

    return () => {
      if (map) {
        map.removeLayer(pointVectorLayer);
        // @ts-ignore
        map.un('click', clickListener);
      }
    };
  }, [data, map, overlay, time, timestamp, display]);
};
