import axios from 'axios';
import { isEqual } from 'lodash';
import { useQuery } from 'react-query';

export const key = ['ALERTS_KEY'];
export const useGetAlerts = (poolTime: number, alertLayer: boolean) => {
  return useQuery(
    [...key, poolTime],
    async () => {
      const { data } = await axios.get('https://api.weather.gov/alerts/active?area=CO', {
        headers: { Accept: 'application/geo+json' },
      });
      return data;
    },
    {
      refetchInterval: 1000 * poolTime,
      cacheTime: 0,
      staleTime: 0,
      enabled: alertLayer,
      isDataEqual: (oldData, newData) => {
        return isEqual(oldData, newData);
      }, // Only trigger updates if data is different
    },
  );
};
