import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import { queryClient } from '../../../index';
import { key } from '../hooks/apiPikAlert/useGetLatestDate';

const DateTime = () => {
  const [time, setTime] = useState('');
  const [validTime, setValidTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const coloradoTime = moment().tz('America/Denver').format('M/D h:mm:ss a');
      setTime(coloradoTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const validData = queryClient.getQueriesData([...key]);
  useEffect(() => {
    const coloradoTime = moment().tz('America/Denver');
    const roundedMinutes = Math.floor(coloradoTime.minutes() / 5) * 5;
    const roundedTime = coloradoTime
      .clone()
      .minutes(roundedMinutes)
      .seconds(0)
      .format('M/D h:mm a');
    setValidTime(roundedTime);
  }, [validData]);

  return (
    <div className="absolute text-xs md:text-sm -top-2 left-1/2 transform -translate-x-1/2 bg-white pt-2 pb-1 pr-2 pl-2 rounded shadow-sm z-10 flex gap-x-2 w-[70%] ml-3 md:ml-0 justify-center md:justify-start md:w-fit">
      <div className="relative flex items-center gap-x-2">
        <div className="triangle-left"></div>
        <p className="mb-0">
          <b>Current time:</b> {time}
        </p>{' '}
        <span className="opacity-60">|</span>
        <p className="mb-0">
          <b>Data valid:</b> {validTime}
        </p>
        <div className="triangle-right"></div>
      </div>
    </div>
  );
};

export default DateTime;
