import { useState } from 'react';

import { useGetDistrictSummary } from '../hooks/apiPikAlert/useGetDistrictSummary';
import { useGetLatestDate } from '../hooks/apiPikAlert/useGetLatestDate';
import { alertColors } from '../utils/helper';
import Alert from './Alert';

const RoadsTable = ({ alert, options, onChange, handleSiteClick, setTab }) => {
  const { data: latest } = useGetLatestDate();
  const timestamp = latest?.find((item) => item.dir === 'latest_vehicles')?.latest_time;

  const { data: districtData } = useGetDistrictSummary(timestamp);
  const [query, setQuery] = useState('');

  // Return null if there's no data to display
  if (!districtData?.district_array?.length) return null;

  const district = districtData?.district_array[0];

  const getAlertColor = (alertCode) => alertColors[alertCode] || 'white';

  const getAlertKey = (timeFrame) => {
    return alert.value === 'all'
      ? `${timeFrame}_alert_code`
      : `${alert.value}_${timeFrame}_alert_code`;
  };

  const getAlertKeySummary = (timeFrame) => {
    return alert.value === 'all'
      ? `${timeFrame}_alert_summary_code`
      : `${alert.value}_${timeFrame}_alert_summary_code`;
  };

  // Filter sites based on the search query
  const filteredSites = district?.site_array?.filter((site) =>
    query ? site.desc.toLowerCase().includes(query.toLowerCase()) : true,
  );

  const handleRowClick = (site) => {
    handleSiteClick(site);
    setTab('map');
  };

  return (
    <div className="container mx-auto px-4 py-8 pl-[75px]">
      <div className="flex justify-between mb-2">
        {/* Alert Filter */}
        <Alert options={options} selectedValue={alert} onChange={onChange} />

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search by site"
          className="rounded-lg border-slate-300"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      <div className="overflow-x-auto max-h-[82vh]">
        <table className="min-w-full table-fixed border-collapse border border-slate-400">
          <thead className="sticky -top-[1px] z-10">
            <tr className="bg-gray-100">
              <th className="w-1/3 py-3 px-4 border border-slate-300 text-left font-semibold text-gray-700">
                Site
              </th>
              <th className="w-1/6 py-3 px-4 border border-slate-300 text-left font-semibold text-gray-700">
                Obs
              </th>
              <th className="w-1/6 py-3 px-4 border border-slate-300 text-left font-semibold text-gray-700">
                Next 6 hrs
              </th>
              <th className="w-1/6 py-3 px-4 border border-slate-300 text-left font-semibold text-gray-700">
                Next 6 - 24 hrs
              </th>
              <th className="w-1/6 py-3 px-4 border border-slate-300 text-left font-semibold text-gray-700">
                Next 24 - 72 hrs
              </th>
            </tr>
          </thead>
          <tbody>
            {/* District Row */}
            <tr className="border border-slate-300">
              <td className="py-3 px-4 text-gray-700 font-bold">{district.district_name}</td>
              <td
                className="py-3 px-4 border border-slate-300"
                style={{ backgroundColor: getAlertColor(district[getAlertKeySummary('obs')]) }}
              ></td>
              <td
                className="py-3 px-4 border border-slate-300"
                style={{ backgroundColor: getAlertColor(district[getAlertKeySummary('hr06')]) }}
              ></td>
              <td
                className="py-3 px-4 border border-slate-300"
                style={{ backgroundColor: getAlertColor(district[getAlertKeySummary('hr24')]) }}
              ></td>
              <td
                className="py-3 px-4 border border-slate-300"
                style={{ backgroundColor: getAlertColor(district[getAlertKeySummary('hr72')]) }}
              ></td>
            </tr>

            {/* Filtered Sites */}
            {filteredSites?.map((site) => (
              <tr
                key={site.site_num}
                className="border border-slate-300 cursor-pointer"
                onDoubleClick={() => handleRowClick(site)}
              >
                <td className="py-3 px-4 text-gray-700 text-sm">{site.desc}</td>
                <td
                  className="py-3 px-4 border border-slate-300"
                  style={{ backgroundColor: getAlertColor(site[getAlertKey('obs')]) }}
                ></td>
                <td
                  className="py-3 px-4 border border-slate-300"
                  style={{ backgroundColor: getAlertColor(site[getAlertKey('hr06')]) }}
                ></td>
                <td
                  className="py-3 px-4 border border-slate-300"
                  style={{ backgroundColor: getAlertColor(site[getAlertKey('hr24')]) }}
                ></td>
                <td
                  className="py-3 px-4 border border-slate-300"
                  style={{ backgroundColor: getAlertColor(site[getAlertKey('hr72')]) }}
                ></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoadsTable;
