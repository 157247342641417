import axios from 'axios';
import { useQuery } from 'react-query';

export const key = ['VEHICLES'];

export interface IVehicle {
  auto_brake: string;
  elevation: string;
  heading_deg: string;
  humidity: string;
  id: string;
  lat: string;
  lon: string;
  obs_time: string;
  pressure: string;
  road_temp_f: string;
  speed_mph: string;
  spreader: string;
  temp_f: string;
  traction_control: string;
  wiper_status: string;
}
interface IVehicleDistrict {
  data_time: string;
  display_name: string;
  district_name: string;
  max_lat: number;
  max_lon: number;
  min_lat: number;
  min_lon: number;
  vehicles: Array<IVehicle>;
}
interface IVehiclesResponse {
  data_time: string;
  districts: Array<IVehicleDistrict>;
}
export const useGetVehicles = (timestamp?: string) => {
  return useQuery({
    queryKey: [...key, timestamp],
    queryFn: async (): Promise<IVehiclesResponse> => {
      const { data } = await axios.get('https://pikalert.c9weather.com/latest_vehicles', {
        params: {
          path: '/latest_vehicles',
          state: 'colorado',
          client_ip: 'unknown',
          _dc: timestamp,
          page: 1,
          start: 0,
          limit: 25,
        },
      });
      return data;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !!timestamp,
  });
};
