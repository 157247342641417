import { Map, Overlay } from 'ol'; // OpenLayers Map
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Stroke, Style } from 'ol/style';
import { useEffect } from 'react';

import { queryClient } from '../../../../index';
import { getAlertColor } from '../../utils/helper';
import { LayerEnum } from '../../variables/layerEnum';
import { key } from '../apiPikAlert/useGetDistrictSummary';
import { useGetRoads } from '../apiPikAlert/useGetRoads';
import { Site } from './useSitesLayer';

export const useRoadsLayer = (
  map: Map | null,
  alert: boolean,
  time: string,
  overlay: Overlay,
  display: boolean,
  setSiteDate: (e: any) => void,
) => {
  const { data } = useGetRoads();
  const roadData = queryClient.getQueriesData([...key]).map(([, data]) => data);
  // @ts-ignore
  const siteData = roadData[1]?.district_array[0].site_array;
  useEffect(() => {
    if (!data || !map || !siteData || !display) return;
    const doesRoadMatchSite = (dicast_id: string, site_num: number) => {
      return Number(dicast_id) === site_num;
    };

    const shouldHighlightRoad = (site: Site) => {
      const isRoadCondSite = site.is_road_cond_site === true;
      const alertCodeNotClear =
        site.hr06_alert_code !== 'clear' ||
        site.hr24_alert_code !== 'clear' ||
        site.hr72_alert_code !== 'clear';
      return isRoadCondSite && alertCodeNotClear;
    };

    const geojsonData = {
      type: 'FeatureCollection',
      features: data.segments?.map((road) => {
        let point = {};
        const isHighlighted = siteData?.some((site: Site) => {
          point = site;
          return doesRoadMatchSite(road.dicast_id, site.site_num) && shouldHighlightRoad(site);
        });

        return {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: road.coordinates.map((point: { lat: number; lon: number }) => [
              point.lon,
              point.lat,
            ]),
          },
          properties: {
            segment_id: road.segment_id,
            dicast_id: road.dicast_id,
            center: [road.center_lon, road.center_lat],
            isHighlighted,
            ...point,
            site: point,
            type: LayerEnum.ROAD_LAYER,
          },
        };
      }),
    };

    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonData, {
        featureProjection: 'EPSG:3857',
      }),
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: 'ROADS',
      zIndex: 1,
      style: (feature) => {
        const isHighlighted = feature.get('isHighlighted');
        return new Style({
          stroke: new Stroke({
            color: isHighlighted ? getAlertColor(feature.get('site'), alert, time) : '#3B3B3B',
            width: 2,
          }),
        });
      },
    });

    map.addLayer(vectorLayer);
    const clickListener = map.on('click', (event) => {
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const siteData = feature.getProperties();
        if (siteData) {
          console.log(siteData);
          setSiteDate(siteData);
        }
        return true;
      });
    });
    return () => {
      if (map && vectorLayer) {
        map.removeLayer(vectorLayer);
        // @ts-ignore
        map.un('click', clickListener);
      }
    };
  }, [alert, data, display, map, overlay, siteData, time]);
};
