import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { FeatureLike } from 'ol/Feature';

import { AlertData, isJsonData, JsonData } from '../AlertData';
import { CountyData, isCountyJsonData } from '../CountyData';

export const alertModal = (e: FeatureLike) => {
  const properties = e.getProperties();
  const modal = Modal.confirm({
    title: isCountyJsonData(properties)
      ? properties?.name
      : isJsonData(properties)
      ? properties?.event
      : '',
    content: (
      <>
        {properties?.region === 'ALERT' ? (
          <AlertData jsonData={properties as JsonData} />
        ) : isCountyJsonData(properties) ? (
          <CountyData jsonData={properties} />
        ) : null}
      </>
    ),
    okText: 'Confirm',
    closeIcon: <CloseOutlined />,
    style: { minWidth: 800 },
    cancelButtonProps: { style: { display: 'none' } },
    onOk() {
      modal.destroy();
    },
    okButtonProps: {
      style: {
        backgroundColor: '#1890ff',
        color: '#fff',
        borderRadius: '5px',
        border: 'none',
      },
    },
  });
};
